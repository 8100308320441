import CustomButton from '@/common/CustomButton';
import CustomCollapse from '@/common/CustomCollapse';
import FormItem from '@/common/FormItem';
import {
  CaretDownFilled,
  DeleteOutlined,
  DownOutlined,
  PlusCircleFilled,
  UploadOutlined
} from '@ant-design/icons';
import {
  Col,
  Row,
  Select,
  Form,
  Badge,
  Collapse,
  InputNumber,
  Input,
  Divider,
  Checkbox,
  Tag,
  message,
  Card,
  Modal,
  Spin
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './index.module.less';

import terms from '../../images/sample1.pdf';

import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import PaymentAdvance from '../UpdatedComponents/Payments/advance';
import PaymentProgress from '../UpdatedComponents/Payments/progress';
import PaymentRetention from '../UpdatedComponents/Payments/retention';
import UpdatedPacking from '../UpdatedComponents/Packing';
import UpdatedMobilisation from '../UpdatedComponents/Mobilisation';
import UpdatedMRDT from '../UpdatedComponents/MRDT';
import UpdatedLD from '../UpdatedComponents/LD';
import UpdatedDLP from '../UpdatedComponents/DLP';
import UpdatedWarranty from '../UpdatedComponents/Warranty';
import UpdatedContractPerformance from '../UpdatedComponents/ContractPerformance';
import UpdatedFreightInsurance from '../UpdatedComponents/FreightInsurance';
import { DefaultPaymentObject } from '../../constants';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { getAllProjects, getCheckLimit } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import Payments from '../Payments';
import UpdatedDelivery from '../UpdatedComponents/DeliverySchedule';
import UpdatedBoq from '../UpdatedComponents/BOQ';
import ReactQuill from 'react-quill';
import { ToWords } from 'to-words';
// import { deleteDraftImages } from '@/features/initiate-loi/redux/slice';

const { Option } = Select;
const { Panel } = Collapse;
const user = LocalStorage.getItem('LOI_user');
const DraftEdit1 = ({
  po,
  myParam,
  flowValues,
  setFlowValues,
  basicContractPrice,
  isLimitExceeded,
  setIsLimitExceeded,
  loaMessage,
  loiMessage,
  handleDeleteImages,
  tempDocList,
  setLOIMessage,
  setLOAMessage,
  broadScope,
  attachmentList,
  specialTerms,
  vendorId,
  draftDetails,
  setBasicContractPrice,
  setBroadScope,
  initiateDraft,
  setContactEmail,
  setAttachmentList,
  setVendorContact,
  setVendorAddress,
  setVendorSite,
  setVendorContactNumber,
  setQuantityVariation,
  setPriceJustification,
  setAgreementLocation,
  setAllTpl,
  setSpecialTerms,
  setContractValidity,
  setPriceBasis,
  setCurrency,
  emailMessage,
  setEmailMessage,
  setVqrd,
  setPo,
  handleFormSubmit,
  boq,
  setBoq,
  setSbgName,
  allSbgs,
  setApproverName,
  setProjectId,
  delivery,
  setDelivery,
  setVendorLocation,
  allVendorLocations,
  setBuJv,
  setProjectName,
  setBuName,
  setVendorName,
  setVendorId,
  setBuId,
  value,
  setValue,
  payments,
  setPayments,
  newDocs,
  setNewDocs,
  supportingDoc,
  setSupportingDoc,
  contacts,
  setContacts,
  formSubmitted,
  setFormSubmitted,
  loi,
  setLOI,
  setSelect,
  current,
  setCurrent,
  validateMessages,
  progressMessage,
  setProgressMessage,
  deliveryMessage1,
  setDeliveryMessage1,
  clauseMessage,
  setClauseMessage,
  defectLiabilityMessage1,
  setDefectLiabilityMessage1,
  contractMessage2,
  setContractMessage2,
  setSelectedValues,
  selectedValues,
  check,
  setCheck,
  allApprovers,
  allBu,
  allCFO,
  allVendors,
  formValues,
  allProjects,
  setProjectCodes,
  selectedOption,
  setSelectedOption,
  selectedSchedule,
  setSelectedSchedule,
  selectedSbgs,
  selectedApprovers,
  setSelectedApprovers,
  setformValues,
  dummyData,
  advanceMessage,
  setAdvanceMessage,
  retentionMessage,
  setRetentionMessage,
  retention,
  setRetention,
  progressPercent1,
  setProgressPercent1,
  packing,
  setPacking,
  mobilisationMessage,
  setMobilisationMessage,
  mrdtMessage,
  setMrdtMessage,
  warrantyMessage,
  setWarrantyMessage,
  freightMessage,
  setFreightMessage,
  mobilisation,
  setMobilisation,
  mrdt,
  setMrdt,
  ld,
  setLD,
  warranty,
  setWarranty,
  dlp,
  setDlp,
  contractPerformance,
  setContractPerformance,
  freight,
  setFreight,
  defaultClauses,
  setEmailreg,
  emailreg,
  setClauseCount,
  clauseCount,
  isDraftLoading,
  setCancellationMessage,
  setUnitRatesMessage,
  unitRatesMessage,
  inAdditionMessage,
  setInAdditionMessage,
  taxDutiesMessage,
  setTaxDutiesMessage,
  priceBasis,
  setPriceBasisText
}) => {
  // const { mode } = useParams();
  const priceBasisValues = [
    { value: 'EXW (Ex-works)', name: 'EXW (Ex-works)' },
    { value: 'FCA (Free Carrier)', name: 'FCA (Free Carrier)' },
    { value: 'FAS (Free Alongside Ship)', name: 'FAS (Free Alongside Ship)' },
    { value: 'FOB (Free on Board)', name: 'FOB (Free on Board)' },
    { value: 'CFR (Cost & Freight)', name: 'CFR (Cost & Freight)' },
    {
      value: 'CIF (Cost, Insurance & Freight)',
      name: 'CIF (Cost, Insurance & Freight)'
    },
    { value: 'CPT (Cost Paid To)', name: 'CPT (Cost Paid To)' },
    {
      value: 'CIP ( Carrier and Insurance Paid To)',
      name: 'CIP ( Carrier and Insurance Paid To)'
    },
    {
      value: 'DAP (Delivered at Place unloaded)',
      name: 'DAP (Delivered at Place unloaded)'
    },
    { value: 'DPU (Delivered at Place)', name: 'DPU (Delivered at Place)' },
    { value: 'DDP (Delivered Duty Paid)', name: 'DDP (Delivered Duty Paid)' }
  ];

  const modalFormValues = LocalStorage.getItem('formValues');
  console.log('isFORM', unitRatesMessage === true);
  var date = new Date();
  const [form] = Form.useForm();
  const isbroadScopeEmpty =
    broadScope?.replace(/<(.|\n)*?>/g, '').trim().length === 0;

  const isListOfAttachmentsEmpty =
    attachmentList?.replace(/<(.|\n)*?>/g, '').trim().length === 0;

  const isSpecialTermsEmpty =
    specialTerms?.replace(/<(.|\n)*?>/g, '').trim().length === 0;

  const toWords = new ToWords({
    localeCode: draftDetails?.currency === 'INR' ? 'en-IN' : 'en-US',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: 'Rupee',
        plural: 'Rupees',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paise',
          symbol: ''
        }
      }
    }
  });

  var filteredApproverName = [];
  var len = allApprovers?.length;
  for (var i = 0; i < len; i++) {
    filteredApproverName.push({
      id: allApprovers[i]?.id,
      fullname: `${allApprovers[i]?.givenName} ${allApprovers[i]?.surname}`
    });
  }

  var filteredSBGName = [];
  var SBGlen = allSbgs?.length;
  for (var k = 0; k < SBGlen; k++) {
    filteredSBGName.push({
      id: allSbgs[k]?.id,
      fullname: `${allSbgs[k]?.givenName} ${allSbgs[k]?.surname}`
    });
  }

  var filteredCFOName = [];
  var CFOlen = allCFO?.length;
  for (var j = 0; j < CFOlen; j++) {
    filteredCFOName.push({
      id: allCFO[j]?.id,
      fullname: `${allCFO[j]?.givenName} ${allCFO[j]?.surname}`
    });
  }

  const [numPages, setNumPages] = useState(null);
  const dispatch = useDispatch();
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (
      defaultClauses &&
      !formValues.cancellation_notice &&
      !formValues.tax_duties &&
      !formValues.loiMessage &&
      !formValues.loaMessage &&
      !formValues.message0 &&
      !formValues.message3
    ) {
      form.setFieldsValue({
        cancellation_notice: defaultClauses?.checkbox?.defaultClause,
        tax_duties:
          taxDutiesMessage !== 'undefined'
            ? taxDutiesMessage
            : defaultClauses?.taxAndDuties?.defaultClause,
        loaMessage:
          loiMessage !== 'undefined'
            ? loiMessage
            : defaultClauses?.loaMessage?.defaultClause,
        loiMessage:
          loaMessage !== 'undefined'
            ? loaMessage
            : defaultClauses?.loiMessage?.defaultClause,
        message0:
          unitRatesMessage === null
            ? defaultClauses?.message0?.defaultClause
            : unitRatesMessage,
        message3:
          inAdditionMessage !== null
            ? inAdditionMessage
            : defaultClauses?.message3?.defaultClause
      });
      setformValues({
        ...formValues,
        cancellation_notice: defaultClauses?.checkbox?.defaultClause,
        tax_duties:
          taxDutiesMessage !== 'undefined'
            ? taxDutiesMessage
            : defaultClauses?.taxAndDuties?.defaultClause,
        loaMessage:
          loaMessage !== 'undefined'
            ? loaMessage
            : defaultClauses?.loaMessage?.defaultClause,
        loiMessage:
          loiMessage !== 'undefined'
            ? loiMessage
            : defaultClauses?.loiMessage?.defaultClause,
        message0:
          unitRatesMessage === null
            ? defaultClauses?.message0?.defaultClause
            : unitRatesMessage,
        message3:
          inAdditionMessage !== null
            ? inAdditionMessage
            : defaultClauses?.message3?.defaultClause
      });
    }
  }, [defaultClauses]);

  const handleInputChange = (key, value) => {
    setformValues({ ...formValues, [key]: value });
  };

  const onFinish = (values) => {
    values['payments'] = selectedOption;
    values['schedule'] = selectedSchedule;
    values['approver'] = selectedApprovers;
    values['sbgs'] = selectedSbgs;
    values['documents'] = [supportingDoc];
    setformValues({ ...values, dynamicFields: formValues.dynamicFields });

    {
      selectedApprovers?.length > 0
        ? setCurrent(current + 1)
        : message.error('Add Approver');
    }

    if (emailreg == true) {
      setCurrent(current);
      return message.error('Please enter valid email');
    } else {
      console.log('Test');
    }
  };

  const itemList = [
    {
      value: 'Basil'
    },
    {
      value: 'Shravan'
    }
  ];

  const itemList1 = [
    {
      value: 'Basil'
    }
  ];

  // Initialize prevIsLimitExceeded to the initial value of isLimitExceeded

  const prevIsLimitExceededRef = useRef();

  useEffect(() => {
    prevIsLimitExceededRef.current = isLimitExceeded;
  }, [isLimitExceeded]);

  const onChangeValue = async (e) => {
    const payload = {
      projectId: draftDetails?.Project?.ProjectMasterId,
      loiValue: e
    };
    setBasicContractPrice(e);

    try {
      const response = await dispatch(getCheckLimit(payload));
      const newIsLimitExceeded = response?.payload?.data?.isLimitExceeded;

      setFlowValues((prevValues) => ({
        ...prevValues,
        isLimitExceeded: newIsLimitExceeded
      }));

      if (prevIsLimitExceededRef.current !== newIsLimitExceeded) {
        form.setFieldsValue({ approvers_0: undefined });
      }

      setIsLimitExceeded(newIsLimitExceeded); // Update the state after checking
    } catch (error) {
      // Handle the error
    }
  };

  useEffect(() => {}, [defaultClauses]);

  const populatePaymentTerms = () => {
    const advancePayments = draftDetails?.PaymentTermsAdvances || [];
    const retentionPayments = draftDetails?.PaymentTermsRetentions || [];
    const progressPayments = draftDetails?.PaymentTermsProgresses || [];
    const finalPayments = [];
    const dynamicFields = {};

    let currentCounter = 0;
    advancePayments.forEach((itm) => {
      finalPayments.push({
        type: 'Advance',
        percentage: itm.percentageOfTotalContractPriceAsAdvance,
        periodValue: itm.claimPeriod,
        period: itm.claimPeriodUnit,
        clauses: itm.PaymentTermsAdvanceClauses?.map((itm) => itm.clause)
      });
      dynamicFields[`advanced_${currentCounter}_claimperiod`] = itm.claimPeriod;
      dynamicFields[`advanced_${currentCounter}_percentage`] =
        itm.percentageOfTotalContractPriceAsAdvance;
      dynamicFields[`advanced_${currentCounter}_claim_duration`] =
        itm.claimPeriodUnit;
      dynamicFields[`payment_options_${currentCounter}`] = 'Advance';
      dynamicFields[`payment_${currentCounter}_clauses`] =
        itm.PaymentTermsAdvanceClauses?.map((itm) => itm.clause);
      currentCounter++;
    });

    progressPayments.forEach((itm) => {
      finalPayments.push({
        type: 'Progress',
        periodValue: itm.percentageOfTotalContractPriceAfter,
        percentage: itm.progressPercentage,
        period: itm.claimPeriodUnit,
        clauses: itm.PaymentTermsProgressClauses.map((itm) => itm.clause)
      });
      dynamicFields[`progress_${currentCounter}_percentage`] =
        itm.progressPercentage;
      dynamicFields[`progress_${currentCounter}_total`] =
        itm.percentageOfTotalContractPriceAfter; // need to check
      dynamicFields[`progress_${currentCounter}_claim_duration`] =
        itm.claimPeriodUnit;
      dynamicFields[`payment_options_${currentCounter}`] = 'Progress';
      dynamicFields[`payment_${currentCounter}_clauses`] =
        itm.PaymentTermsProgressClauses?.map((itm) => itm.clause);
      currentCounter++;
    });

    retentionPayments.forEach((itm) => {
      finalPayments.push({
        type: 'Retention',
        percentage: itm.retentionPercentage, // need to check
        periodValue: itm.claimPeriod,
        period: itm.claimPeriodUnit,
        clauses: itm.PaymentTermsRetentionClauses.map((itm) => itm.clause)
      });

      dynamicFields[`retention_${currentCounter}_percentage`] =
        itm.retentionPercentage;
      dynamicFields[`payment_options_${currentCounter}`] = 'Retention';
      dynamicFields[`payment_${currentCounter}_clauses`] =
        itm.PaymentTermsRetentionClauses?.map((itm) => itm.clause);
      currentCounter++;
    });

    if (finalPayments && finalPayments.length > 0) {
      setPayments(finalPayments);
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populateBoq = () => {
    const boqs = [...(draftDetails?.Boqs || [])];
    const finalBoqs = [];
    const dynamicFields = {};
    if (draftDetails?.Boqs?.length === 0) {
      boqs.push({});
    }
    boqs.slice(0, 1).forEach((itm, idx) => {
      finalBoqs.push({
        boqVendorScope: draftDetails?.Boqs?.length === 0 ? 'no' : 'manual',
        clauses: draftDetails?.Boqs?.map((itm) => ({
          field1: itm.unit,
          field2: itm.description,
          field3: itm.quantity,
          field4: itm.rate,
          field5: itm.amount
        }))
      });
      dynamicFields[`boq_radio_0`] =
        draftDetails?.Boqs?.length === 0 ? 'no' : 'manual';
      if (draftDetails?.Boqs?.length > 0) {
        dynamicFields[`boq_${idx}_clauses`]?.push({
          field1: itm.unit || undefined,
          field2: itm.description || undefined,
          field3: itm.quantity || undefined,
          field4: itm.rate || undefined,
          field5: itm.amount || undefined
        });
      }
    });

    console.log(finalBoqs);
    if (finalBoqs && finalBoqs.length > 0) {
      setBoq(finalBoqs);
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populatePacking = () => {
    const packings = [...(draftDetails?.PackagingAndForwardings || [])];
    const finalPackings = [];
    const dynamicFields = {};
    if (draftDetails?.PackagingAndForwardings?.length === 0) {
      packings.push({});
    }
    packings.forEach((itm, idx) => {
      finalPackings.push({
        vendorScope:
          draftDetails?.PackagingAndForwardings?.length === 0 ? 'no' : 'yes',
        clauses: itm.PackagingAndForwardingClauses?.map((itm) => ({
          field1: itm.clause,
          field2: itm.field2
        }))
      });
      dynamicFields[`packing_radio_${idx}`] =
        draftDetails?.PackagingAndForwardings?.length === 0 ? 'no' : 'yes';
      if (draftDetails?.PackagingAndForwardings?.length > 0) {
        dynamicFields[`packing_${idx}_clauses`] =
          itm.PackagingAndForwardingClauses?.map((itm) => ({
            field1: itm.clause,
            field2: itm.field2
          }));
      }
    });
    if (finalPackings && finalPackings.length > 0) {
      setPacking(finalPackings);
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populateDeliverySchedule = () => {
    const delivery = [...(draftDetails?.DeliverySchedules || [])];
    const finalDeliveries = [];
    const dynamicFields = {};
    if (draftDetails?.DeliverySchedules?.length === 0) {
      delivery.push({});
    }
    delivery.forEach((itm, idx) => {
      finalDeliveries.push({
        deliveryScope:
          draftDetails?.DeliverySchedules?.length === 0 ? 'no' : 'yes',
        clauses: itm.DeliveryScheduleClauses?.map((itm) => ({
          field1: itm.field1,
          field2: itm.field2
        }))
      });
      dynamicFields[`delivery_radio_${idx}`] =
        draftDetails?.DeliverySchedules?.length === 0 ? 'no' : 'yes';
      if (draftDetails?.DeliverySchedules?.length > 0) {
        dynamicFields[`delivery_${idx}_clauses`] =
          itm.DeliveryScheduleClauses?.map((itm) => ({
            field1: itm.field1,
            field2: itm.field2
          }));
      }
    });

    if (finalDeliveries && finalDeliveries.length > 0) {
      setDelivery(finalDeliveries);
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populateLd = () => {
    const tempLds = [...(draftDetails?.LDs || [])];
    const finalLds = [];
    const dynamicFields = {};
    if (draftDetails?.LDs?.length === 0) {
      tempLds.push({});
    }
    tempLds.forEach((itm, idx) => {
      finalLds.push({
        delayCause:
          draftDetails?.LDs?.length === 0 ? 'LDNot Applicable' : 'LDApplicable',
        clauses: itm.LDClauses?.map((itm) => itm.clause)
      });
      dynamicFields[`ld_select_${idx}`] =
        draftDetails?.LDs?.length === 0 ? 'LDNot Applicable' : 'LDApplicable';
      if (draftDetails?.LDs?.length > 0) {
        dynamicFields[`ld_${idx}_clauses`] = itm.LDClauses?.map(
          (itm) => itm.clause
        );
      }
    });
    if (finalLds?.length > 0) {
      setLD(finalLds);
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populateMobility = () => {
    const tempMob = [...(draftDetails?.MobilisationTimes || [])];
    const finalMobilisation = [];
    const dynamicFields = {};
    if (draftDetails?.MobilisationTimes?.length === 0) {
      tempMob.push({});
    }
    tempMob.forEach((itm, idx) => {
      finalMobilisation.push({
        mobilisationAppicable:
          draftDetails?.MobilisationTimes?.length === 0
            ? 'MOBNot Applicable'
            : 'MOBApplicable',
        periodValue: itm.claimPeriod,
        period: itm.claimPeriodUnit,
        clauses: itm.MobilisationTimeClauses?.map((itm) => itm.clause)
      });
      dynamicFields[`mob_select_${idx}`] =
        draftDetails?.MobilisationTimes?.length === 0
          ? 'MOBNot Applicable'
          : 'MOBApplicable';
      dynamicFields[`mob_${idx}_claim_duration`] = itm.claimPeriodUnit;
      dynamicFields[`mob_claim_${idx}`] = itm.claimPeriod;
      if (draftDetails?.MobilisationTimes?.length > 0) {
        dynamicFields[`mobility_${idx}_clauses`] =
          itm.MobilisationTimeClauses?.map((itm) => itm.clause);
      }
    });
    if (finalMobilisation?.length > 0) {
      setMobilisation(finalMobilisation);
      //
      //   ...formValues,
      //   dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      // });
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populateMrdt = () => {
    const tempMrdt = [...(draftDetails?.MinResources || [])];
    const finalMrdt = [];
    const dynamicFields = {};
    if (draftDetails?.MinResources?.length === 0) {
      tempMrdt.push({});
    }
    tempMrdt.forEach((itm, idx) => {
      finalMrdt.push({
        mrdTime:
          draftDetails?.MinResources?.length === 0
            ? 'MinNot Applicable'
            : 'MinApplicable',
        clauses: itm.MinResourceClauses?.map((itm) => itm.clause)
      });
      dynamicFields[`mrdt_select_${idx}`] =
        draftDetails?.MinResources?.length === 0
          ? 'MinNot Applicable'
          : 'MinApplicable';
      if (draftDetails?.MinResources?.length > 0) {
        dynamicFields[`mrdt_${idx}_clauses`] = itm.MinResourceClauses?.map(
          (itm) => itm.clause
        );
      }
    });
    if (finalMrdt?.length > 0) {
      setMrdt(finalMrdt);
      //
      //   ...formValues,
      //   dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      // });
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populateWarranty = () => {
    const tempWarranties = [...(draftDetails?.Warranties || [])];
    const finalWarranty = [];
    const dynamicFields = {};
    if (draftDetails?.Warranties?.length === 0) {
      tempWarranties.push({});
    }
    tempWarranties.forEach((itm, idx) => {
      finalWarranty.push({
        warrantyApplicable:
          draftDetails?.Warranties?.length === 0
            ? 'WarrantyNot Applicable'
            : 'WarrantyApplicable',
        clauses: itm.WarrantyClauses?.map((itm) => itm.clause)
      });
      dynamicFields[`warranty_select_${idx}`] =
        draftDetails?.Warranties?.length === 0
          ? 'WarrantyNot Applicable'
          : 'WarrantyApplicable';
      if (draftDetails?.Warranties?.length > 0) {
        dynamicFields[`warranty_${idx}_clauses`] = itm.WarrantyClauses?.map(
          (itm) => itm.clause
        );
      }
    });
    if (finalWarranty?.length > 0) {
      setWarranty(finalWarranty);
      //
      //   ...formValues,
      //   dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      // });
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populateDlp = () => {
    const tempDlps = [...(draftDetails?.DLPs || [])];
    const finalDlps = [];
    const dynamicFields = {};
    if (draftDetails?.DLPs?.length === 0) {
      tempDlps.push({});
    }
    tempDlps.forEach((itm, idx) => {
      finalDlps.push({
        dlpApplicable:
          draftDetails?.DLPs?.length === 0
            ? 'DLPNot Applicable'
            : 'DLPApplicable',
        clauses: itm.DLPClauses?.map((itm) => itm.clause)
      });
      dynamicFields[`dlp_select_${idx}`] =
        draftDetails?.DLPs?.length === 0
          ? 'DLPNot Applicable'
          : 'DLPApplicable';
      if (draftDetails?.Warranties?.length > 0) {
        dynamicFields[`dlp_${idx}_clauses`] = itm.DLPClauses?.map(
          (itm) => itm.clause
        );
      }
    });
    if (finalDlps?.length > 0) {
      setDlp(finalDlps);
      //
      //   ...formValues,
      //   dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      // });
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populateContract = () => {
    const tempContract = [...(draftDetails?.ContractPerformances || [])];
    const finalContract = [];
    const dynamicFields = {};
    if (draftDetails?.ContractPerformances?.length === 0) {
      tempContract.push({});
    }
    tempContract.forEach((itm, idx) => {
      finalContract.push({
        pbgApplicable:
          draftDetails?.ContractPerformances?.length === 0
            ? 'PBGNot Applicable'
            : 'PBGApplicable',
        clauses: itm.ContractPerformanceClauses?.map((itm) => ({
          field1: itm.field1,
          field2: itm.field2
        }))
      });
      dynamicFields[`contract_select_${idx}`] =
        draftDetails?.ContractPerformances?.length === 0
          ? 'PBGNot Applicable'
          : 'PBGApplicable';

      if (draftDetails?.ContractPerformances?.length > 0) {
        dynamicFields[`contract${idx}_clauses`] =
          itm.ContractPerformanceClauses?.map((itm) => ({
            field1: itm.field1,
            field2: itm.field2 || null
          }));
      }
    });
    if (finalContract?.length > 0) {
      setContractPerformance(finalContract);
      //
      //   ...formValues,
      //   dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      // });
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const populateFreight = () => {
    const tempFreight = [...(draftDetails?.FreightAndInsurances || [])];
    const finalFreight = [];
    const dynamicFields = {};
    if (draftDetails?.FreightAndInsurances?.length === 0) {
      tempFreight.push({});
    }
    tempFreight.forEach((itm, idx) => {
      finalFreight.push({
        freightVendorScope:
          draftDetails?.FreightAndInsurances?.length === 0 ? 'no' : 'yes',
        clauses: itm.FreightAndInsuranceClauses?.map((itm) => itm.clause)
      });
      dynamicFields[`freight_radio_${idx}`] =
        draftDetails?.FreightAndInsurances?.length === 0 ? 'no' : 'yes';
      if (draftDetails?.ContractPerformances?.length > 0) {
        dynamicFields[`freight_${idx}_clauses`] =
          itm.FreightAndInsuranceClauses?.map((itm) => itm.clause);
      }
    });
    if (finalFreight?.length > 0) {
      setFreight(finalFreight);
      setformValues({
        ...formValues,
        dynamicFields: { ...(formValues.dynamicFields || {}), ...dynamicFields }
      });
      if (dynamicFields) {
        form.setFieldsValue(dynamicFields);
      }
    }
  };

  const setDynamicFields = () => {
    if (formValues.dynamicFields) {
      form.setFieldsValue(formValues.dynamicFields);
    }
  };

  if (isbroadScopeEmpty === true) {
    setBroadScope('');
    form.setFieldsValue({ broad_scope: undefined });
  }

  if (isListOfAttachmentsEmpty === true) {
    setAttachmentList('');
    form.setFieldsValue({ doc_list: undefined });
  }

  if (isSpecialTermsEmpty === true) {
    setSpecialTerms('');
    form.setFieldsValue({ spl_terms: undefined });
  }

  async function fetchData() {
    const payload = {
      projectId: draftDetails?.Project?.ProjectMasterId,
      loiValue: draftDetails?.basicContractPrice || 0
    };

    try {
      const response = await dispatch(getCheckLimit(payload));
      const responseData = response.payload.data;
      setIsLimitExceeded(responseData.isLimitExceeded);
      setFlowValues({
        isLimitExceeded: responseData.isLimitExceeded,
        isApproved: responseData.isApproved,
        isCbNull: responseData.isCbNull // This line seems incorrect. Should it be isCbNull?
      });
    } catch (error) {
      // Handle error
    }
  }

  useEffect(() => {
    console.log('SELECTED', draftDetails);
    const searchValue = draftDetails?.priceBasis;

    fetchData();

    const exists = priceBasisValues.some((item) => item.value === searchValue);
    setSelectedApprovers(draftDetails?.Approvers);
    if (draftDetails?.allOtherTermsAndConditions === null) {
      setSelectedValues([]);
    } else {
      setSelectedValues(draftDetails?.allOtherTermsAndConditions?.split(','));
    }
    setTaxDutiesMessage(draftDetails?.taxAndDuties);
    setUnitRatesMessage(
      draftDetails?.Message0s?.[0]?.Message0Clauses?.[0]?.clause
    );
    setInAdditionMessage(
      draftDetails?.Message3s?.[0]?.Message3Clauses?.[0]?.clause
    );
    setLOI(draftDetails?.loiOrLoa);
    setPo(draftDetails?.poOrWo);
    setVendorName(
      draftDetails?.vendorName === null ? '' : draftDetails?.vendorName
    );
    setBuId(draftDetails?.BU?.BuMaster?.id);
    setVendorId({
      id: draftDetails?.Vendor?.VendorMaster?.id || vendorId.id
    });
    setProjectName(draftDetails?.Project?.ProjectMaster?.projectName);
    setProjectId({ id: draftDetails?.Project?.ProjectMaster?.id });
    setProjectCodes(draftDetails?.Project?.ProjectMaster?.projectCode);
    setAgreementLocation(draftDetails?.agreementLocation);
    setVendorContactNumber(draftDetails?.vendorsContactNumber);
    setVqrd(draftDetails?.vendorQuotationReferenceDetails);
    setBroadScope(draftDetails?.broadScope);
    setCurrency(draftDetails?.currency);
    setPriceBasis(exists ? draftDetails?.priceBasis : 'Others');
    setPriceBasisText(
      draftDetails?.priceBasis === 'Others'
        ? undefined
        : draftDetails?.priceBasis
    );
    setContractValidity(draftDetails?.validityOfContractInMonths);
    setBasicContractPrice(draftDetails?.basicContractPrice);
    setBuName(draftDetails?.BU?.BuMaster?.buName);
    setPriceJustification(
      draftDetails?.priceJustificationAndSelectionOfVendors
    );
    setVendorContact(draftDetails?.vendorsContactPerson);
    setContactEmail(draftDetails?.vendorsContactEMailId);
    setSpecialTerms(draftDetails?.specialTermsAndConditions);
    setVendorAddress(draftDetails?.Vendor?.VendorMaster?.addressLine1);
    setAttachmentList(draftDetails?.listOfAttachments);
    setEmailMessage(draftDetails?.emailMessage);

    setLOIMessage(
      draftDetails?.loiOrLoa && draftDetails?.loiOrLoa === 'LOI'
        ? draftDetails?.loiOrLoaMessage
        : defaultClauses?.loiMessage?.defaultClause
    );
    setLOAMessage(
      draftDetails?.loiOrLoa && draftDetails?.loiOrLoa === 'LOA'
        ? draftDetails?.loiOrLoaMessage
        : defaultClauses?.loaMessage?.defaultClause
    );

    if (
      draftDetails?.iHerebyDeclareThereAreNoContradictionsInAttachments ===
      'Yes'
    ) {
      setValue(true);
    } else {
      setValue(false);
    }

    if (draftDetails?.isTheLoiAutoCancellationAfter30DaysAccepted === null) {
      setCheck(false);
      setCancellationMessage(defaultClauses?.checkbox?.defaultClause);
    } else {
      setCheck(true);
      setCancellationMessage(
        draftDetails?.isTheLoiAutoCancellationAfter30DaysAccepted
      );
    }

    setQuantityVariation(draftDetails?.quantityVariation);
    form.setFieldsValue({
      loi: formValues.loi ? formValues.loi : draftDetails?.loiOrLoa,
      approvers_0: draftDetails?.Approvers?.[0]?.name,
      approvers_1: draftDetails?.Approvers?.[1]?.name,
      loaMessage:
        loaMessage !== 'undefined'
          ? loaMessage
          : defaultClauses?.loaMessage?.defaultClause,
      loiMessage:
        loiMessage !== 'undefined'
          ? loiMessage
          : defaultClauses?.loiMessage?.defaultClause,
      po: formValues.po ? formValues.po : draftDetails?.poOrWo,
      message0:
        unitRatesMessage !== null
          ? unitRatesMessage
          : defaultClauses?.message0?.defaultClause,
      message3:
        inAdditionMessage !== null
          ? inAdditionMessage
          : defaultClauses?.message3?.defaultClause,
      tax_duties:
        taxDutiesMessage !== 'undefined'
          ? taxDutiesMessage
          : defaultClauses?.taxAndDuties?.defaultClause,
      v_name: draftDetails?.vendorName,
      v_site: draftDetails?.Vendor?.VendorMaster?.vendorSite,
      v_company: draftDetails?.Vendor?.VendorMaster?.vendorCode,
      v_site_address: draftDetails?.Vendor?.VendorMaster?.addressLine1,
      vendor_contact: draftDetails?.vendorsContactPerson,
      v_contact_no: draftDetails?.vendorsContactNumber,
      v_contact_id: draftDetails?.vendorsContactEMailId,
      bu: draftDetails?.BU?.BuMaster?.buName,
      project_name: draftDetails?.Project?.ProjectMaster?.projectName,
      project_Code: draftDetails?.Project?.ProjectMaster?.projectCode,
      vendor_quotation: draftDetails?.vendorQuotationReferenceDetails,
      agreement_Location: draftDetails?.agreementLocation,
      broad_scope: draftDetails?.broadScope,
      contract_price: draftDetails?.basicContractPrice,
      currency: draftDetails?.currency,
      price_basis: exists ? draftDetails?.priceBasis : 'Others',
      price_basis_text:
        draftDetails?.priceBasis === 'Others'
          ? undefined
          : draftDetails?.priceBasis,
      validitycontract: draftDetails?.validityOfContractInMonths,
      spl_terms: draftDetails?.specialTermsAndConditions,
      quantity_variation: draftDetails?.quantityVariation,
      check1: formValues.check1 ? formValues.check1 : value,
      all_tpl: draftDetails?.allOtherTermsAndConditions?.split(','),
      price_justification:
        draftDetails?.priceJustificationAndSelectionOfVendors,
      doc_list: draftDetails?.listOfAttachments,
      email_message: draftDetails?.emailMessage,
      cancellation_notice:
        draftDetails?.isTheLoiAutoCancellationAfter30DaysAccepted
          ? draftDetails?.isTheLoiAutoCancellationAfter30DaysAccepted
          : defaultClauses?.checkbox?.defaultClause
    });

    if (draftDetails) {
      populatePaymentTerms();
      populateBoq();
      populatePacking();
      populateDeliverySchedule();
      populateMobility();
      populateMrdt();
      populateLd();
      populateWarranty();
      populateDlp();
      populateContract();
      populateFreight();
    }
  }, [draftDetails]);

  useEffect(() => {
    setDynamicFields(current);
    setSelectedApprovers(formValues?.approver);
    setLOI(formValues?.loi);
    setPo(formValues?.po);
    setVendorName(formValues.v_name);
    setVendorSite(formValues.v_site);
    setVendorContact(formValues?.vendor_contact);
    setVendorContactNumber(formValues?.v_contact_no);
    setContactEmail(formValues?.v_contact_id);
    setVqrd(formValues?.vendor_quotation);
    setAgreementLocation(formValues?.agreement_Location);
    setBroadScope(formValues?.broad_scope);
    setCurrency(formValues?.currency);
    setBoq(boq);
    setPriceBasis(formValues?.price_basis);
    setPriceBasisText(formValues?.price_basis_text);
    setPayments(payments);
    setPacking(packing);
    setDelivery(delivery);
    setMobilisation(mobilisation);
    setMrdt(mrdt);
    setLD(ld);
    setWarranty(warranty);
    setDlp(dlp);
    setContractPerformance(contractPerformance);
    setFreight(freight);
    setContractValidity(formValues?.validitycontract);
    setSpecialTerms(formValues?.spl_terms);
    setQuantityVariation(formValues?.quantity_variation);
    setSelectedValues(formValues?.all_tpl);
    setPriceJustification(formValues?.price_justification);
    setAttachmentList(formValues?.doc_list);
    setEmailMessage(formValues?.email_message);
    if (formValues?.cancellation_notice === undefined) {
      setCheck(false);
      setCancellationMessage(defaultClauses?.checkbox?.defaultClause);
    } else {
      setCheck(true);
      setCancellationMessage(formValues?.cancellation_notice);
    }

    setValue(formValues.check1 ? formValues.check1 : value);
    console.log('CANC', formValues?.cancellation_notice);

    form.setFieldsValue({
      loi: formValues.loi,
      loiMessage: formValues.loiMessage
        ? formValues.loiMessage
        : defaultClauses?.loiMessage?.defaultClause,
      loaMessage: formValues.loaMessage
        ? formValues.loaMessage
        : defaultClauses?.loaMessage?.defaultClause,
      po: formValues.po,
      message0: formValues.message0
        ? formValues.message0
        : defaultClauses?.message0?.defaultClause,
      message3: formValues.message3
        ? formValues.message3
        : defaultClauses?.message3?.defaultClause,
      v_name: formValues.v_name,
      v_company: formValues.v_company,
      v_site: formValues.v_site,
      v_site_address: formValues.v_site_address,
      vendor_contact: formValues.vendor_contact,
      v_contact_id: formValues.v_contact_id,
      v_contact_no: formValues.v_contact_no,
      approvers_0: selectedApprovers?.[0]?.name,
      approvers_1: filteredCFOName.fullname,
      bu: formValues?.bu,
      project_name: formValues?.project_name,
      project_Code: formValues?.project_Code,
      vendor_quotation: formValues.vendor_quotation,
      doi: moment(date).format('DD MMM YY'),
      creator_email: user?.user?.email,
      contract_price: formValues?.contract_price,
      currency: formValues.currency,
      broad_scope: formValues.broad_scope,
      price_basis: formValues.price_basis,
      price_basis_text: formValues.price_basis_text,
      msg4: formValues.msg4,
      validitycontract: formValues.validitycontract,
      spl_terms: formValues.spl_terms,
      quantity_variation: formValues.quantity_variation,
      tax_duties: formValues.tax_duties
        ? formValues.tax_duties
        : defaultClauses?.taxAndDuties?.defaultClause,
      all_tpl: formValues.all_tpl,
      price_justification: formValues.price_justification,
      check1: formValues.check1,
      noContra: formValues.noContra,
      email_message: emailMessage,
      doc_list: formValues.doc_list,
      cancellation_notice: formValues.cancellation_notice,
      anti_bribery: formValues.anti_bribery,
      agreement_Location: formValues.agreement_Location
    });
  }, [current]);

  const handleDeleteAdvanceMessage = (item) => {
    delete advanceMessage[item];
    form.setFieldsValue({ [item]: '' });

    let advanceMessageCopy = [...advanceMessage];
    advanceMessageCopy = advanceMessageCopy.filter((o) => o !== item);
    setAdvanceMessage(advanceMessageCopy);
  };

  const handleAddAdvanceMessage = () => {
    let advanceMessageCopy = [...advanceMessage];

    let slug =
      advanceMessageCopy.length &&
      Math.max(advanceMessageCopy.map((o) => Number(o.slice(-1))).join(', ')) +
        1;
    advanceMessageCopy.push(`advance_message_${slug}`);

    setAdvanceMessage(advanceMessageCopy);
  };

  const handleDeleteFreight = (index, item) => {
    delete freight[item];
    form.setFieldsValue({ [item]: '' });

    let freightCopy = [...freight];
    // freightCopy = freightCopy.filter((o) => o !== item);
    freightCopy[index].deleted = true;
    setFreight(freightCopy);
  };

  const handleAddFreight = () => {
    let freightCopy = [...freight];
    freightCopy.push({ clauses: [''] });
    setFreight(freightCopy);
  };

  const handleDeleteBoq = (index, item, inputName) => {
    delete boq[item];
    // form.setFieldsValue({ [inputName]: undefined });
    let boqCopy = [...boq];
    // eslint-disable-next-line
    // boqCopy = boqCopy.filter((o) => o !== item);
    boqCopy[index].deleted = true;
    setBoq(boqCopy);

    const tempDynamicFields = formValues.dynamicFields || {};
    if (Object.prototype.hasOwnProperty.call(tempDynamicFields, inputName)) {
      delete tempDynamicFields[inputName];
    }
    setformValues({
      ...formValues,
      dynamicFields: tempDynamicFields
    });
  };

  const handleAddBoq = () => {
    let boqCopy = [...boq];

    boqCopy.push({ clauses: [{}] });

    setBoq(boqCopy);
  };

  const handleAddPacking = () => {
    let packingCopy = [...packing];

    packingCopy.push({ clauses: [{}] });

    setPacking(packingCopy);
  };

  const handleDeletePacking = (index, item) => {
    delete packing[item];
    form.setFieldsValue({ [item]: '' });
    let packingCopy = [...packing];
    // packingCopy = packingCopy.filter((o) => o !== item);
    packingCopy[index].deleted = true;

    setPacking(packingCopy);
  };

  const handleAddDelivery = () => {
    let deliveryCopy = [...delivery];

    deliveryCopy.push({ clauses: [{}] });

    setDelivery(deliveryCopy);
  };

  const handleDeleteDelivery = (index, item) => {
    delete delivery[item];
    form.setFieldsValue({ [item]: '' });
    let deliveryCopy = [...delivery];
    // deliveryCopy = deliveryCopy.filter((o) => o !== item);
    deliveryCopy[index].deleted = true;

    setDelivery(deliveryCopy);
  };

  const handleDeleteContractPerformance = (index, item, inputName) => {
    delete contractPerformance[item];
    // form.setFieldsValue({ [inputName]: undefined });
    let contractPerformanceCopy = [...contractPerformance];
    // eslint-disable-next-line
    // contractPerformanceCopy = contractPerformanceCopy.filter((o) => o !== item);
    contractPerformanceCopy[index].deleted = true;
    setContractPerformance(contractPerformanceCopy);

    const tempDynamicFields = formValues.dynamicFields || {};
    if (Object.prototype.hasOwnProperty.call(tempDynamicFields, inputName)) {
      delete tempDynamicFields[inputName];
    }
    setformValues({
      ...formValues,
      dynamicFields: tempDynamicFields
    });
  };

  const handleAddContractPerformance = () => {
    let contractPerformanceCopy = [...contractPerformance];

    contractPerformanceCopy.push({ clauses: [{}] });

    setContractPerformance(contractPerformanceCopy);
  };

  const handleDeleteDLP = (index, item) => {
    delete dlp[item];
    form.setFieldsValue({ [item]: '' });

    let dlpCopy = [...dlp];
    // eslint-disable-next-line
    // dlpCopy = dlpCopy.filter((o) => o !== item);
    dlpCopy[index].deleted = true;

    setDlp(dlpCopy);
  };

  const onChangeBU = (value) => {
    dispatch(getAllProjects(value));
    const selectValue = value;

    const fil = allBu.filter((allBu) => allBu.id == selectValue);

    setProjectCodes(true);
    setBuJv(value);
    setBuId({ id: fil[0].id });
    form.setFieldsValue({ bu: fil[0].buName });
    form.setFieldsValue({
      project_name: undefined
    });
    form.setFieldsValue({
      project_Code: undefined
    });
  };

  const onChangeProjectName = (e) => {
    const selectValue = e;

    const filtered = allProjects.filter(
      (allProjects) => allProjects.projectName == selectValue
    );

    setProjectId({ id: modalFormValues?.projectId });
    form.setFieldsValue({
      project_Code: filtered[0].projectCode
    });
  };

  const handleAddDLP = () => {
    let dlpCopy = [...dlp];

    // let slug =
    //   dlpCopy.length &&
    //   Math.max(dlpCopy.map((o) => Number(o.slice(-1))).join(', ')) + 1;
    dlpCopy.push({ clauses: [''] });

    setDlp(dlpCopy);
  };

  const handleDeleteWarranty = (index, item) => {
    delete warranty[item];
    form.setFieldsValue({ [item]: '' });

    let warrantyCopy = [...warranty];
    // warrantyCopy = warrantyCopy.filter((o) => o !== item);
    warrantyCopy[index].deleted = true;

    setWarranty(warrantyCopy);
  };

  const handleAddWarranty = () => {
    let warrantyCopy = [...warranty];

    // let slug =
    //   warrantyCopy.length &&
    //   Math.max(warrantyCopy.map((o) => Number(o.slice(-1))).join(', ')) + 1;
    // warrantyCopy.push(`warranty_${slug}`);
    warrantyCopy.push({ clauses: [''] });

    setWarranty(warrantyCopy);
  };

  const handleDeleteLD = (index, item) => {
    delete ld[item];
    form.setFieldsValue({ [item]: '' });

    let ldCopy = [...ld];
    // ldCopy = ldCopy.filter((o) => o !== item);
    ldCopy[index].deleted = true;

    setLD(ldCopy);
  };

  /*   const handleDocumentDelete = (id) => {
    const payload = {
      headerName: 'Attachments',
      id: id,
      deleteWholeHeader: 'yes'
    };
    dispatch(deleteDraftImages(payload));
  }; */

  const handleAddLD = () => {
    let ldCopy = [...ld];

    // let slug =
    //   ldCopy.length &&
    //   Math.max(ldCopy.map((o) => Number(o.slice(-1))).join(', ')) + 1;
    ldCopy.push({ clauses: [''] });

    setLD(ldCopy);
  };

  const handleMRDT = (obj) => {
    setMrdt({
      ...mrdt,
      ...obj
    });
  };

  const handleDeleteMRDT = (index, item) => {
    delete mrdt[item];
    form.setFieldsValue({ [item]: '' });

    let mrdtCopy = [...mrdt];
    // mrdtCopy = mrdtCopy.filter((o) => o !== item);
    mrdtCopy[index].deleted = true;

    setMrdt(mrdtCopy);
  };

  const handleAddMRDT = () => {
    let mrdtCopy = [...mrdt];

    mrdtCopy.push({ clauses: [''] });

    setMrdt(mrdtCopy);
  };

  const handleMobilisation = (obj) => {
    setMobilisation({
      ...mobilisation,
      ...obj
    });
  };

  const handleDeleteMobilisation = (index, item) => {
    delete mobilisation[item];
    form.setFieldsValue({ [item]: '' });

    let mobilisationCopy = [...mobilisation];
    // mobilisationCopy = mobilisationCopy.filter((o) => o !== item);
    mobilisationCopy[index].deleted = true;

    setMobilisation(mobilisationCopy);
  };

  const handleAddMobilisation = () => {
    let mobilisationCopy = [...mobilisation];

    // let slug =
    //   mobilisationCopy.length &&
    //   Math.max(mobilisationCopy.map((o) => Number(o.slice(-1))).join(', ')) + 1;
    mobilisationCopy.push({ clauses: [''] });

    setMobilisation(mobilisationCopy);
  };

  const handleDeleteFreightMessage = (item) => {
    delete freightMessage[item];
    form.setFieldsValue({ [item]: '' });

    let freightMessageCopy = [...freightMessage];
    freightMessageCopy = freightMessageCopy.filter((o) => o !== item);

    setFreightMessage(freightMessageCopy);
  };

  const handleAddFreightMessage = () => {
    let freightMessageCopy = [...freightMessage];

    let slug =
      freightMessageCopy.length &&
      Math.max(freightMessageCopy.map((o) => Number(o.slice(-1))).join(', ')) +
        1;
    freightMessageCopy.push(`freight_message_${slug}`);

    setFreightMessage(freightMessageCopy);
  };

  const handleDeleteWarrantyMessage = (item) => {
    delete warrantyMessage[item];
    form.setFieldsValue({ [item]: '' });

    let warrantyMessageCopy = [...warrantyMessage];
    warrantyMessageCopy = warrantyMessageCopy.filter((o) => o !== item);

    setWarrantyMessage(warrantyMessageCopy);
  };

  const handleAddWarrantyMessage = () => {
    let warrantyMessageCopy = [...warrantyMessage];

    let slug =
      warrantyMessageCopy.length &&
      Math.max(warrantyMessageCopy.map((o) => Number(o.slice(-1))).join(', ')) +
        1;
    warrantyMessageCopy.push(`warranty_message_${slug}`);

    setWarrantyMessage(warrantyMessageCopy);
  };

  const handleDeleteMRDTMessage = (item) => {
    delete mrdtMessage[item];
    form.setFieldsValue({ [item]: '' });

    let mrdtMessageCopy = [...mrdtMessage];
    mrdtMessageCopy = mrdtMessageCopy.filter((o) => o !== item);

    setMrdtMessage(mrdtMessageCopy);
  };

  const handleAddMRDTMessage = () => {
    let mrdtMessageCopy = [...mrdtMessage];
    let slug =
      mrdtMessageCopy.length &&
      Math.max(mrdtMessageCopy.map((o) => Number(o.slice(-1))).join(', ')) + 1;
    mrdtMessageCopy.push(`mrdt_message_${slug}`);

    setMrdtMessage(mrdtMessageCopy);
  };

  const handleDeleteMobilisationMessage = (item) => {
    delete mobilisationMessage[item];
    form.setFieldsValue({ [item]: '' });

    let mobilisationMessageCopy = [...mobilisationMessage];
    mobilisationMessageCopy = mobilisationMessageCopy.filter((o) => o !== item);

    setMobilisationMessage(mobilisationMessageCopy);
  };

  const handleMobilisationMessage = () => {
    let mobilisationMessageCopy = [...mobilisationMessage];

    let slug =
      mobilisationMessageCopy.length &&
      Math.max(
        mobilisationMessageCopy.map((o) => Number(o.slice(-1))).join(', ')
      ) + 1;
    mobilisationMessageCopy.push(`mob_time_message_${slug}`);

    setMobilisationMessage(mobilisationMessageCopy);
  };

  const handleDeleteProgressPercent1 = (item) => {
    delete progressPercent1[item];
    form.setFieldsValue({ [item]: '' });

    let progressPercent1Copy = [...progressPercent1];
    progressPercent1Copy = progressPercent1Copy.filter((o) => o !== item);

    setProgressPercent1(progressPercent1Copy);
  };

  const handleAddProgressPercent1 = () => {
    let progressPercent1Copy = [...progressPercent1];

    let slug =
      progressPercent1Copy.length &&
      Math.max(
        progressPercent1Copy.map((o) => Number(o.slice(-1))).join(', ')
      ) + 1;
    progressPercent1Copy.push(`progress_message_${slug}`);

    setProgressPercent1(progressPercent1Copy);
  };

  const handleDeleteRetentionMessage = (item) => {
    delete retentionMessage[item];
    form.setFieldsValue({ [item]: '' });
    let retentionMessageCopy = [...retentionMessage];
    retentionMessageCopy = retentionMessageCopy.filter((o) => o !== item);

    setRetentionMessage(retentionMessageCopy);
  };

  const handleAddRetentionMessage = () => {
    let retentionMessageCopy = [...retentionMessage];

    let slug =
      retentionMessageCopy.length &&
      Math.max(
        retentionMessageCopy.map((o) => Number(o.slice(-1))).join(', ')
      ) + 1;
    retentionMessageCopy.push(`retention_message_${slug}`);

    setRetentionMessage(retentionMessageCopy);
  };

  const handleDeleteRetention = (index) => {
    let retentionCopy = [...retention];
    // eslint-disable-next-line
    retentionCopy = retentionCopy.filter((_, i) => i != index);

    setRetention(retentionCopy);
  };

  /* const handleAddRetention = () => {
    let retentionCopy = [...retention];

    retentionCopy.push({
      advanceMessage: ''
    });

    setRetention(retentionCopy);
  };
 */

  const handleDeleteContractMessage2 = (item) => {
    delete contractMessage2[item];
    form.setFieldsValue({ [item]: '' });

    let contractMessage2Copy = [...contractMessage2];
    contractMessage2Copy = contractMessage2Copy.filter((o) => o !== item);

    setContractMessage2(contractMessage2Copy);
  };
  const onVendorSearch = (value) => {
    setVendorName(value);

    return value;
  };

  const onApproverSearch = (value) => {
    setApproverName(value);

    return value;
  };

  const onSBGSearch = (value) => {
    setSbgName(value);

    return value;
  };

  const onVendorLocationSearch = (value) => {
    setVendorLocation(value);

    return value;
  };

  const onBuSearch = (value) => {
    setBuName(value);

    return value;
  };

  const onProjectSearch = (value) => {
    setProjectName(value);

    return value;
  };

  function toggle(value) {
    return !value;
  }

  const handleAddContractMessage2 = () => {
    let contractMessage2Copy = [...contractMessage2];

    let slug =
      contractMessage2Copy.length &&
      Math.max(
        contractMessage2Copy.map((o) => Number(o.slice(-1))).join(', ')
      ) + 1;
    contractMessage2Copy.push(`freight_message_${slug}`);

    setContractMessage2(contractMessage2Copy);
  };
  const handleDeleteDefectLiabilityMessage1 = (item) => {
    delete defectLiabilityMessage1[item];
    form.setFieldsValue({ [item]: '' });

    let defectLiabilityMessage1Copy = [...defectLiabilityMessage1];
    defectLiabilityMessage1Copy = defectLiabilityMessage1Copy.filter(
      (o) => o !== item
    );

    setDefectLiabilityMessage1(defectLiabilityMessage1Copy);
  };

  const handleAddDefectLiabilityMessage1 = () => {
    let defectLiabilityMessage1Copy = [...defectLiabilityMessage1];

    let slug =
      defectLiabilityMessage1Copy.length &&
      Math.max(
        defectLiabilityMessage1Copy.map((o) => Number(o.slice(-1))).join(', ')
      ) + 1;
    defectLiabilityMessage1Copy.push(`dlp_message_${slug}`);

    setDefectLiabilityMessage1(defectLiabilityMessage1Copy);
  };

  const handleDeleteDeliveryMessage1 = (item) => {
    delete deliveryMessage1[item];
    form.setFieldsValue({ [item]: '' });

    let deliveryMessageCopy1 = [...deliveryMessage1];
    deliveryMessageCopy1 = deliveryMessageCopy1.filter((o) => o !== item);

    setDeliveryMessage1(deliveryMessageCopy1);
  };

  const handleAddDeliveryMessage1 = () => {
    let deliveryMessageCopy1 = [...deliveryMessage1];
    let slug =
      deliveryMessageCopy1.length &&
      Math.max(
        deliveryMessageCopy1.map((o) => Number(o.slice(-1))).join(', ')
      ) + 1;
    deliveryMessageCopy1.push(`delivery_message${slug}`);

    setDeliveryMessage1(deliveryMessageCopy1);
  };

  const handleDeleteClauseMessage = (item) => {
    delete clauseMessage[item];
    form.setFieldsValue({ [item]: '' });

    let clauseMessageCopy = [...clauseMessage];
    clauseMessageCopy = clauseMessageCopy.filter((o) => o !== item);

    setClauseMessage(clauseMessageCopy);
  };

  const handleAddClauseMessage = () => {
    let clauseMessageCopy = [...clauseMessage];

    let slug =
      clauseMessageCopy.length &&
      Math.max(clauseMessageCopy.map((o) => Number(o.slice(-1))).join(', ')) +
        1;
    clauseMessageCopy.push(`ld_message_${slug}`);

    setClauseMessage(clauseMessageCopy);
  };

  const handleDeleteProgressMessage = (index) => {
    let progressMessageCopy = [...progressMessage];
    // eslint-disable-next-line
    progressMessageCopy = progressMessageCopy.filter((_, i) => i != index);

    setProgressMessage(progressMessageCopy);
  };

  const handleCapacity = (e) => {
    setSelect(e);
    setPo(e);
  };

  const handleLOI = (e) => {
    setLOI(e);
  };

  const handlePayments = (obj, value, inputName) => {
    setSelectedOption({
      ...selectedOption,
      ...obj
    });
    setformValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
  };

  const handleschedule = (e) => {
    setSelectedSchedule([...selectedSchedule, { schedule: e.target.value }]);
  };

  const handleApprovers = (e) => {
    const approver = filteredApproverName.filter((allApprovers) =>
      allApprovers.id.toLowerCase().includes(e.toLowerCase())
    );

    /*  setSelectedApprovers([]); */
    setSelectedApprovers([
      { name: approver[0]?.fullname, userId: e, index: 1 },
      {
        name: `${allCFO[0]?.givenName} ${allCFO[0]?.surname} `,
        userId: allCFO[0]?.id,
        index: 2
      }
    ]);
  };

  const handleSBGS = (e) => {
    const SBG = filteredSBGName.filter((allApprovers) =>
      allApprovers.id.toLowerCase().includes(e.toLowerCase())
    );

    /*  setSelectedApprovers([]); */
    setSelectedApprovers([{ name: SBG[0]?.fullname, userId: e, index: 1 }]);
  };

  const handleDeleteContact = (index) => {
    let contactsCopy = [...contacts];
    // eslint-disable-next-line
    contactsCopy = contactsCopy.filter((_, i) => i != index);

    setContacts(contactsCopy);
  };

  const handleAddPayment = () => {
    let paymentsCopy = [...payments];

    paymentsCopy.push({ ...DefaultPaymentObject });

    setPayments(paymentsCopy);
  };

  const handleDeletePayment = (index, item, inputName) => {
    delete selectedOption[item];
    // form.setFieldsValue({ [inputName]: null });

    let paymentsCopy = [...payments];
    paymentsCopy[index].deleted = true;
    // paymentsCopy = paymentsCopy.filter((o) => o !== item);

    setPayments(paymentsCopy);
    const tempDynamicFields = formValues.dynamicFields || {};
    if (Object.prototype.hasOwnProperty.call(tempDynamicFields, inputName)) {
      delete tempDynamicFields[inputName];
    }
    setformValues({
      ...formValues,
      dynamicFields: tempDynamicFields
    });
  };

  const onChange = (e) => {
    setValue(e.target.checked);
  };

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: '',
      docs: ''
    });

    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e) => {
    let newDocValues = [...supportingDoc, e.target.files[0]];

    setSupportingDoc(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = [...supportingDoc];
    newDocValues.splice(index, 1);
    setSupportingDoc(newDocValues);
  };

  const handleOnchange = (e) => {
    setVendorName(e);
    form.setFieldsValue({
      v_site: undefined
    });

    form.setFieldsValue({
      v_site_address: undefined
    });
    form.setFieldsValue({
      v_company: undefined
    });
  };

  const handleLocationOnchange = (e) => {
    const selectValue = e;
    const filtered = allVendorLocations.filter(
      (allVendorLocations) => allVendorLocations.id == selectValue
    );

    const address = `${filtered[0]?.addressLine1}${
      filtered[0]?.addressLine2 === null ? '' : `, ${filtered[0]?.addressLine2}`
    }${
      filtered[0]?.addressLine2_1 === null
        ? ''
        : `, ${filtered[0]?.addressLine2_1}`
    }`;
    setVendorId({ id: e });

    form.setFieldsValue({ v_site: filtered[0].vendorSite });
    form.setFieldsValue({
      v_site_address: address
    });
    setVendorAddress(address);

    form.setFieldsValue({ v_company: filtered[0].vendorCode });
  };

  const categoryCounters = {};

  const handleEmail = (e) => {
    const value = e.target.value;
    setContactEmail(value);
    let domain = value.substring(value.lastIndexOf('@'));
    let before_domain = value.substring(0, value.indexOf('@'));
    let chack_mail = before_domain.slice(-2);
    if (chack_mail === '-v' || chack_mail === '-c') {
      setEmailreg(false);
    } else if (domain !== '@tataprojects.com') {
      setEmailreg(false);
    } else {
      setEmailreg(true);
    }
  };

  const handleCancel = () => {
    localStorage.removeItem('formValues');
    localStorage.removeItem('checkLimit');
  };

  const vendorQuotation = (e) => {
    setVqrd(e);
  };

  return (
    <>
      {isDraftLoading ? (
        <div style={{ height: '100vh' }} className="df-jc-ac">
          <Spin />
        </div>
      ) : (
        <Form
          validateMessages={validateMessages}
          form={form}
          layout="vertical"
          className={`ant-form ant-form-vertical  ${styles.form_width}`}
          onFinish={onFinish}
          onSubmitCapture={() => {
            setFormSubmitted(true);
          }}
        >
          <div className={`w-100 ${styles.init_env_header}`}>
            <Row>
              <Col
                span={16}
                className={`${styles.header_text} sfprotext-bold font-22`}
              >
                Finish the draft LoI/LoA {myParam}
              </Col>

              <Col
                className="pull-left justify-content-end df-jb-ac mr-2 "
                span={2}
              >
                <CustomButton onClick={handleCancel}>
                  <Link to="/dashboard">Cancel</Link>
                </CustomButton>
              </Col>
              <Col
                className="pull-left justify-content-end df-jb-ac ml-2 mr-2 "
                span={2}
              >
                <CustomButton onClick={() => handleFormSubmit('draft')}>
                  Save as Draft
                </CustomButton>
              </Col>
              <Col className="pull-right" span={2}>
                <CustomButton type="primary" htmlType="submit">
                  Next
                </CustomButton>
              </Col>
            </Row>
          </div>{' '}
          <div className={`${styles.container}`}>
            <Card className={styles.form_card}>
              <Row className={styles.tabs_holder}> </Row>
              <div className={`${styles.dot}`}>1</div>
              <h2 className="sfprotext-bold font-22">Project details</h2>
              <Row
                className="mt-4"
                gutter={{
                  span: 8,
                  sm: 16,
                  md: 24,
                  lg: 32
                }}
              >
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Select Letter Type"
                    name="loi"
                    rules={[
                      {
                        required: true,
                        message: 'Select Letter Type'
                      }
                    ]}
                  >
                    <Select
                      onChange={handleLOI}
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Select Letter Type"
                    >
                      <Option value="LOI">LOI</Option>
                      <Option value="LOA">LOA</Option>
                    </Select>
                  </FormItem>
                </Col>
                {/* SECOND ROW */}
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label={'PO / WO'}
                    name="po"
                    rules={[
                      {
                        required: true,
                        message: 'Select Department'
                      }
                    ]}
                  >
                    <Select
                      onChange={handleCapacity}
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Select Department"
                    >
                      <Option value="PO">PO</Option>
                      <Option value="WO">WO</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Date of Issuance"
                    name="doi"
                    rules={[
                      {
                        required: true,
                        message: 'Select date'
                      }
                    ]}
                  >
                    <Input
                      disabled
                      defaultValue={moment(date).format('DD MMM YY')}
                      className="w-100"
                    />
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Vendor Name"
                    name="v_name"
                    rules={[
                      {
                        required: true,
                        message: 'Select Vendor Name'
                      }
                    ]}
                  >
                    <Select
                      onSearch={onVendorSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      showSearch
                      onChange={(e) => handleOnchange(e)}
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Enter Vendor Name"
                    >
                      {allVendors?.map((item, index) => (
                        <Option key={index} value={item.vendorName}>
                          {item.vendorName}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>{' '}
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Vendor Site"
                    name="v_site"
                    rules={[
                      {
                        required: true,
                        message: `Select Vendor Site`
                      }
                    ]}
                  >
                    <Select
                      onSearch={onVendorLocationSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(e) => {
                        setVendorSite(e);
                        handleLocationOnchange(e);
                      }}
                      showSearch
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Vendor Site"
                    >
                      {allVendorLocations?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.vendorSite}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Vendor ERP code"
                    name="v_company"
                    rules={[
                      {
                        required: true,
                        message: `Select Vendor ERP code`
                      }
                    ]}
                  >
                    <Input disabled placeholder="Select Vendor ERP code" />
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Vendor Site Address"
                    name="v_site_address"
                    rules={[
                      {
                        required: true,
                        message: `Select Vendor Site Address`
                      }
                    ]}
                  >
                    <Input
                      onChange={(e) => setVendorAddress(e.target.value)}
                      disabled
                      placeholder={`Select Vendor Site Address`}
                    />
                  </FormItem>
                </Col>
                {/* Third Row */}
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Vendor's Contact Person"
                    name="vendor_contact"
                    rules={[
                      {
                        required: true,
                        message: `Enter Vendor's Contact Person`
                      }
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        setVendorContact(e.target.value);
                      }}
                      placeholder={`Enter Vendor's Contact Person`}
                    />
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Vendor's Contact E-mail ID"
                    name="v_contact_id"
                    rules={[
                      {
                        type: 'email',
                        required: true,
                        pattern: new RegExp(
                          // eslint-disable-next-line
                          `[0-9a-zA-Z.+_]+@[0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}`
                        ),
                        message: 'Enter valid email'
                      }
                    ]}
                  >
                    <Input
                      onChange={handleEmail}
                      placeholder="Enter Vendor's Contact Email"
                    />
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Vendor's Contact Number"
                    name="v_contact_no"
                    rules={[
                      {
                        required: true,
                        message: 'Enter valid contact'
                      }
                    ]}
                  >
                    <InputNumber
                      onChange={(e) => setVendorContactNumber(e)}
                      minLength={8}
                      maxLength={10}
                      controls={false}
                      className={`w-100`}
                      placeholder="Enter Vendor's Contact Number"
                    />
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="BU-JV Name"
                    name="bu"
                    rules={[
                      {
                        required: true,
                        message: 'Select BU-JV name'
                      }
                    ]}
                  >
                    <Select
                      disabled
                      defaultValue={modalFormValues?.bu}
                      onChange={onChangeBU}
                      onSearch={onBuSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      showSearch
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Select BU-JV name"
                    >
                      {' '}
                      {allBu?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.buName}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>{' '}
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Project Name "
                    name="project_name"
                    rules={[
                      {
                        required: true,
                        message: 'Select Project Name'
                      }
                    ]}
                  >
                    <Select
                      disabled
                      defaultValue={modalFormValues?.project_name}
                      onChange={(e) => onChangeProjectName(e)}
                      showSearch
                      onSearch={onProjectSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Select Project Name"
                    >
                      {allProjects?.map((item, index) => (
                        <Option key={index} value={item.projectName}>
                          {item.projectName}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>{' '}
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Project Code "
                    name="project_Code"
                    rules={[
                      {
                        required: false,
                        message: 'Select Project Code'
                      }
                    ]}
                  >
                    <Input disabled placeholder="Project Code" />
                  </FormItem>
                </Col>{' '}
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label="Vendor Quotation Reference Details"
                    name="vendor_quotation"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Vendor Quotation Reference Details'
                      }
                    ]}
                  >
                    <Input
                      onChange={(e) => vendorQuotation(e.target.value)}
                      placeholder="Enter Vendor Quotation Reference Details"
                    />
                  </FormItem>
                </Col>{' '}
                <Col className="gutter-row" span={7}>
                  <FormItem
                    label=" Agreement Location"
                    name="agreement_Location"
                    rules={[
                      {
                        required: true,
                        message: 'Select Agreement Location'
                      }
                    ]}
                  >
                    <Select
                      onChange={(e) => setAgreementLocation(e)}
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Select Agreement Location"
                    >
                      <Option value="Hyderabad">Hyderabad</Option>
                      <Option value="Mumbai-HKP">Mumbai- HKP</Option>
                      <Option value="Noida">Noida</Option>
                      <Option value="Mumbai-Delphi">Mumbai- Delphi</Option>
                      <Option value="Mumbai-OneBoulevard">
                        Mumbai- One Boulevard
                      </Option>
                    </Select>
                  </FormItem>
                </Col>{' '}
                {loi === 'LOA' && (
                  <>
                    <Col className="gutter-row" span={24}>
                      <FormItem
                        initialValue={
                          loaMessage === 'undefined'
                            ? defaultClauses?.loaMessage?.defaultClause
                            : loaMessage
                        }
                        /* label="Message 1" */
                        name="loaMessage"
                        rules={[
                          {
                            required: true,
                            message: 'Enter message'
                          }
                        ]}
                      >
                        <TextArea
                          value={formValues.loaMessage}
                          defaultValue={
                            defaultClauses?.loaMessage?.defaultClause || ''
                          }
                          allowClear
                          rows={4}
                          className={`w-100`}
                          placeholder="Enter message"
                          onChange={(e) => {
                            handleInputChange('loaMessage', e.target.value),
                              setLOAMessage(e.target.value);
                          }}
                        />
                      </FormItem>
                    </Col>
                  </>
                )}
                {loi === 'LOI' && (
                  <>
                    <Col className="gutter-row" span={24}>
                      <FormItem
                        initialValue={
                          loiMessage === 'undefined'
                            ? defaultClauses?.loiMessage?.defaultClause
                            : loiMessage
                        }
                        /* label="Message 1" */
                        name="loiMessage"
                        rules={[
                          {
                            required: true,
                            message: 'Enter message'
                          }
                        ]}
                      >
                        <TextArea
                          defaultValue={
                            defaultClauses?.loiMessage?.defaultClause || ''
                          }
                          allowClear
                          value={formValues.loiMessage}
                          rows={4}
                          className={`w-100`}
                          placeholder="Enter message"
                          onChange={(e) => {
                            handleInputChange('loiMessage', e.target.value),
                              setLOIMessage(e.target.value);
                          }}
                        />
                      </FormItem>
                    </Col>
                  </>
                )}
                <Col className="gutter-row" span={24}>
                  <FormItem
                    label="Broad Scope"
                    name="broad_scope"
                    rules={[
                      {
                        required: true,
                        message: 'Enter broad scope'
                      }
                    ]}
                  >
                    <ReactQuill
                      placeholder="Enter broad scope"
                      theme="snow"
                      onChange={(e) => setBroadScope(e)}
                    />
                  </FormItem>
                </Col>{' '}
                <Col className="gutter-row" span={10}>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <FormItem
                        label={'LOI Value'}
                        name="contract_price"
                        rules={[
                          {
                            required: true,
                            message: 'Enter value'
                          }
                        ]}
                      >
                        <InputNumber
                          onChange={onChangeValue}
                          stringMode
                          // disabled
                          // defaultValue={loiVal}
                          className="w-100"
                          controls={false}
                          min={0}
                          placeholder="Enter value"
                        />
                      </FormItem>
                    </Col>

                    <Col span={12}>
                      <FormItem
                        name="currency"
                        rules={[
                          {
                            required: true,
                            message: 'Select currency'
                          }
                        ]}
                      >
                        <Select
                          onChange={(e) => {
                            setCurrency(e);
                          }}
                          showSearch
                          style={{ marginTop: '26px' }}
                          suffixIcon={<CaretDownFilled />}
                          placeholder="Select currency"
                        >
                          <Option value="AFN" label="Afghan afghani">
                            AFN
                          </Option>
                          <Option value="ALL" label="Albanian lek">
                            ALL
                          </Option>
                          <Option value="DZD" label="Algerian dinar">
                            DZD
                          </Option>
                          <Option value="AOA" label="Angolan kwanza">
                            AOA
                          </Option>
                          <Option value="ARS" label="Argentine peso">
                            ARS
                          </Option>
                          <Option value="AMD" label="Armenian dram">
                            AMD
                          </Option>
                          <Option value="AWG" label="Aruban florin">
                            AWG
                          </Option>
                          <Option value="AUD" label="Australian dollar">
                            AUD
                          </Option>
                          <Option value="AZN" label="Azerbaijani manat">
                            AZN
                          </Option>
                          <Option value="BHD" label="Bahraini dinar">
                            BHD
                          </Option>
                          <Option value="BSD" label="Bahamian dollar">
                            BSD
                          </Option>
                          <Option value="BDT" label="Bangladeshi taka">
                            BDT
                          </Option>
                          <Option value="BBD" label="Barbadian dollar">
                            BBD
                          </Option>
                          <Option value="BYN" label="Belarusian ruble">
                            BYN
                          </Option>
                          <Option value="BZD" label="Belize dollar">
                            BZD
                          </Option>
                          <Option value="BMD" label="Bermudian dollar">
                            BMD
                          </Option>
                          <Option value="BTN" label="Bhutanese ngultrum">
                            BTN
                          </Option>
                          <Option value="BOB" label="Bolivian boliviano">
                            BOB
                          </Option>
                          <Option
                            value="BAM"
                            label="Bosnia and Herzegovina convertible mark"
                          >
                            BAM
                          </Option>
                          <Option value="BWP" label="Botswana pula">
                            BWP
                          </Option>
                          <Option value="BRL" label="Brazilian real">
                            BRL
                          </Option>
                          <Option value="GBP" label="British pound">
                            GBP
                          </Option>
                          <Option value="BND" label="Brunei dollar">
                            BND
                          </Option>
                          <Option value="MMK" label="Burmese kyat">
                            MMK
                          </Option>
                          <Option value="BIF" label="Burundian franc">
                            BIF
                          </Option>
                          <Option value="KHR" label="Cambodian riel">
                            KHR
                          </Option>
                          <Option value="CAD" label="Canadian dollar">
                            CAD
                          </Option>
                          <Option value="CVE" label="Cape Verdean escudo">
                            CVE
                          </Option>
                          <Option value="KYD" label="Cayman Islands dollar">
                            KYD
                          </Option>
                          <Option value="XAF" label="Central African CFA franc">
                            XAF
                          </Option>
                          <Option value="XPF" label="CFP franc">
                            XPF
                          </Option>
                          <Option value="CLP" label="Chilean peso">
                            CLP
                          </Option>
                          <Option value="CNY" label="Chinese yuan">
                            CNY
                          </Option>
                          <Option value="COP" label="Colombian peso">
                            COP
                          </Option>
                          <Option value="KMF" label="Comorian franc">
                            KMF
                          </Option>
                          <Option value="CDF" label="Congolese franc">
                            CDF
                          </Option>
                          <Option value="CRC" label="Costa Rican colón">
                            CRC
                          </Option>
                          <Option value="HRK" label="Croatian kuna">
                            HRK
                          </Option>
                          <Option value="CUC" label="Cuban convertible peso">
                            CUC
                          </Option>
                          <Option value="CUP" label="Cuban peso">
                            CUP
                          </Option>
                          <Option value="CZK" label="Czech koruna">
                            CZK
                          </Option>
                          <Option value="DKK" label="Danish krone">
                            DKK
                          </Option>
                          <Option value="DOP" label="Dominican peso">
                            DOP
                          </Option>
                          <Option value="DJF" label="Djiboutian franc">
                            DJF
                          </Option>
                          <Option value="XCD" label="Eastern Caribbean dollar">
                            XCD
                          </Option>
                          <Option value="EGP" label="Egyptian pound">
                            EGP
                          </Option>
                          <Option value="ERN" label="Eritrean nakfa">
                            ERN
                          </Option>
                          <Option value="ETB" label="Ethiopian birr">
                            ETB
                          </Option>
                          <Option value="EUR" label="Euro">
                            EUR
                          </Option>
                          <Option value="FKP" label="Falkland Islands pound">
                            FKP
                          </Option>
                          <Option value="FJD" label="Fijian dollar">
                            FJD
                          </Option>
                          <Option value="GMD" label="Gambian dalasi">
                            GMD
                          </Option>
                          <Option value="GEL" label="Georgian lari">
                            GEL
                          </Option>
                          <Option value="GHS" label="Ghanaian cedi">
                            GHS
                          </Option>
                          <Option value="GIP" label="Gibraltar pound">
                            GIP
                          </Option>
                          <Option value="GTQ" label="Guatemalan quetzal">
                            GTQ
                          </Option>
                          <Option value="GGP" label="Guernsey pound">
                            GGP
                          </Option>
                          <Option value="GNF" label="Guinean franc">
                            GNF
                          </Option>
                          <Option value="GYD" label="Guyanese dollar">
                            GYD
                          </Option>
                          <Option value="HTG" label="Haitian gourde">
                            HTG
                          </Option>
                          <Option value="HNL" label="Honduran lempira">
                            HNL
                          </Option>
                          <Option value="HKD" label="Hong Kong dollar">
                            HKD
                          </Option>
                          <Option value="HUF" label="Hungarian forint">
                            HUF
                          </Option>
                          <Option value="ISK" label="Icelandic króna">
                            ISK
                          </Option>
                          <Option value="INR" label="Indian rupee">
                            INR
                          </Option>
                          <Option value="IDR" label="Indonesian rupiah">
                            IDR
                          </Option>
                          <Option value="IRR" label="Iranian rial">
                            IRR
                          </Option>
                          <Option value="IQD" label="Iraqi dinar">
                            IQD
                          </Option>
                          <Option value="ILS" label="Israeli new shekel">
                            ILS
                          </Option>
                          <Option value="JMD" label="Jamaican dollar">
                            JMD
                          </Option>
                          <Option value="JPY" label="Japanese yen">
                            JPY
                          </Option>
                          <Option value="JEP" label="Jersey pound">
                            JEP
                          </Option>
                          <Option value="JOD" label="Jordanian dinar">
                            JOD
                          </Option>
                          <Option value="KZT" label="Kazakhstani tenge">
                            KZT
                          </Option>
                          <Option value="KES" label="Kenyan shilling">
                            KES
                          </Option>
                          <Option value="KID" label="Kiribati dollar">
                            KID
                          </Option>
                          <Option value="KGS" label="Kyrgyzstani som">
                            KGS
                          </Option>
                          <Option value="KWD" label="Kuwaiti dinar">
                            KWD
                          </Option>
                          <Option value="LAK" label="Lao kip">
                            LAK
                          </Option>
                          <Option value="LBP" label="Lebanese pound">
                            LBP
                          </Option>
                          <Option value="LSL" label="Lesotho loti">
                            LSL
                          </Option>
                          <Option value="LRD" label="Liberian dollar">
                            LRD
                          </Option>
                          <Option value="LYD" label="Libyan dinar">
                            LYD
                          </Option>
                          <Option value="MOP" label="Macanese pataca">
                            MOP
                          </Option>
                          <Option value="MKD" label="Macedonian denar">
                            MKD
                          </Option>
                          <Option value="MGA" label="Malagasy ariary">
                            MGA
                          </Option>
                          <Option value="MWK" label="Malawian kwacha">
                            MWK
                          </Option>
                          <Option value="MYR" label="Malaysian ringgit">
                            MYR
                          </Option>
                          <Option value="MVR" label="Maldivian rufiyaa">
                            MVR
                          </Option>
                          <Option value="IMP" label="Manx pound">
                            IMP
                          </Option>
                          <Option value="MRU" label="Mauritanian ouguiya">
                            MRU
                          </Option>
                          <Option value="MUR" label="Mauritian rupee">
                            MUR
                          </Option>
                          <Option value="MXN" label="Mexican peso">
                            MXN
                          </Option>
                          <Option value="MDL" label="Moldovan leu">
                            MDL
                          </Option>
                          <Option value="MNT" label="Mongolian tögrög">
                            MNT
                          </Option>
                          <Option value="MAD" label="Moroccan dirham">
                            MAD
                          </Option>
                          <Option value="MZN" label="Mozambican metical">
                            MZN
                          </Option>
                          <Option value="NAD" label="Namibian dollar">
                            NAD
                          </Option>
                          <Option value="NPR" label="Nepalese rupee">
                            NPR
                          </Option>
                          <Option
                            value="ANG"
                            label="Netherlands Antillean guilder"
                          >
                            ANG
                          </Option>
                          <Option value="TWD" label="New Taiwan dollar">
                            TWD
                          </Option>
                          <Option value="NZD" label="New Zealand dollar">
                            NZD
                          </Option>
                          <Option value="NIO" label="Nicaraguan córdoba">
                            NIO
                          </Option>
                          <Option value="NGN" label="Nigerian naira">
                            NGN
                          </Option>
                          <Option value="KPW" label="North Korean won">
                            KPW
                          </Option>
                          <Option value="NOK" label="Norwegian krone">
                            NOK
                          </Option>
                          <Option value="OMR" label="Omani rial">
                            OMR
                          </Option>
                          <Option value="PKR" label="Pakistani rupee">
                            PKR
                          </Option>
                          <Option value="PAB" label="Panamanian balboa">
                            PAB
                          </Option>
                          <Option value="PGK" label="Papua New Guinean kina">
                            PGK
                          </Option>
                          <Option value="PYG" label="Paraguayan guaraní">
                            PYG
                          </Option>
                          <Option value="PEN" label="Peruvian sol">
                            PEN
                          </Option>
                          <Option value="PHP" label="Philippine peso">
                            PHP
                          </Option>
                          <Option value="PLN" label="Polish złoty">
                            PLN
                          </Option>
                          <Option value="QAR" label="Qatari riyal">
                            QAR
                          </Option>
                          <Option value="RON" label="Romanian leu">
                            RON
                          </Option>
                          <Option value="RUB" label="Russian ruble">
                            RUB
                          </Option>
                          <Option value="RWF" label="Rwandan franc">
                            RWF
                          </Option>
                          <Option value="SHP" label="Saint Helena pound">
                            SHP
                          </Option>
                          <Option value="WST" label="Samoan tālā">
                            WST
                          </Option>
                          <Option
                            value="STN"
                            label="São Tomé and Príncipe dobra"
                          >
                            STN
                          </Option>
                          <Option value="SAR" label="Saudi riyal">
                            SAR
                          </Option>
                          <Option value="RSD" label="Serbian dinar">
                            RSD
                          </Option>
                          <Option value="SLL" label="Sierra Leonean leone">
                            SLL
                          </Option>
                          <Option value="SGD" label="Singapore dollar">
                            SGD
                          </Option>
                          <Option value="SOS" label="Somali shilling">
                            SOS
                          </Option>
                          <Option value="SLS" label="Somaliland shilling">
                            SLS
                          </Option>
                          <Option value="ZAR" label="South African rand">
                            ZAR
                          </Option>
                          <Option value="KRW" label="South Korean won">
                            KRW
                          </Option>
                          <Option value="SSP" label="South Sudanese pound">
                            SSP
                          </Option>
                          <Option value="SRD" label="Surinamese dollar">
                            SRD
                          </Option>
                          <Option value="SEK" label="Swedish krona">
                            SEK
                          </Option>
                          <Option value="CHF" label="Swiss franc">
                            CHF
                          </Option>
                          <Option value="LKR" label="Sri Lankan rupee">
                            LKR
                          </Option>
                          <Option value="SZL" label="Swazi lilangeni">
                            SZL
                          </Option>
                          <Option value="SYP" label="Syrian pound">
                            SYP
                          </Option>
                          <Option value="TJS" label="Tajikistani somoni">
                            TJS
                          </Option>
                          <Option value="TZS" label="Tanzanian shilling">
                            TZS
                          </Option>
                          <Option value="THB" label="Thai baht">
                            THB
                          </Option>
                          <Option value="TOP" label="Tongan paʻanga">
                            TOP
                          </Option>
                          <Option value="PRB" label="Transnistrian ruble">
                            PRB
                          </Option>
                          <Option
                            value="TTD"
                            label="Trinidad and Tobago dollar"
                          >
                            TTD
                          </Option>
                          <Option value="TND" label="Tunisian dinar">
                            TND
                          </Option>
                          <Option value="TRY" label="Turkish lira">
                            TRY
                          </Option>
                          <Option value="TMT" label="Turkmenistan manat">
                            TMT
                          </Option>
                          <Option value="TVD" label="Tuvaluan dollar">
                            TVD
                          </Option>
                          <Option value="UGX" label="Ugandan shilling">
                            UGX
                          </Option>
                          <Option value="UAH" label="Ukrainian hryvnia">
                            UAH
                          </Option>
                          <Option
                            value="AED"
                            label="United Arab Emirates dirham"
                          >
                            AED
                          </Option>
                          <Option value="USD" label="United States dollar">
                            USD
                          </Option>
                          <Option value="UYU" label="Uruguayan peso">
                            UYU
                          </Option>
                          <Option value="UZS" label="Uzbekistani soʻm">
                            UZS
                          </Option>
                          <Option value="VUV" label="Vanuatu vatu">
                            VUV
                          </Option>
                          <Option
                            value="VES"
                            label="Venezuelan bolívar soberano"
                          >
                            VES
                          </Option>
                          <Option value="VND" label="Vietnamese đồng">
                            VND
                          </Option>
                          <Option value="XOF" label="West African CFA franc">
                            XOF
                          </Option>
                          <Option value="ZMW" label="Zambian kwacha">
                            ZMW
                          </Option>
                          <Option value="ZWB" label="Zimbabwean bonds">
                            ZWB
                          </Option>
                        </Select>
                      </FormItem>
                    </Col>
                    <div style={{ marginTop: '-20px' }} className="ml-2">
                      <strong>
                        {toWords?.convert(Number(basicContractPrice), {
                          currency: false
                        })}
                      </strong>
                    </div>
                  </Row>
                </Col>
                {boq.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.boq) {
                      categoryCounters.boq = 1;
                    } else {
                      categoryCounters.boq += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedBoq
                      itemNumber={categoryCounters.boq}
                      formValues={formValues}
                      setFormValues={setformValues}
                      item={item}
                      value={value}
                      handleCapacity={handleCapacity}
                      onChange={onChange}
                      form={form}
                      handleAddBoq={handleAddBoq}
                      handleDeleteBoq={handleDeleteBoq}
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      index={index}
                      boqItem={item}
                      boqs={boq}
                      setBoq={setBoq}
                      boqIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                      clauseCount={clauseCount}
                      setClauseCount={setClauseCount}
                    />
                  );
                })}
                {/*   <div className="w-100 mt-3 h-100">
                <div
                  className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                  onClick={handleAddBoq}
                >
                  <PlusCircleFilled className="mr-1" /> Add BOQ
                </div>
              </div> */}
                <Divider />
                <Col className="gutter-row" span={6}>
                  <FormItem
                    label="Price basis"
                    name="price_basis"
                    rules={[
                      {
                        required: po === 'WO' ? false : true,
                        message: 'Select price basis'
                      }
                    ]}
                  >
                    <Select
                      allowClear
                      onChange={(e) => {
                        setPriceBasis(e);
                      }}
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Select price basis"
                    >
                      {priceBasisValues?.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      ))}
                      <Option value="Others">Others</Option>;
                    </Select>
                  </FormItem>
                </Col>{' '}
                {priceBasis === 'Others' && (
                  <Col className="gutter-row " span={18}>
                    <FormItem
                      label="Price Basis"
                      name="price_basis_text"
                      rules={[
                        {
                          required: true,
                          message: 'Enter price basis'
                        }
                      ]}
                    >
                      <TextArea
                        onChange={(e) => {
                          setPriceBasisText(e.target.value);
                        }}
                        // value={formValues?.price_basis_text}
                        placeholder="Enter Price Basis"
                        autoSize
                      />
                    </FormItem>
                  </Col>
                )}
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    initialValue={
                      unitRatesMessage === null
                        ? defaultClauses?.message0?.defaultClause
                        : unitRatesMessage
                    }
                    name="message0"
                    rules={[
                      {
                        required: true,
                        message: 'Enter message'
                      }
                    ]}
                  >
                    <TextArea
                      value={formValues.message0}
                      onChange={(e) => {
                        handleInputChange('message0', e.target.value);
                        setUnitRatesMessage(e.target.value);
                      }}
                      autoSize
                      defaultValue={
                        unitRatesMessage === null
                          ? defaultClauses?.message0?.defaultClause
                          : unitRatesMessage
                      }
                    />
                  </Form.Item>
                </Col>{' '}
                <Divider />
                <Col className="font-18 sfprotext-bold mt-2 mb-4" span={24}>
                  Payment Options
                </Col>
                {payments.map((item, paymentIndex) => {
                  if (!item.deleted) {
                    if (paymentIndex === 0 || !categoryCounters.payment) {
                      categoryCounters.payment = 1;
                    } else {
                      categoryCounters.payment += 1;
                    }
                  }

                  return item.deleted ? (
                    <></>
                  ) : (
                    <Row
                      key={paymentIndex}
                      className={`${styles.grey_holder}  w-100 mt-3`}
                    >
                      <Payments
                        itemNumber={categoryCounters.payment}
                        form={form}
                        formValues={formValues}
                        setFormValues={setformValues}
                        index={paymentIndex}
                        item={item}
                        selectedOption={selectedOption}
                        onChange={handlePayments}
                        handleDeletePayment={handleDeletePayment}
                        setPayments={setPayments}
                        payments={payments}
                        paymentIndex={paymentIndex}
                        defaultClauses={defaultClauses}
                        formSubmitted={formSubmitted}
                      />
                      <>
                        {item.type === 'Advance' && (
                          <>
                            <PaymentAdvance
                              draftDetails={draftDetails}
                              form={form}
                              formValues={formValues}
                              setFormValues={setformValues}
                              styles={styles}
                              advanceMessage={advanceMessage}
                              handleDeleteAdvanceMessage={
                                handleDeleteAdvanceMessage
                              }
                              handleAddAdvanceMessage={handleAddAdvanceMessage}
                              setAdvanceMessage={setAdvanceMessage}
                              handleDeleteContact={handleDeleteContact}
                              contacts={contacts}
                              paymentItem={item}
                              payments={payments}
                              setPayments={setPayments}
                              paymentIndex={paymentIndex}
                              defaultClauses={defaultClauses}
                              formSubmitted={formSubmitted}
                            />
                          </>
                        )}
                        {item.type === 'Progress' && (
                          <>
                            {progressMessage.map((_, index) => (
                              <PaymentProgress
                                formValues={formValues}
                                setFormValues={setformValues}
                                handleDeleteProgressPercent1={
                                  handleDeleteProgressPercent1
                                }
                                setProgressPercent1={setProgressPercent1}
                                handleAddProgressPercent1={
                                  handleAddProgressPercent1
                                }
                                progressPercent1={progressPercent1}
                                suffixIcon={<CaretDownFilled />}
                                key={index}
                                index={index}
                                handleDeleteProgressMessage={
                                  handleDeleteProgressMessage
                                }
                                paymentItem={item}
                                payments={payments}
                                setPayments={setPayments}
                                paymentIndex={paymentIndex}
                                defaultClauses={defaultClauses}
                                formSubmitted={formSubmitted}
                              />
                            ))}
                          </>
                        )}
                        {item.type === 'Retention' && (
                          <>
                            {retention.map((_, index) => (
                              <PaymentRetention
                                formValues={formValues}
                                setFormValues={setformValues}
                                handleDeleteRetentionMessage={
                                  handleDeleteRetentionMessage
                                }
                                setRetentionMessage={setRetentionMessage}
                                handleAddRetentionMessage={
                                  handleAddRetentionMessage
                                }
                                retentionMessage={retentionMessage}
                                suffixIcon={<CaretDownFilled />}
                                key={index}
                                index={index}
                                handleDeleteRetention={handleDeleteRetention}
                                paymentItem={item}
                                payments={payments}
                                setPayments={setPayments}
                                paymentIndex={paymentIndex}
                                defaultClauses={defaultClauses}
                                formSubmitted={formSubmitted}
                              />
                            ))}
                          </>
                        )}
                      </>
                    </Row>
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddPayment}
                  >
                    <PlusCircleFilled className="mr-1" /> Add Payment Option
                  </div>
                </div>
                <Divider />
                {packing.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.packing) {
                      categoryCounters.packing = 1;
                    } else {
                      categoryCounters.packing += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedPacking
                      itemNumber={categoryCounters.packing}
                      formValues={formValues}
                      setFormValues={setformValues}
                      packing={packing}
                      item={item}
                      handleschedule={handleschedule}
                      value={value}
                      onChange={onChange}
                      form={form}
                      handleAddDeliveryMessage1={handleAddDeliveryMessage1}
                      handleDeletePacking={handleDeletePacking}
                      deliveryMessage1={deliveryMessage1}
                      handleDeleteDeliveryMessage1={
                        handleDeleteDeliveryMessage1
                      }
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      index={index}
                      packingItem={item}
                      packings={packing}
                      setPackings={setPacking}
                      packingIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                    />
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddPacking}
                  >
                    <PlusCircleFilled className="mr-1" /> Add Packing &
                    Forwarding
                  </div>
                </div>
                <Divider />
                {delivery.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.delivery) {
                      categoryCounters.delivery = 1;
                    } else {
                      categoryCounters.delivery += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedDelivery
                      itemNumber={categoryCounters.delivery}
                      formValues={formValues}
                      setFormValues={setformValues}
                      delivery={delivery}
                      item={item}
                      value={value}
                      onChange={onChange}
                      form={form}
                      handleAddDeliveryMessage1={handleAddDeliveryMessage1}
                      handleDeleteDelivery={handleDeleteDelivery}
                      deliveryMessage1={deliveryMessage1}
                      handleDeleteDeliveryMessage1={
                        handleDeleteDeliveryMessage1
                      }
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      index={index}
                      deliveryItem={item}
                      setDelivery={setDelivery}
                      deliveryIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                    />
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddDelivery}
                  >
                    <PlusCircleFilled className="mr-1" /> Add
                    Delivery/Completeion
                  </div>
                </div>
                <Divider />
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    initialValue={
                      inAdditionMessage !== null
                        ? inAdditionMessage
                        : defaultClauses?.message3?.defaultClause
                    }
                    /* label="Message 4" */
                    name="message3"
                    rules={[
                      {
                        required: true,
                        message: 'Enter message'
                      }
                    ]}
                  >
                    <TextArea
                      value={formValues.message3}
                      onChange={(e) => {
                        handleInputChange('message3', e.target.value);
                        setInAdditionMessage(e.target.value);
                      }}
                      defaultValue={
                        inAdditionMessage !== null
                          ? inAdditionMessage
                          : defaultClauses?.message3?.defaultClause
                      }
                      className={`w-100`}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>{' '}
                <Divider />
                {mobilisation.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.mobilisation) {
                      categoryCounters.mobilisation = 1;
                    } else {
                      categoryCounters.mobilisation += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedMobilisation
                      itemNumber={categoryCounters.mobilisation}
                      formValues={formValues}
                      setFormValues={setformValues}
                      handleMobilisation={handleMobilisation}
                      item={item}
                      mobilisation={mobilisation}
                      handleschedule={handleschedule}
                      value={value}
                      handleCapacity={handleCapacity}
                      onChange={onChange}
                      form={form}
                      handleMobilisationMessage={handleMobilisationMessage}
                      handleDeleteMobilisation={handleDeleteMobilisation}
                      mobilisationMessage={mobilisationMessage}
                      handleDeleteMobilisationMessage={
                        handleDeleteMobilisationMessage
                      }
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      index={index}
                      mobilityItem={item}
                      setMobilisation={setMobilisation}
                      mobilityIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                    />
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddMobilisation}
                  >
                    <PlusCircleFilled className="mr-1" /> Add Mobilisation Time
                  </div>
                </div>
                <Divider />
                {mrdt.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.mrdt) {
                      categoryCounters.mrdt = 1;
                    } else {
                      categoryCounters.mrdt += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedMRDT
                      itemNumber={categoryCounters.mrdt}
                      formValues={formValues}
                      setFormValues={setformValues}
                      item={item}
                      handleMRDT={handleMRDT}
                      handleschedule={handleschedule}
                      value={value}
                      handleCapacity={handleCapacity}
                      onChange={onChange}
                      form={form}
                      handleAddMRDT={handleAddMRDT}
                      handleDeleteMRDT={handleDeleteMRDT}
                      mrdtMessage={mrdtMessage}
                      handleDeleteMRDTMessage={handleDeleteMRDTMessage}
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      handleAddMRDTMessage={handleAddMRDTMessage}
                      index={index}
                      mrdtItem={item}
                      mrdt={mrdt}
                      setMrdt={setMrdt}
                      mrdtIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                    />
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddMRDT}
                  >
                    <PlusCircleFilled className="mr-1" /> Add Minimum Resource
                    Deployment Time
                  </div>
                </div>
                <Divider />
                {ld.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.ld) {
                      categoryCounters.ld = 1;
                    } else {
                      categoryCounters.ld += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedLD
                      itemNumber={categoryCounters.ld}
                      formValues={formValues}
                      setFormValues={setformValues}
                      item={item}
                      ld={ld}
                      handleschedule={handleschedule}
                      value={value}
                      handleCapacity={handleCapacity}
                      onChange={onChange}
                      form={form}
                      handleAddLD={handleAddLD}
                      handleDeleteLD={handleDeleteLD}
                      clauseMessage={clauseMessage}
                      handleDeleteClauseMessage={handleDeleteClauseMessage}
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      handleAddClauseMessage={handleAddClauseMessage}
                      index={index}
                      ldItem={item}
                      setLD={setLD}
                      ldIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                    />
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddLD}
                  >
                    <PlusCircleFilled className="mr-1" /> Add LD Clause
                  </div>
                </div>
                <Divider />
                {warranty.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.warranty) {
                      categoryCounters.warranty = 1;
                    } else {
                      categoryCounters.warranty += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedWarranty
                      itemNumber={categoryCounters.warranty}
                      formValues={formValues}
                      setFormValues={setformValues}
                      item={item}
                      warranty={warranty}
                      handleschedule={handleschedule}
                      value={value}
                      handleCapacity={handleCapacity}
                      onChange={onChange}
                      form={form}
                      handleAddWarranty={handleAddWarranty}
                      handleDeleteWarranty={handleDeleteWarranty}
                      warrantyMessage={warrantyMessage}
                      handleDeleteWarrantyMessage={handleDeleteWarrantyMessage}
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      handleAddWarrantyMessage={handleAddWarrantyMessage}
                      index={index}
                      warrantyItem={item}
                      setWarranty={setWarranty}
                      warrantyIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                    />
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddWarranty}
                  >
                    <PlusCircleFilled className="mr-1" /> Add Warranty Clause
                  </div>
                </div>
                <Divider />
                {dlp.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.dlp) {
                      categoryCounters.dlp = 1;
                    } else {
                      categoryCounters.dlp += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedDLP
                      itemNumber={categoryCounters.dlp}
                      formValues={formValues}
                      setFormValues={setformValues}
                      item={item}
                      dlp={dlp}
                      handleschedule={handleschedule}
                      value={value}
                      handleCapacity={handleCapacity}
                      onChange={onChange}
                      form={form}
                      handleAddDLP={handleAddDLP}
                      handleDeleteDLP={handleDeleteDLP}
                      defectLiabilityMessage1={defectLiabilityMessage1}
                      handleDeleteDefectLiabilityMessage1={
                        handleDeleteDefectLiabilityMessage1
                      }
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      handleAddDefectLiabilityMessage1={
                        handleAddDefectLiabilityMessage1
                      }
                      index={index}
                      dlpItem={item}
                      dlps={dlp}
                      setDlp={setDlp}
                      dlpIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                    />
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddDLP}
                  >
                    <PlusCircleFilled className="mr-1" /> Add DLP Clause
                  </div>
                </div>
                <Divider />
                {contractPerformance.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.contractPerformance) {
                      categoryCounters.contractPerformance = 1;
                    } else {
                      categoryCounters.contractPerformance += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedContractPerformance
                      itemNumber={categoryCounters.contractPerformance}
                      formValues={formValues}
                      setFormValues={setformValues}
                      dummyData={dummyData}
                      item={item}
                      contractPerformance={contractPerformance}
                      handleschedule={handleschedule}
                      value={value}
                      handleCapacity={handleCapacity}
                      onChange={onChange}
                      form={form}
                      handleAddContractPerformance={
                        handleAddContractPerformance
                      }
                      handleDeleteContractPerformance={
                        handleDeleteContractPerformance
                      }
                      contractMessage2={contractMessage2}
                      handleDeleteContractMessage2={
                        handleDeleteContractMessage2
                      }
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      handleAddContractMessage2={handleAddContractMessage2}
                      index={index}
                      contractItem={item}
                      setContractPerformance={setContractPerformance}
                      contractIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                    />
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddContractPerformance}
                  >
                    <PlusCircleFilled className="mr-1" /> Add Contract
                    Performance
                  </div>
                </div>
                <Divider />
                {freight.map((item, index) => {
                  if (!item.deleted) {
                    if (index === 0 || !categoryCounters.freight) {
                      categoryCounters.freight = 1;
                    } else {
                      categoryCounters.freight += 1;
                    }
                  }
                  return item.deleted ? (
                    <></>
                  ) : (
                    <UpdatedFreightInsurance
                      itemNumber={categoryCounters.freight}
                      formValues={formValues}
                      setFormValues={setformValues}
                      freight={freight}
                      item={item}
                      handleschedule={handleschedule}
                      value={value}
                      handleCapacity={handleCapacity}
                      onChange={onChange}
                      form={form}
                      handleAddFreight={handleAddFreight}
                      handleDeleteFreight={handleDeleteFreight}
                      freightMessage={freightMessage}
                      handleDeleteFreightMessage={handleDeleteFreightMessage}
                      suffixIcon={<CaretDownFilled />}
                      key={index}
                      handleAddFreightMessage={handleAddFreightMessage}
                      index={index}
                      freightItem={item}
                      freights={freight}
                      setFreight={setFreight}
                      freightIndex={index}
                      defaultClauses={defaultClauses}
                      formSubmitted={formSubmitted}
                    />
                  );
                })}
                <div className="w-100 mt-3 h-100">
                  <div
                    className={` ${styles.add_div} df-jc-ac cursor-pointer`}
                    onClick={handleAddFreight}
                  >
                    <PlusCircleFilled className="mr-1" /> Add Freight &
                    Insurance
                  </div>
                </div>
                <Divider />
                <Col className="gutter-row " span={6}>
                  <FormItem
                    label="Validity of contract (in months)"
                    name="validitycontract"
                    rules={[
                      {
                        required: false,
                        message: `Enter duration`
                      }
                    ]}
                  >
                    <InputNumber
                      onChange={(e) => setContractValidity(e)}
                      controls={false}
                      className={`w-100`}
                      placeholder="Enter duration "
                    />
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={24}>
                  <FormItem
                    label="Special Terms and Conditions"
                    name="spl_terms"
                    rules={[
                      {
                        required: true,
                        message: `Enter message`
                      }
                    ]}
                  >
                    <ReactQuill
                      placeholder="Enter special terms and conditions"
                      theme="snow"
                      onChange={(e) => {
                        setSpecialTerms(e);
                      }}
                    />
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={24}>
                  <FormItem
                    label="Quantity variation"
                    name="quantity_variation"
                    rules={[
                      {
                        required: true,
                        message: `Enter message`
                      }
                    ]}
                  >
                    <TextArea
                      onChange={(e) => setQuantityVariation(e.target.value)}
                      placeholder="Enter quantity variation"
                    />
                  </FormItem>
                </Col>
                <Col className="gutter-row" span={24}>
                  <FormItem
                    initialValue={
                      taxDutiesMessage === 'undefined'
                        ? defaultClauses?.taxAndDuties?.defaultClause
                        : taxDutiesMessage
                    }
                    label="Tax and Duties"
                    name="tax_duties"
                    rules={[
                      {
                        required: true,
                        message: 'Enter message'
                      }
                    ]}
                  >
                    <ReactQuill
                      defaultValue={draftDetails?.taxAndDuties || ''}
                      allowClear
                      rows={8}
                      className={`w-100`}
                      placeholder="Enter tax and duties"
                      onChange={(e) => {
                        handleInputChange('tax_duties', e);
                        setTaxDutiesMessage(e);
                      }}
                    />
                  </FormItem>
                </Col>{' '}
                <Divider />
                <Col className="gutter-row" span={12}>
                  <FormItem
                    label="All other general terms and conditions shall be as per General Conditions of Contract, General Conditions of Contract for Health, Safety. Also, the below T&C as per standard documents of Tata Projects shall be applicable. All of these need to be read in conjunction with this LOA/LOI"
                    name="all_tpl"
                    rules={[
                      {
                        required: true,
                        message: 'Select type'
                      }
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      value={selectedValues}
                      onChange={(e) => {
                        setAllTpl(e);
                        setSelectedValues(e);
                      }}
                      showSearch
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Select type"
                    >
                      <Option value="GPC">GPC</Option>
                      <Option value="GCC">GCC</Option>
                      <Option value="SCC">SCC</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Divider />
                <Col span={24}>
                  <FormItem
                    label="Price Justification, selection of vendors and list of documents for TPL reference (this will not be printed in LOI/LOA)"
                    name="price_justification"
                    rules={[
                      {
                        required: true,
                        message: 'Enter message'
                      }
                    ]}
                  >
                    <TextArea
                      onChange={(e) => {
                        setPriceJustification(e.target.value);
                      }}
                      placeholder="Enter messsage"
                    />
                  </FormItem>
                </Col>
                <Divider />
                <Col span={24}>
                  <FormItem
                    label="List of documents for reference to vendors"
                    name="doc_list"
                    rules={[
                      {
                        required: true,
                        message: 'Enter list of documents'
                      }
                    ]}
                  >
                    <ReactQuill
                      placeholder="Enter list of documents"
                      theme="snow"
                      onChange={(e) => setAttachmentList(e)}
                    />
                  </FormItem>
                </Col>
                <Divider />
                {tempDocList.length > 0 && !myParam ? (
                  <>
                    <Col span={24}>
                      <h1 className="sfprotext-bold font-16">Uploaded Docs</h1>
                      <ol>
                        {tempDocList.map((item, index) => (
                          <li key={index}>
                            <span>
                              <Tag color="geekblue">
                                {' '}
                                <a
                                  className="cursor-pointer"
                                  href={item.fileUrl}
                                  download
                                >
                                  {item.fileName}
                                </a>
                              </Tag>

                              <DeleteOutlined
                                onClick={() => handleDeleteImages(item.id)}
                              />
                            </span>
                          </li>
                        ))}
                      </ol>
                    </Col>
                    <Divider />
                  </>
                ) : null}
                {newDocs.map((item, index) => (
                  <>
                    {supportingDoc[index] !== '' &&
                    supportingDoc[index] !== undefined ? (
                      <>
                        <Col key={index} className={`mb-2`} span={20}>
                          <span>
                            <Tag color="geekblue">
                              {supportingDoc[index].name}
                            </Tag>
                            <DeleteOutlined
                              onClick={(e) => handleclearDoc(e, index)}
                            />
                          </span>
                        </Col>
                        <Col span={4}>
                          {index === 0 ? (
                            <CustomButton
                              className={`mb-4`}
                              onClick={handleOnClick}
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            ''
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={20}>
                          <FormItem
                            name="docs"
                            rules={[
                              {
                                required:
                                  tempDocList?.length === 0 || myParam
                                    ? true
                                    : false,
                                message: 'Upload document'
                              }
                            ]}
                          >
                            <label
                              htmlFor="file-upload"
                              className={styles.custom_file_upload}
                            >
                              <UploadOutlined className="mr-2" /> Upload
                            </label>
                            <Input
                              accept="*"
                              className={styles.upload}
                              type="file"
                              onChange={(e) => handlechangeDoc(e, index)}
                              id="file-upload"
                            />
                          </FormItem>
                        </Col>
                        <Col span={4}>
                          {index === 0 ? (
                            <CustomButton
                              className={`mb-2`}
                              onClick={handleOnClick}
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            <CustomButton
                              className={`${styles.remove_button}`}
                              onClick={(e) => handleRemove(e, index)}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </Col>
                      </>
                    )}
                  </>
                ))}
                <Divider />
                <Col span={24}>
                  <FormItem
                    rules={[
                      {
                        required: value ? false : true,
                        message: `Please check before submitting`
                      }
                    ]}
                    name="check1"
                  >
                    <Checkbox checked={value} onChange={onChange} />
                    <span className=" ml-1 sfprotext-bold font-14">
                      I hereby declare there are no contradictions in
                      attachments and content of the letter
                    </span>
                  </FormItem>

                  <div className="mt-2">
                    {check ? (
                      <>
                        <FormItem
                          name="cancellation_notice"
                          rules={[
                            {
                              required: check ? true : false,
                              message: 'Enter message'
                            }
                          ]}
                        >
                          <span className=" ">
                            <Checkbox
                              className="mr-2"
                              checked={check}
                              onChange={() => setCheck(toggle)}
                            />
                            <Input
                              style={{ width: '90%' }}
                              defaultValue={
                                draftDetails?.isTheLoiAutoCancellationAfter30DaysAccepted ===
                                null
                                  ? formValues?.cancellation_notice ===
                                    undefined
                                    ? defaultClauses?.checkbox?.defaultClause
                                    : formValues?.cancellation_notice
                                  : draftDetails?.isTheLoiAutoCancellationAfter30DaysAccepted
                              }
                              onChange={(e) => {
                                handleInputChange(
                                  'cancellation_notice',
                                  e.target.value
                                ),
                                  setCancellationMessage(e.target.value);
                              }}
                            />
                          </span>
                        </FormItem>
                      </>
                    ) : (
                      <>
                        <Checkbox
                          checked={check}
                          onChange={() => setCheck(toggle)}
                        />
                        <span className=" mt-2 ml-1 sfprotext-bold font-14">
                          {defaultClauses?.checkbox?.defaultClause || ''}
                        </span>
                      </>
                    )}
                  </div>
                </Col>
                <Divider />
              </Row>
              <Col className="font-18 sfprotext-bold " span={24}>
                Anti Bribery Covenant
              </Col>
              <div>
                <Document
                  file={terms}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className={`${styles.anti_bribery_viewer}`}
                >
                  {Array.from(new Array(numPages), (_, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      wrap={true}
                      pageNumber={index + 1}
                      className={`${styles.anti_bribery}`}
                      size="A1"
                    />
                  ))}
                </Document>
              </div>
            </Card>
            <br />

            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <Badge className={`${styles.badge}`} count={2} dot={false} />
                }
                extra="Add approvers"
                key="3"
              >
                {flowValues?.isApproved === false &&
                flowValues?.isCbNull === false &&
                flowValues?.isLimitExceeded === false ? (
                  <>
                    {itemList1.map((item, index) => (
                      <Row
                        key={index}
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      >
                        <Col className="gutter-row" span={1}>
                          <div
                            className={`${
                              index > 1
                                ? `${styles.step} ${styles.v_stepper}${styles.circle} `
                                : ''
                            } mt-2`}
                          >
                            <div className={`${styles.circle}`}>
                              <p>{index + 1}</p>
                            </div>
                            <div
                              className={
                                itemList1.length !== index + 1
                                  ? `${styles.line}`
                                  : ''
                              }
                            ></div>
                          </div>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <div>
                            <FormItem
                              {...item}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select an approver'
                                }
                              ]}
                              name={`approvers_${index}`}
                              className="aasdsad"
                            >
                              <Select
                                onSearch={onSBGSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onChange={(e) => handleSBGS(e)}
                                suffixIcon={<CaretDownFilled />}
                                disabled={false}
                                style={{ width: '100%', marginBottom: '10px' }}
                                showSearch
                                placeholder="Select Approver"
                              >
                                {filteredSBGName.map((item, index) => (
                                  <Option key={index} value={item.id}>
                                    {item.fullname}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          </div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <div className="mt-2">
                            <b>Needs to sign</b>
                          </div>
                        </Col>
                        {item.value === '' ? (
                          <Col
                            className="gutter-row"
                            span={2}
                            /* onClick={() => removeInput(index)} */
                          >
                            <div>
                              <div
                                className={`cursor-pointer ${styles.delete_icon}`}
                              />
                            </div>
                          </Col>
                        ) : (
                          ''
                        )}
                      </Row>
                    ))}
                  </>
                ) : (
                  <>
                    {itemList.map((item, index) => (
                      <Row
                        key={index}
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      >
                        <Col className="gutter-row" span={1}>
                          <div
                            className={`${
                              index > 1
                                ? `${styles.step} ${styles.v_stepper}${styles.circle} `
                                : ''
                            } mt-2`}
                          >
                            <div className={`${styles.circle}`}>
                              <p>{index + 1}</p>
                            </div>
                            <div
                              className={
                                itemList.length !== index + 1
                                  ? `${styles.line}`
                                  : ''
                              }
                            ></div>
                          </div>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <div>
                            <FormItem
                              {...item}
                              rules={[
                                {
                                  required: index === 1 ? false : true,
                                  message: 'Please select an approver'
                                }
                              ]}
                              name={`approvers_${index}`}
                              className="aasdsad"
                            >
                              <Select
                                onSearch={onApproverSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onChange={(e) => handleApprovers(e)}
                                suffixIcon={<CaretDownFilled />}
                                defaultValue={
                                  index === 1
                                    ? filteredCFOName?.map(
                                        (item) => `${item?.fullname}`
                                      )
                                    : ''
                                }
                                disabled={index === 1 ? true : false}
                                style={{ width: '100%', marginBottom: '10px' }}
                                showSearch
                                placeholder="Select Approver"
                              >
                                {filteredApproverName.map((item, index) => (
                                  <Option key={index} value={item.id}>
                                    {item.fullname}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          </div>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <div className="mt-2">
                            <b>Needs to sign</b>
                          </div>
                        </Col>
                        {item.value === '' ? (
                          <Col
                            className="gutter-row"
                            span={2}
                            /* onClick={() => removeInput(index)} */
                          >
                            <div>
                              <div
                                className={`cursor-pointer ${styles.delete_icon}`}
                              />
                            </div>
                          </Col>
                        ) : (
                          ''
                        )}
                      </Row>
                    ))}
                  </>
                )}

                <br />
              </Panel>
            </CustomCollapse>
            <br />
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <Badge className={`${styles.badge}`} count={3} dot={false} />
                }
                extra="Add email message"
                key="3"
              >
                <FormItem name="email_message" label="Email message">
                  <TextArea
                    placeholder="Enter your message..."
                    onChange={(e) => {
                      setEmailMessage(e.target.value);
                      handleInputChange('email_message', e.target.value);
                    }}
                  />
                </FormItem>
              </Panel>
            </CustomCollapse>
            <br />
          </div>
          <Modal visible={initiateDraft} footer={false} closable={false}>
            <p style={{ textAlign: 'center' }}>Saving the document as draft</p>
            <p style={{ textAlign: 'center' }}>
              <Spin />
            </p>
          </Modal>
        </Form>
      )}
    </>
  );
};

export default DraftEdit1;
