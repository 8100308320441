import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { updateControlBudget } from '@/features/settings/redux/slice';
//import { createProjects } from '@/features/settings/redux/slice';
// import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Row, Select, Skeleton, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CaretDownFilled } from '@ant-design/icons';

const CBModal = ({
  byIdLoading,
  // handleSbuSearchChange,
  // all_SBUs,
  setId,
  id,
  projectById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllDesignations
}) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(
    projectById?.isCbNull === null ? false : projectById?.isCbNull
  );
  const [form] = Form.useForm();
  useEffect(() => {
    console.log('HH', projectById);
    setId(projectById?.id);
    setIsActive(projectById?.isCbNull === null ? false : projectById?.isCbNull);

    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      isCbNull: projectById?.isCbNull === null ? false : projectById?.isCbNull,
      isControlBudget: projectById?.isControlBudget
    });
  }, [projectById, form]);

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('isCbNull', isActive);
    formData.append('isControlBudget', values.isControlBudget);
    console.log(values);
    const formDataObject = {
      url: id, // Change this to the appropriate URL
      payload: {
        isCbNull: isActive,
        isControlBudget: values.isControlBudget
      }
    };

    dispatch(updateControlBudget(formDataObject)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllDesignations();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const onChange = (e) => {
    setIsActive(e);
    form.setFieldsValue({ isControlBudget: undefined });
  };

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit Control Budget"
    >
      {byIdLoading ? (
        <Skeleton />
      ) : (
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col span={24}>
              <FormItem
                label={'Is control budget not approved for this project?'}
                name="isCbNull"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select'
                  }
                ]}
              >
                <Select
                  onChange={onChange}
                  placeholder="Select"
                  suffixIcon={<CaretDownFilled />}
                >
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </FormItem>
            </Col>
            {!isActive && (
              <Col span={24}>
                <FormItem
                  label={'Is control budget applicable for this project?'}
                  name="isControlBudget"
                  // className={`${styles.astrick}`}
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Select placeholder="Select" suffixIcon={<CaretDownFilled />}>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </FormItem>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </CustomModal>
  );
};

export default CBModal;
