import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { allLetters } from './api';

const initialState = {
  all_trackers: [],
  all_counts_letters: {},
  all_count: {},
  completed_count: {},
  rejected_count: {},
  waiting_for_others_count: {},
  pending_count: {},
  cancelled_count: {},
  forwarded_count: {},
  returned: {},
  forReview: {},
  draft_count: {},
  pagination: {}
};

const actions = {
  GETTRACKERSLIST: 'getTrackersList/GETTRACKERSLIST'
};

export const getTrackersList = createAsyncThunk(
  actions.GETTRACKERSLIST,
  async (payload) => {
    const response = await allLetters('all', payload);
    return response;
  }
);

export const getTrackersSlice = createSlice({
  name: 'trackersList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrackersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrackersList.fulfilled, (state, action) => {
        const {
          data,
          pagination,
          completedCount,
          waitingForOthersCount,
          rejectedCount,
          totalRecords,
          allCount,
          pendingCount,
          forwardedCount,
          returned,
          forReview,
          draftsCount,
          cancelledCount
        } = action.payload;
        //console.log(data);
        state.all_trackers = data;
        state.all_counts_letters = allCount;
        state.pending_count = pendingCount;
        state.forwarded_count = forwardedCount;
        state.all_count = totalRecords;
        state.draft_count = draftsCount;
        state.completed_count = completedCount;
        state.forReview = forReview;
        state.returned = returned;
        state.cancelled_count = cancelledCount;
        state.rejected_count = rejectedCount;
        state.waiting_for_others_count = waitingForOthersCount;
        state.pagination = pagination || null;
      })
      .addCase(getTrackersList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = getTrackersSlice.actions; */
/* export const { resetTrackers } = getTrackersSlice.actions; */

export default getTrackersSlice.reducer;
