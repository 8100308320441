import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  review_Actions,
  get_otp,
  approveLetter,
  rejectLetter,
  forwardLetter,
  reviewLetter,
  getProjectValue,
  cancelLoi
} from './api';

const initialState = {
  all_file_data: {},
  otp: '',
  all_project_amount: {}
};

const actions = {
  GET_FILE_BY_ID: 'getfilebyid/GET_FILE_BY_ID',
  GET_PROJECT_AMOUNT_BY_ID: 'getProjectAmountById/GET_PROJECT_AMOUNT_BY_ID',
  GET_OTP: 'getOtp/GET_OTP',
  APP_LETTER: 'appLetter/APP_LETTER',
  REJ_LETTER: 'rejLetter/REJ_LETTER',
  REV_LETTER: 'revLetter/REV_LETTER',
  FOR_LETTER: 'forLetter/FOR_LETTER',
  CANCELLOI: 'cancelLOI/CANCELLOI'
};

export const getfilebyid = createAsyncThunk(
  actions.GET_FILE_BY_ID,
  async (payload) => {
    const response = await review_Actions(`all?id=${payload}`);
    return response;
  }
);

export const getProjectAmountById = createAsyncThunk(
  actions.GET_PROJECT_AMOUNT_BY_ID,
  async (payload) => {
    const response = await getProjectValue(payload);
    return response;
  }
);

export const getOtp = createAsyncThunk(actions.GET_OTP, async (paylaod) => {
  const response = await get_otp(paylaod);
  return response;
});

export const appLetter = createAsyncThunk(
  actions.APP_LETTER,
  async (payload) => {
    const response = await approveLetter(
      `LoiId=${payload.id}&email=${payload.email}`,
      payload
    );
    return response;
  }
);

export const rejLetter = createAsyncThunk(
  actions.REJ_LETTER,
  async (payload) => {
    const response = await rejectLetter(
      `LoiId=${payload.id}&email=${payload.email}`,
      payload
    );
    return response;
  }
);
export const revLetter = createAsyncThunk(
  actions.REV_LETTER,
  async (payload) => {
    const response = await reviewLetter(
      `LoiId=${payload.id}&email=${payload.email}`,
      payload
    );
    return response;
  }
);

export const forLetter = createAsyncThunk(
  actions.FOR_LETTER,
  async (payload) => {
    //console.log(payload);
    const response = await forwardLetter(
      `LoiId=${payload.id}&email=${payload.email}`,
      payload
    );
    return response;
  }
);

export const cancelLOI = createAsyncThunk(
  actions.CANCELLOI,
  async (payload) => {
    const response = await cancelLoi(
      `${payload.id}?remarks=${payload.remarks}`
    );
    return response;
  }
);

export const getReviewsSlice = createSlice({
  name: 'reviewActions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getfilebyid.pending, (state) => {
        state.loading = true;
      })
      .addCase(getfilebyid.fulfilled, (state, action) => {
        const { data } = action.payload;
        //console.log(data);
        state.all_file_data = data;
      })
      .addCase(getfilebyid.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getProjectAmountById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectAmountById.fulfilled, (state, action) => {
        const { data } = action.payload;
        //console.log(data);
        state.all_project_amount = data;
      })
      .addCase(getProjectAmountById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOtp.fulfilled, (state, action) => {
        const { success, data, message: msg } = action.payload;
        state.otp = data;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(getOtp.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(appLetter.pending, (state) => {
        state.approveLetterLoading = true;
      })
      .addCase(appLetter.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.approveLetterLoading = false;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(appLetter.rejected, (state) => {
        state.approveLetterLoading = false;
      });

    builder
      .addCase(rejLetter.pending, (state) => {
        state.handoverToVendorLoading = true;
      })
      .addCase(rejLetter.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.handoverToVendorLoading = false;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(rejLetter.rejected, (state) => {
        state.handoverToVendorLoading = false;
      });

    builder
      .addCase(revLetter.pending, (state) => {
        state.handoverToVendorLoading = true;
      })
      .addCase(revLetter.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.handoverToVendorLoading = false;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(revLetter.rejected, (state) => {
        state.handoverToVendorLoading = false;
      });

    builder
      .addCase(forLetter.pending, (state) => {
        state.handoverToRoLoading = true;
      })
      .addCase(forLetter.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.handoverToRoLoading = false;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(forLetter.rejected, (state) => {
        state.handoverToRoLoading = false;
      });

    builder
      .addCase(cancelLOI.pending, (state) => {
        state.handoverToRoLoading = true;
      })
      .addCase(cancelLOI.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.handoverToRoLoading = false;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(cancelLOI.rejected, (state) => {
        state.handoverToRoLoading = false;
      });
  }
});

/* export const getTrackersActions = getReviewsSlice.actions; */
/* export const { resetTrackers } = getReviewsSlice.actions; */

export default getReviewsSlice.reducer;
