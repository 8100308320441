import FormItem from '@/common/FormItem';
import {
  CaretDownFilled,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Form, InputNumber, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import styles from '../../InitiateLOI1/index.module.less';

const { Option } = Select;
const PaymentProgress = ({
  index,
  formValues,
  setFormValues,
  paymentItem,
  payments,
  setPayments,
  paymentIndex,
  defaultClauses
}) => {
  const [paymentValues, setPaymentValues] = useState({
    periodValue: null,
    percentage: null,
    period: ''
  });

  const handleChange = (type, value, inputName) => {
    setPaymentValues((prevValues) => ({
      ...prevValues,
      [type]: value
    }));

    const tempPayments = [...payments];
    tempPayments[paymentIndex][type] = value;

    const clauseIndex = 0;
    if (type === 'percentage' || type === 'periodValue' || type === 'period') {
      const selectedPercentage =
        type === 'percentage'
          ? value
          : paymentValues?.percentage
          ? paymentValues?.percentage
          : formValues?.dynamicFields[`progress_${paymentIndex}_percentage`] ||
            '';
      const selectedPeriodValue =
        type === 'periodValue'
          ? value
          : paymentValues?.periodValue
          ? paymentValues?.periodValue
          : formValues?.dynamicFields[`progress_${paymentIndex}_total`] || '';
      const selectedPeriod =
        type === 'period'
          ? value
          : paymentValues?.period
          ? paymentValues?.period
          : formValues?.dynamicFields[
              `progress_${paymentIndex}_claim_duration`
            ] || '';
      const dynamicFieldKey = `progress_${paymentIndex}clause0`;
      const dynamicFieldValue = `${selectedPercentage}% payment shall be released on ${selectedPeriodValue} ${selectedPeriod} from the date of receipt of Material / Completion of work duly certified by Site In-Charge.`;

      // Update the first clause (when clauseIndex is 0) with dynamicFieldValue
      if (clauseIndex === 0) {
        tempPayments[paymentIndex].clauses[clauseIndex] = dynamicFieldValue;
      }

      setFormValues({
        ...formValues,
        dynamicFields: {
          ...(formValues.dynamicFields || {}),
          [dynamicFieldKey]: dynamicFieldValue,
          [inputName]: value
        }
      });

      setPayments(tempPayments);
      console.log('TEMMMOOOO,', tempPayments);
    }
  };

  const handleClauseChange = (paymentIndex, clauseIndex, val, inputName) => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex].clauses[clauseIndex] = val;
    setPayments(tempPayments);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex].clauses.push(
      defaultClauses?.paymentProgress?.defaultClause || ''
    );
    setPayments(tempPayments);
  };

  const handleDeleteClause = (clauseIndex) => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex].clauses.splice(clauseIndex, 1);
    setPayments(tempPayments);
  };

  const clauses = paymentItem?.clauses || [];

  return (
    <>
      <Col className="font-18 sfprotext-bold ml-3 mb-2 mt-4" span={22}>
        {` Progress ${index > 0 ? index + 1 : ''}`}
      </Col>
      <Row className={`${styles.grey_holder}  w-100`}>
        <Col className="gutter-row ml-3" span={12}>
          <FormItem
            label="Enter percentage"
            rules={[{ required: true }]}
            name={`progress_${paymentIndex}_percentage`}
          >
            <InputNumber
              onChange={(val) =>
                handleChange(
                  'percentage',
                  val,
                  `progress_${paymentIndex}_percentage`
                )
              }
              value={paymentItem?.percentage}
              max={100}
              placeholder="Enter percent"
              style={{ width: '100%' }}
            />
          </FormItem>
        </Col>

        <Col className="gutter-row ml-3" span={24}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <FormItem
                label="Percentage of Total Contract Price after (from material receipt)"
                rules={[{ required: true }]}
                name={`progress_${paymentIndex}_total`}
              >
                <InputNumber
                  onChange={(val) =>
                    handleChange(
                      'periodValue',
                      val,
                      `progress_${paymentIndex}_total`
                    )
                  }
                  value={paymentItem?.periodValue}
                  className="w-100"
                  controls={false}
                  min={0}
                  placeholder="Enter value"
                />
              </FormItem>
            </Col>

            <Col span={6}>
              <FormItem
                name={`progress_${paymentIndex}_claim_duration`}
                rules={[{ required: true, message: 'Please select duration' }]}
              >
                <Select
                  onChange={(val) =>
                    handleChange(
                      'period',
                      val,
                      `progress_${paymentIndex}_claim_duration`
                    )
                  }
                  value={paymentItem?.period}
                  showSearch
                  style={{ marginTop: '26px' }}
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select duration"
                >
                  <Option value="Days">Days</Option>
                  <Option value="Months">Months</Option>
                  <Option value="Years">Years</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Form.List
          name={`payment_${paymentIndex}_clauses`}
          initialValue={paymentItem.clauses}
          // value={paymentItem.clauses}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, clauseIndex) => (
                <Col className="gutter-row  ml-3" span={18} key={clauseIndex}>
                  <Form.Item required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      noStyle
                      rules={[{ required: true, message: 'Please enter' }]}
                    >
                      <Row>
                        <Col span={22}>
                          <TextArea
                            defaultValue={
                              clauseIndex === 0
                                ? formValues.dynamicFields[
                                    `progress_${paymentIndex}clause0`
                                  ] ||
                                  defaultClauses?.paymentProgress?.defaultClause
                                : clauses[clauseIndex] ||
                                  defaultClauses?.paymentProgress?.defaultClause
                            }
                            onChange={(e) =>
                              handleClauseChange(
                                paymentIndex,
                                clauseIndex,
                                e.target.value,
                                `progress_${paymentIndex}clause${clauseIndex}`,
                                fields
                              )
                            }
                            value={
                              clauseIndex === 0
                                ? formValues.dynamicFields[
                                    `progress_${paymentIndex}clause0`
                                  ] ||
                                  defaultClauses?.paymentProgress?.defaultClause
                                : clauses[clauseIndex] ||
                                  defaultClauses?.paymentProgress?.defaultClause
                            }
                            autoSize
                            disabled={clauseIndex === 0}
                          />
                        </Col>
                        <Col span={2}>
                          {fields.length > 1 && clauseIndex !== 0 ? (
                            <span
                              onClick={() => {
                                remove(field.name);
                                handleDeleteClause(clauseIndex);
                              }}
                              className={` pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                            >
                              <MinusCircleFilled style={{ color: '#D72D30' }} />
                            </span>
                          ) : null}
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form.Item>
                </Col>
              ))}
              <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                <Form.Item>
                  <Col className={`${styles.add_clause}  mb-3`} span={24}>
                    <span
                      onClick={() => {
                        add(defaultClauses?.paymentProgress?.defaultClause);
                        handleAddClause();
                      }}
                      className="cursor-pointer"
                    >
                      <PlusCircleFilled className="mr-1" /> Add clause
                    </span>
                  </Col>
                </Form.Item>
              </Col>
            </>
          )}
        </Form.List>
      </Row>
    </>
  );
};

export default PaymentProgress;
