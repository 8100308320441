import React from 'react';

import CustomLayout from '@/common/Layout';
import ApprovedPage from './components';

const Cancelled = () => {
  return (
    <>
      <CustomLayout sider={true} tracker={true}>
        <ApprovedPage />
      </CustomLayout>
    </>
  );
};

export default Cancelled;
