import FormItem from '@/common/FormItem';
import {
  CaretDownFilled,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Form, InputNumber, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import styles from '../../InitiateLOI1/index.module.less';

const { Option } = Select;

const PaymentAdvance = ({
  // form,
  setFormValues,
  formValues,
  paymentItem,
  payments,
  setPayments,
  paymentIndex,
  defaultClauses
  // formSubmitted
}) => {
  const handleChange = (type, value, inputName) => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex][type] = value;
    setPayments(tempPayments);
    setFormValues({
      ...formValues,
      dynamicFields: { ...(formValues.dynamicFields || {}), [inputName]: value }
    });
  };

  const handleClauseChange = (paymentIndex, clauseIndex, val, inputName) => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex].clauses[clauseIndex] = val;
    setPayments(tempPayments);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex].clauses.push(
      defaultClauses?.paymentAdvance?.defaultClause || ''
    );
    setPayments(tempPayments);
  };

  const handleDeleteClause = (clauseIndex) => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex].clauses.splice(clauseIndex, 1);
    setPayments(tempPayments);
  };

  const clauses = paymentItem?.clauses || [];

  return (
    <>
      <Col className="ml-3 font-18 sfprotext-bold mb-2" span={22}>
        Advance
      </Col>

      <Row className={`${styles.grey_holder}  w-100`}>
        <Col className="gutter-row ml-3" span={12}>
          <FormItem
            rules={[{ required: true, message: 'Please enter percentage' }]}
            name={`advanced_${paymentIndex}_percentage`}
            label={`Percentage of Total Contract Price as advance`}
          >
            <InputNumber
              onChange={(e) =>
                handleChange(
                  'percentage',
                  e,
                  `advanced_${paymentIndex}_percentage`
                )
              }
              value={paymentItem?.percentage}
              className="w-100"
              placeholder="Enter pecentage"
            />
          </FormItem>
        </Col>
        <Col className="gutter-row ml-3" span={24}>
          <Row gutter={[8, 8]}>
            <Col className="" span={6}>
              <FormItem
                rules={[{ required: true }]}
                name={`advanced_${paymentIndex}_claimperiod`}
                label="Claim Period"
                required
                value={paymentItem?.periodValue}
                onChange={(e) =>
                  handleChange(
                    'periodValue',
                    e.target.value,
                    `advanced_${paymentIndex}_claimperiod`
                  )
                }
              >
                <InputNumber
                  value={paymentItem?.periodValue}
                  className="w-100"
                  controls={false}
                  min={0}
                  placeholder="Enter value"
                />
              </FormItem>
            </Col>

            <Col className="mt-1" span={6}>
              <FormItem
                name={`advanced_${paymentIndex}_claim_duration`}
                rules={[{ required: true, message: 'Please select duration' }]}
              >
                <Select
                  onChange={(val) =>
                    handleChange(
                      'period',
                      val,
                      `advanced_${paymentIndex}_claim_duration`
                    )
                  }
                  showSearch
                  value={paymentItem.period}
                  style={{ marginTop: '22px' }}
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select duration"
                >
                  <Option value="Days">Days</Option>
                  <Option value="Months">Months</Option>
                  <Option value="Years">Years</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Col>

        <Form.List
          name={`payment_${paymentIndex}_clauses`}
          initialValue={paymentItem.clauses}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, clauseIndex) => (
                <Col className="gutter-row  ml-3" span={18} key={clauseIndex}>
                  <Form.Item required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      noStyle
                      rules={[{ required: true, message: 'Please enter' }]}
                    >
                      <Row>
                        <Col span={22}>
                          <TextArea
                            defaultValue={
                              clauses[clauseIndex] ||
                              defaultClauses?.paymentAdvance?.defaultClause
                            }
                            onChange={(e) =>
                              handleClauseChange(
                                paymentIndex,
                                clauseIndex,
                                e.target.value,
                                `advance_${paymentIndex}clause${clauseIndex}`,
                                fields
                              )
                            }
                            autoSize
                          />
                        </Col>
                        <Col span={2}>
                          {fields.length > 1 ? (
                            <span
                              onClick={() => {
                                remove(field.name);
                                handleDeleteClause(clauseIndex);
                              }}
                              className={`  pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                            >
                              <MinusCircleFilled style={{ color: '#D72D30' }} />
                            </span>
                          ) : null}
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form.Item>
                </Col>
              ))}
              <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                <Form.Item>
                  <Col className={`${styles.add_clause}  mb-3`} span={24}>
                    <span
                      onClick={() => {
                        add(defaultClauses?.paymentAdvance?.defaultClause);
                        handleAddClause();
                      }}
                      className="cursor-pointer"
                    >
                      <PlusCircleFilled className="mr-1" /> Add clause
                    </span>
                  </Col>
                </Form.Item>
              </Col>
            </>
          )}
        </Form.List>
      </Row>
    </>
  );
};

export default PaymentAdvance;
