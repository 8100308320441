import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import Login from './features/login';

import Loader from './common/Loader';
import DashBoard from './features/dashboard';
import InitiateLOI from './features/initiate-loi';

import PageNotFound from './common/PageNotFound';
import ApproverDashboard from './features/approver-dashboard';
/* import AdminManagePage from './features/pending/components'; */

import AdminManage from './features/requester-manage';
import Pending from './features/pending';

import ApproverReview from './features/approver-review';

import ApproverWaiting from './features/waiting-for-others';
import CFODashboard from './features/cfo-dasbhoard';

import CFOReview from './features/cfo-review';
import Rejected from './features/rejected';
import Forwarded from './features/forwarded';
import Approved from './features/approved';
import Completed from './features/completed';
import Reports from './features/reports';
import ReqApproved from './features/requester-approved';
import ForReview from './features/for-review';
import ReqReturned from './features/returned';
import Drafts from './features/drafts';
import DraftInitiate from './features/draft-edit';
import SettingsPage from './features/settings';
import Projects from './features/settings/components/Projects';
import Cancelled from './features/cancelled';
import AdminDashboard from './features/admin-dashboard';

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    // redirectUri: 'https://loi.tataprojects.com/session.html'
    redirectUri: 'https://loi.lexyslabs.com/session.html'
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};

const pca = new PublicClientApplication(configuration);

pca.addEventCallback((event) => {
  console.log(event);
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});

const roleBasedNavigation = (id) => {
  switch (id) {
    case 'REQUESTER':
      return '/dashboard';
    case 'APPROVER':
      return '/approver-dashboard';
    case 'CFO':
      return '/CFO-dashboard';
    case 'SBGHEAD':
      return '/approver-dashboard';
    case 'REVIEWER':
      return '/approver-dashboard';
    case 'ADMIN':
      return '/admin-manage';
    default:
      return '/';
  }
};

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return <Loader />;
  }

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const AuthChecker = ({ children }) => {
  let location = useLocation();

  const { loggedIn, user } = getLoggedInUser();

  let goTo = roleBasedNavigation(user?.user?.Role?.role || '');

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
};

function App() {
  const user = getLoggedInUser();
  let goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : user?.user?.Role?.role
    ? roleBasedNavigation(user?.user?.Role?.role || '')
    : '/login';

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <Login />
              </MsalProvider>
            </AuthChecker>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DashBoard />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="initiate-loi"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <InitiateLOI />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="CFO-dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CFODashboard />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="approver-dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverDashboard />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="waiting-for-others"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverWaiting />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="pending"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Pending />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="approved"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Approved />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="cancelled"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Cancelled />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="forward"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Forwarded />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="completed"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Completed />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="requester-approved"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ReqApproved />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="for-review"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ForReview />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="requester-returned"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ReqReturned />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="rejected"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Rejected />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="manage"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AdminManage />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="admin-manage"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AdminDashboard />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="drafts"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Drafts />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="settings"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SettingsPage />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="settings/projects"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Projects />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="reports"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Reports />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="review-actions/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverReview />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="cfo-review-actions/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CFOReview />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="drafts/draft-edit/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DraftInitiate />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="*"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <PageNotFound />{' '}
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="*"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <PageNotFound />
              </MsalProvider>
            </AuthChecker>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
