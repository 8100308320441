import React from 'react';

import CustomLayout from '@/common/Layout';
import DraftsPage from './components';

const Drafts = () => {
  return (
    <>
      <CustomLayout sider={true} tracker={true}>
        <DraftsPage />
      </CustomLayout>
    </>
  );
};

export default Drafts;
