import FormItem from '@/common/FormItem';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Col, Form, InputNumber, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

import styles from '../../InitiateLOI1/index.module.less';

const PaymentRetention = ({
  index,
  formValues,
  setFormValues,
  paymentItem,
  payments,
  setPayments,
  paymentIndex,
  defaultClauses
}) => {
  const handleChange = (type, value, inputName) => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex][type] = value;
    //console.log('retention change', type, value, tempPayments);
    setPayments(tempPayments);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
  };

  const handleClauseChange = (paymentIndex, clauseIndex, val, inputName) => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex].clauses[clauseIndex] = val;
    setPayments(tempPayments);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex].clauses.push(
      defaultClauses?.paymentRetention?.defaultClause
    );
    setPayments(tempPayments);
  };

  const handleDeleteClause = (clauseIndex) => {
    const tempPayments = [...payments];
    tempPayments[paymentIndex].clauses.splice(clauseIndex, 1);
    //console.log('final contracts', tempPayments);
    setPayments(tempPayments);
  };

  const clauses = paymentItem?.clauses || [];

  return (
    <>
      <Col className="font-18 sfprotext-bold ml-3 mb-2 mt-4" span={22}>
        {`Retention ${index > 0 ? index + 1 : ''}`}
      </Col>

      <Row className={`${styles.grey_holder}  w-100`}>
        <Col className="gutter-row ml-3" span={6}>
          <FormItem
            name={`retention_${paymentIndex}_percentage`}
            label={`Percentage of amount to be retained`}
            rules={[{ required: true, message: 'Please enter percentage' }]}
          >
            <InputNumber
              onChange={(val) =>
                handleChange(
                  'percentage',
                  val,
                  `retention_${paymentIndex}_percentage`
                )
              }
              value={paymentItem?.percentage}
              placeholder="Percentage of amount to be retained"
              className="w-100"
            />
          </FormItem>
        </Col>

        <Form.List
          name={`payment_${paymentIndex}_clauses`}
          initialValue={paymentItem.clauses}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, clauseIndex) => (
                <Col className="gutter-row  ml-3" span={18} key={clauseIndex}>
                  <Form.Item required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      noStyle
                      rules={[{ required: true, message: 'Please enter' }]}
                    >
                      <Row>
                        <Col span={22}>
                          <TextArea
                            defaultValue={
                              clauses[clauseIndex] ||
                              defaultClauses?.paymentRetention?.defaultClause
                            }
                            onChange={(e) =>
                              handleClauseChange(
                                paymentIndex,
                                clauseIndex,
                                e.target.value,
                                `retention_${paymentIndex}clause${clauseIndex}`,
                                fields
                              )
                            }
                            autoSize
                          />
                        </Col>
                        <Col span={2}>
                          {fields.length > 1 ? (
                            <span
                              onClick={() => {
                                remove(field.name);
                                handleDeleteClause(clauseIndex);
                              }}
                              className={`  pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                            >
                              <MinusCircleFilled style={{ color: '#D72D30' }} />
                            </span>
                          ) : null}
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form.Item>
                </Col>
              ))}
              <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                <Form.Item>
                  <Col className={`${styles.add_clause}  mb-3`} span={24}>
                    <span
                      onClick={() => {
                        add(defaultClauses?.paymentRetention?.defaultClause);
                        handleAddClause();
                      }}
                      className="cursor-pointer"
                    >
                      <PlusCircleFilled className="mr-1" /> Add clause
                    </span>
                  </Col>
                </Form.Item>
              </Col>
            </>
          )}
        </Form.List>
      </Row>
    </>
  );
};

export default PaymentRetention;
