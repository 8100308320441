import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { allTrackers } from './api';

const initialState = {
  all_rejected: []
};

const actions = {
  GETREJECTEDLIST: 'getRejectedList/GETREJECTEDLIST'
};

export const getRejectedList = createAsyncThunk(
  actions.GETREJECTEDLIST,
  async () => {
    const response = await allTrackers();
    return response;
  }
);

export const getRejectedListSlice = createSlice({
  name: 'rejectedList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRejectedList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRejectedList.fulfilled, (state, action) => {
        const { data } = action.payload;
        //console.log('Rejected', data);
        state.all_rejected = data;
      })
      .addCase(getRejectedList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = getRejectedListSlice.actions; */
/* export const { resetTrackers } = getRejectedListSlice.actions; */

export default getRejectedListSlice.reducer;
