import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { allTrackers } from './api';

const initialState = {
  all_completed: []
};

const actions = {
  GETCOMPLETEDLIST: 'getCompletedList/GETCOMPLETEDLIST'
};

export const getCompletedList = createAsyncThunk(
  actions.GETCOMPLETEDLIST,
  async () => {
    const response = await allTrackers();
    return response;
  }
);

export const getCompletedListSlice = createSlice({
  name: 'completedList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompletedList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompletedList.fulfilled, (state, action) => {
        const { data } = action.payload;
        //console.log('COMPLTED', data);
        state.all_completed = data;
      })
      .addCase(getCompletedList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = getCompletedListSlice.actions; */
/* export const { resetTrackers } = getCompletedListSlice.actions; */

export default getCompletedListSlice.reducer;
