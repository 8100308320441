import React from 'react';

import CustomLayout from '@/common/Layout';
import ApproverWaitingPage from './components';

const ApproverWaiting = () => {
  return (
    <>
      <CustomLayout sider={true} tracker={true}>
        <ApproverWaitingPage />
      </CustomLayout>
    </>
  );
};

export default ApproverWaiting;
