import Header from '@/common/Content/header';
import SearchBar from '@/common/Content/searchBar';
import CustomButton from '@/common/CustomButton';
import { getHistoryList } from '@/features/requester-manage/redux/slice';
import {
  ClockCircleOutlined,
  DownOutlined,
  MessageOutlined
} from '@ant-design/icons';

import {
  /*  Badge, */
  Empty,
  Col,
  Dropdown,
  Menu,
  Modal,
  Popover,
  Progress,
  Row,
  Space,
  Table,
  Timeline,
  Tooltip,
  Badge
} from 'antd';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTrackersList } from '../redux/slice';
import styles from './index.module.less';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';

const AdminManagePage = () => {
  const user = getLoggedInUser()?.user?.Role?.role;
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const current_status =
    window.location.pathname.replace('/', '') === 'pending'
      ? 'pending'
      : window.location.pathname.replace('/', '');

  const gcd = function (a, b) {
    if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

    return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
  };

  // const final = Math.floor(5) + '/' + Math.floor(5);
  const menu = (id) => (
    <Menu
      items={[
        {
          label: <div onClick={() => handleHistory(id)}>View audit trail</div>,
          key: '0'
        }
      ]}
    />
  );

  const columns = [
    {
      title: '',
      dataIndex: 'icons',
      render: () => {
        return (
          <>
            <div className={`${styles.table_icon}`}>
              {<ClockCircleOutlined />}
            </div>
          </>
        );
      }
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: '45%',
      render: (subject, record) => (
        <>
          <div className="mb-1">
            <b className="font-14 ">
              <a href={`/review-actions/${record.id}`} target="__blank">
                {subject.title}
                {`# ${record.loiOrLoa}-${record.loiId}`}
                {record?.projectName ? `${' || '}${record?.projectName}` : ''}
              </a>
            </b>
          </div>
          <div>
            To:{' '}
            {record?.Approvers?.length > 2
              ? record.Approvers.slice(0, 2).map((item) => item?.name).join`, `
              : record.Approvers.map((item) => item?.name).join`, `}
            {'\u00a0'}
            <Popover
              content={
                <>
                  <h2 className="sfprotext-bold">Approvers</h2>
                  {record?.Approvers?.map((item, index) => (
                    <p key={index}>{item.name}</p>
                  ))}
                </>
              }
              placement="rightTop"
            >
              {subject.Approvers.length > 2 ? (
                <Badge
                  className={`site-badge-count-109 ${styles.badge}`}
                  count={`+ ${subject.Approvers.length - 2}`}
                />
              ) : (
                ''
              )}
            </Popover>
          </div>
        </>
      )
    },
    {
      title: 'Status',
      key: 'description',
      dataIndex: 'description',
      width: '30%',
      render: (description) => (
        <>
          <Row>
            <Col span={18}>
              <div className="mr-5 mb-1">
                <h1>
                  <Progress
                    size="small"
                    // success={{ percent: 30 }}
                    percent={`${Math.floor(
                      (description.actionTakenApprovers /
                        description.totalApprovers) *
                        100
                    )}`}
                    strokeColor={'#1CB697'}
                    status="active"
                    format={gcd}
                    showInfo={false}
                  />
                </h1>
                <div>
                  {user === 'ADMIN' &&
                  description.approverStatusdescription.approverStatus
                    ? description.approverStatus
                    : description.status}
                </div>
              </div>
            </Col>
            <Col
              span={6}
              style={{
                marginLeft: '-40px',
                fontSize: '13px',
                marginTop: '3px'
              }}
            >{`${description.actionTakenApprovers} / ${description.totalApprovers} done`}</Col>
          </Row>
        </>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (text, record) => {
        return (
          <>
            {window.location.pathname.replace('/', '') === 'completed' ? (
              <Link to={'/review-actions/' + `${record.id}`}>
                <CustomButton
                  type="primary"
                  className={`${styles.use_button} mr-2`}
                >
                  View
                </CustomButton>
              </Link>
            ) : window.location.pathname.replace('/', '') === 'pending' ? (
              <CustomButton className={`${styles.use_button} mr-2`}>
                <Link to={'/review-actions/' + `${record.id}`}>View</Link>
              </CustomButton>
            ) : (
              <CustomButton className={`${styles.use_button} mr-2`}>
                {record.status === 'Completed' ? (
                  <a href={`/review-actions/${record.id}`} target="__blank">
                    View
                  </a>
                ) : (
                  <a href={`/review-actions/${record.id}`} target="__blank">
                    View
                  </a>
                )}
              </CustomButton>
            )}

            <CustomButton className={`${styles.down_button}`}>
              <Dropdown overlay={menu(record.id)} trigger={['click']}>
                <a onClick={(e) => e.preventDefault(e)}>
                  <Space>
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </CustomButton>
          </>
        );
      }
    }
  ];

  const { all_trackers, all_count } = useSelector(
    (state) => state.trackersList
  );
  const { history_data } = useSelector((state) => state.adminManage);

  const data = all_trackers?.map((item) => {
    const {
      Approvers,
      loiId,
      loiOrLoa,
      status,
      approverStatus,
      totalApprovers,
      actionTakenApprovers
    } = item;

    return {
      ...item,
      subject: {
        Approvers,
        loiOrLoa,
        loiId
      },
      description: {
        status,
        approverStatus,
        totalApprovers,
        actionTakenApprovers
      }
    };
  });

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
  }, [search, page]);
  const handleGetStats = () => {
    let payload = search !== '' ? { search: search } : { page: page };
    current_status !== 'manage' ? (payload['status'] = current_status) : '';
    dispatch(getTrackersList(payload));
  };

  const handleHistory = (id) => {
    setShowHistoryModal(true);
    dispatch(getHistoryList(id));
  };

  let locale = {
    emptyText: (
      <div>
        <Empty description={`No LoI/LoAs to display`} />
      </div>
    )
  };

  return (
    <>
      <div className={`mb-4 ${styles.MRS_container}`}>
        <Header
          justify="space-between"
          align="middle"
          title="Pending"
          buttons={[]}
        />
        <div>
          <SearchBar
            justify="start"
            align="middle"
            className="mt-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search LoI/LoA by Project Name'
            }}
            filters={false}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div>
        <Table
          locale={locale}
          rowKey={(record) => record.id}
          className={`${styles.custom_table}`}
          columns={columns}
          dataSource={data}
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            showSizeChanger: false,
            total: all_count
          }}
        />
        {showHistoryModal ? (
          <Modal
            title="Audit Trail"
            visible={showHistoryModal}
            onCancel={() => setShowHistoryModal(false)}
            footer={false}
          >
            <Timeline>
              {history_data.map((item, index) => {
                return (
                  <Timeline.Item
                    key={index}
                    color={
                      item.status === 'Rejected'
                        ? 'red'
                        : item.status === 'Signed'
                        ? 'green'
                        : item.status === 'Verified'
                        ? 'green'
                        : item.status === 'Initiated'
                        ? 'orange'
                        : 'blue'
                    }
                  >
                    {item.name}
                    {item.status !== '' ? (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === 'Signed'
                            ? 'Signed'
                            : item.status === 'Rejected'
                            ? 'Rejected'
                            : item.status === ''
                            ? `Needs to sign on ${moment(
                                history_data[0].createdAt
                              ).format('DD MMM YY, h:mm A')}`
                            : item.status
                        } on ${moment(item.updatedAt).format(
                          'DD MMM YY, h:mm A'
                        )}`}</span>
                      </div>
                    ) : (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === ''
                            ? `Needs to sign on ${moment(
                                history_data[0].createdAt
                              ).format('DD MMM YY, h:mm A')}`
                            : item.status
                        }`}</span>
                      </div>
                    )}
                    {item.comments !== '' && item.comments !== null ? (
                      <>
                        <div className={`${styles.content_text}`}>
                          <span style={{ fontSize: '9px' }}>
                            <MessageOutlined />
                          </span>
                          <span style={{ marginLeft: '4px', fontSize: '8px' }}>
                            <Tooltip placement="top" title={item?.comments}>
                              {item?.comments?.length > 40
                                ? item?.comments.substring(0, 40) + '...'
                                : item?.comments}
                            </Tooltip>
                          </span>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Modal>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default AdminManagePage;
