import FormItem from '@/common/FormItem';
import {
  CaretDownFilled,
  DeleteOutlined,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Form, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Option } from 'antd/lib/mentions';
import React from 'react';

import styles from '../../InitiateLOI1/index.module.less';

const UpdatedLD = ({
  formValues,
  setFormValues,
  item,
  ld,
  handleDeleteLD,
  ldItem,
  setLD,
  ldIndex,
  defaultClauses,
  itemNumber
}) => {
  const handleChange = (type, value, inputName) => {
    const tempLd = [...ld];
    tempLd[ldIndex][type] = value;
    if (value === 'LDApplicable') {
      tempLd[ldIndex].clauses = [defaultClauses?.ldp?.defaultClause];
    }
    setLD(tempLd);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
  };

  const handleClauseChange = (ldIndex, clauseIndex, val, inputName) => {
    const tempLd = [...ld];
    tempLd[ldIndex].clauses[clauseIndex] = val;
    setLD(tempLd);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempLd = [...ld];
    tempLd[ldIndex].clauses.push(defaultClauses?.ldp?.defaultClause);
    setLD(tempLd);
  };

  const handleDeleteClause = (clauseIndex) => {
    //console.log('delete clause', item);
    const tempLd = [...ld];
    tempLd[ldIndex].clauses.splice(clauseIndex, 1);
    //console.log('final contracts', tempLd);
    setLD(tempLd);
  };

  const clauses = ldItem?.clauses || [];

  return (
    <>
      <Col className="font-18 sfprotext-bold mt-4 mb-2" span={22}>
        {`LD Clause ${itemNumber}`}
      </Col>
      <Row className={`${styles.grey_holder}   w-100`}>
        <Col className="gutter-row ml-3" span={6}>
          <FormItem
            label="LD Clause for delay"
            name={`ld_select_${ldIndex}`}
            rules={[{ required: true, message: 'Please select' }]}
          >
            <Select
              value={ldItem.delayCause}
              onChange={(value) =>
                handleChange('delayCause', value, `ld_select_${ldIndex}`)
              }
              suffixIcon={<CaretDownFilled />}
              placeholder="Select"
            >
              <Option value="LDApplicable">Applicable</Option>
              <Option value="LDNot Applicable">Not Applicable</Option>
            </Select>
          </FormItem>
        </Col>{' '}
        <Col span={17}>
          {itemNumber > 1 ? (
            <div
              onClick={() => handleDeleteLD(ldIndex, item)}
              className={` cursor-pointer ${styles.delete_holder}`}
            >
              <DeleteOutlined />
            </div>
          ) : null}
        </Col>
        {ldItem.delayCause === 'LDApplicable' && (
          <>
            <Form.List
              name={`ld_${ldIndex}_clauses`}
              initialValue={ldItem.clauses}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, clauseIndex) => (
                    <Col
                      className="gutter-row  ml-3"
                      span={18}
                      key={clauseIndex}
                    >
                      <Form.Item required={false} key={field.key}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          noStyle
                          rules={[{ required: true, message: 'Please enter' }]}
                        >
                          <Row>
                            <Col span={22}>
                              <TextArea
                                defaultValue={
                                  clauses[clauseIndex] ||
                                  defaultClauses?.ldp?.defaultClause
                                }
                                onChange={(e) =>
                                  handleClauseChange(
                                    ldIndex,
                                    clauseIndex,
                                    e.target.value,
                                    `ld_${ldIndex}clause${clauseIndex}`,
                                    fields
                                  )
                                }
                                autoSize
                              />
                            </Col>
                            <Col span={2}>
                              {fields.length > 1 ? (
                                <span
                                  onClick={() => {
                                    remove(field.name);
                                    handleDeleteClause(clauseIndex);
                                  }}
                                  className={`  pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                                >
                                  <MinusCircleFilled
                                    style={{ color: '#D72D30' }}
                                  />
                                </span>
                              ) : null}
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  ))}
                  <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                    <Form.Item>
                      <Col className={`${styles.add_clause}  mb-3`} span={24}>
                        <span
                          onClick={() => {
                            add(defaultClauses?.ldp?.defaultClause);
                            handleAddClause();
                          }}
                          className="cursor-pointer"
                        >
                          <PlusCircleFilled className="mr-1" /> Add clause
                        </span>
                      </Col>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
            {/* {clauses.map((cls, clauseIndex) => (
              <>
                <Col className="gutter-row ml-3" span={20}>
                  <FormItem
                    rules={[{ required: true, message: 'Please enter' }]}
                    name={`ld_${ldIndex}_clause_${clauseIndex}`}
                  >
                    <TextArea
                      value={cls}
                      onChange={(e) =>
                        handleClauseChange(
                          ldIndex,
                          clauseIndex,
                          e.target.value,
                          `ld_${ldIndex}_clause_${clauseIndex}`
                        )
                      }
                      placeholder="Enter message"
                      autoSize
                      defaultValue={defaultClauses?.ldp?.defaultClause}
                    />
                  </FormItem>
                </Col>
                <Col span={2}>
                  <div
                    onClick={() => handleDeleteClause(clauseIndex)}
                    className={`mt-1 pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                  >
                    <MinusCircleFilled style={{ color: '#D72D30' }} />
                  </div>
                </Col>
              </>
            ))}
            <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
              <span onClick={handleAddClause} className="cursor-pointer">
                <PlusCircleFilled className="mr-1" /> Add clause
              </span>
            </Col> */}
          </>
        )}{' '}
      </Row>
    </>
  );
};

export default UpdatedLD;
