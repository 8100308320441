import React from 'react';

import CustomLayout from '@/common/Layout';
import RejectedPage from './components';

const Rejected = () => {
  return (
    <>
      <CustomLayout sider={true} tracker={true}>
        <RejectedPage />
      </CustomLayout>
    </>
  );
};

export default Rejected;
