import { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
/* import moment from 'moment'; */
import styles from './index.module.less';

export default function PdfViewerComponent(props) {
  const { all_file_data } = props;
  const containerRef = useRef(null);
  //console.log(all_file_data);

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <div ref={containerRef} /*  className={styles.parent_div} */>
        <Document
          // file={all_file_data?.Document?.fileUrl}
          file={`${process.env.REACT_APP_API_URL}/fetch-file?fileName=${all_file_data?.Document?.fileName}`}
          onLoadSuccess={onDocumentLoadSuccess}
          className={`${styles.document_viewer}`}
        >
          {Array.from(new Array(numPages), (_, index) => (
            <Page
              width={window.innerWidth}
              key={`page_${index + 1}`}
              wrap={true}
              pageNumber={index + 1}
              className={`${styles.document_viewer_page}`}
              size="A6"
            />
          ))}
        </Document>
      </div>
    </>
  );
}
