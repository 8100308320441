import { history } from '@/app/history';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';

import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';
import { rejLetter } from '../../redux/slice';
import styles from './index.module.less';

function RejectModal({ modalVisible, setModalVisible, loading, email }) {
  const [form] = Form.useForm();
  //console.log(allApprovers);
  const dispatch = useDispatch();
  const params = useParams();
  // const envelope_id = params.id;
  const user = LocalStorage.getItem('LOI_user');
  const onFinish = (values) => {
    values['id'] = params.id;
    values['status'] = 'Rejected';
    values['email'] = email;
    dispatch(rejLetter(values)).then((response) => {
      if (
        (user?.user?.Role?.role === 'APPROVER' ||
          user?.user?.Role?.role === 'SBGHEAD' ||
          user?.user?.Role?.role === 'REVIEWER') &&
        response.payload.success
      ) {
        history.push('/approver-dashboard');
      } else {
        history.push(`/CFO-dashboard`);
      }
    });
    setModalVisible('');
  };
  return (
    <CustomModal
      visible={modalVisible === 'reject' ? true : false}
      className={styles.background}
      title={'Reject the LoI/LoA'}
      onCancel={() => setModalVisible('')}
      width={'45%'}
      footer={null}
    >
      <div className="">
        <Form
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
          onFinish={onFinish}
        >
          <FormItem
            label="Comments"
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please enter comments'
              }
            ]}
          >
            <TextArea
              style={{ width: '100%' }}
              placeholder="Add your reason for rejecting the letter"
              rows={4}
              allowClear
            />
          </FormItem>

          <FormItem className="mb-1 text-right">
            <CustomButton
              className="mr-2"
              htmlType="button"
              onClick={() => setModalVisible('')}
            >
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Reject
            </CustomButton>
          </FormItem>
        </Form>
      </div>
    </CustomModal>
  );
}

export default RejectModal;
