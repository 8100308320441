import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import {
  Breadcrumb,
  Button,
  Col,
  Popconfirm,
  Row,
  Table,
  Tag,
  Tooltip,
  message
} from 'antd';
import {
  PoweroffOutlined,
  RightOutlined,
  StopOutlined
} from '@ant-design/icons';
import CustomLayout from '@/common/Layout/index';
//import emptyState from '../../../admin-doa-envelope/images/empty.svg';
import SearchBar from '@/common/Content/searchBar';

import { useDispatch, useSelector } from 'react-redux';
import {
  blockProject,
  getAllProjects,
  getProjectById
} from '../../redux/slice';
//import Pencil from '@/features/initiate-envelope/images/Pencilsvg';
import AddModal from './AddModal';
import EditModal from './EditModal';
import Pencil from '@/features/approver-review/images/Pencilsvg';
import CBModal from './CBModal';
import { getAllBu } from '@/features/initiate-loi/redux/slice';
//import { IconName } from 'react-icons/fa';
//import Pencil from '@/features/approver-review/images/Pencilsvg';

const Projects = () => {
  const { all_Projects, pagination, byIdLoading, tableLoading, projectById } =
    useSelector((state) => state.settings);

  //   console.log(pagination);
  const [id, setId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCBModalOpen, setIsCBModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [sbuSearch, setSbuSearch] = useState('');
  const [page, setPage] = useState(1);

  const [buName, setBuName] = useState('');
  const [dropDownLoading, setDropDownLoading] = useState(false);
  const handleGetAllBu = () => {
    const payload = {
      search: buName !== undefined ? buName : '',
      offset: 0,
      limit: 50
    };
    dispatch(getAllBu(payload)).then((response) => {
      if (response.payload.success === true) {
        setDropDownLoading(false);
      }
    });
  };

  const onSearchBu = (e) => {
    setBuName(e);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showEditModal = (id) => {
    console.log(id);
    dispatch(getProjectById(id));
    setIsEditModalOpen(true);
  };
  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const showCBModal = (id) => {
    console.log(id);
    dispatch(getProjectById(id));
    setIsCBModalOpen(true);
  };
  const handleCBOk = () => {
    setIsCBModalOpen(false);
  };
  const handleCBCancel = () => {
    setIsCBModalOpen(false);
  };

  const confirmBlock = (projectId, isBlocked) => {
    const formData = new FormData();
    formData.append('id', projectId);
    formData.append('action', isBlocked ? 'unblock' : 'block');
    const formDataObject = {
      url: projectId, // Change this to the appropriate URL
      payload: {
        action: isBlocked ? 'unblock' : 'block'
      }
    };

    console.log(formDataObject);

    dispatch(blockProject(formDataObject)).then((response) => {
      if (response.payload.success) {
        handleGetAllDesignations();
        message.success(response.payload.message);
      } else {
        message.error(response.payload.message);
      }
    });
  };
  const cancelBlock = (e) => {
    return e;
  };

  const dispatch = useDispatch();

  const handleGetAllDesignations = () => {
    let payload =
      search !== ''
        ? {
            search: search,
            page: page,
            offset: 1,
            limit: 1000000,
            fetch: 'all'
          }
        : {
            page: page,
            offset: 1,
            limit: 1000000,
            fetch: 'all'
          };
    dispatch(getAllProjects(payload));
  };

  const handleGetSbus = () => {
    // let payload =
    //   sbuSearch !== '' && sbuSearch !== undefined ? { search: sbuSearch } : {};
    // dispatch(getAllSbus(payload));
  };

  useEffect(() => {
    handleGetSbus();
  }, [sbuSearch]);

  useEffect(() => {
    handleGetAllDesignations();
  }, [search, page]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSbuSearchChange = (e) => {
    setSbuSearch(e);
  };
  const data = all_Projects?.map((item) => {
    const { id, projectName, isActive, BuMaster } = item;
    return {
      ...item,
      status: { isActive },
      projectName: {
        projectName
      },
      bu: { BuMaster },

      id: { id }
    };
  });

  const columns = [
    {
      width: '30%',
      title: 'Name',
      dataIndex: 'projectName',
      key: 'projectName',
      render: (approvers) => <a>{approvers?.projectName}</a>
    },

    {
      width: '30%',
      title: 'BU-JV',
      dataIndex: 'bu',
      key: 'bu',
      render: (approvers) => (
        <Tooltip placement="top" title={approvers?.BuMaster?.buName}>
          <div className="text-caps">
            {' '}
            {approvers?.BuMaster?.buName?.length > 30
              ? `${approvers?.BuMaster?.buName?.substring(0, 25)}...`
              : approvers?.BuMaster?.buName || 'NA'}
          </div>
        </Tooltip>
      )
    },

    {
      width: '15%',
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <>
          <Tag color={status?.isActive === true ? 'green' : 'red'}>
            {status?.isActive === true ? 'Active' : 'Deactivated'}
          </Tag>
        </>
      )
    },
    {
      width: '15%',
      title: 'is Blocked?',
      dataIndex: 'status',
      key: 'status',
      render: (status, test) => (
        <>
          <Tag
            className="ml-2"
            color={test?.isBlocked === false ? 'green' : 'red'}
          >
            {test?.isBlocked === true ? 'Blocked' : 'Unblocked'}
          </Tag>
        </>
      )
    },
    {
      width: '20%',
      align: 'center',
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id, test) => (
        <>
          <Tooltip placement="bottom" title="Edit">
            <span onClick={() => showEditModal(id.id)}>
              <Pencil />
            </span>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={`${test.isBlocked ? 'Unblock' : 'Block'}`}
          >
            <span className="ml-3 mr-3">
              <Popconfirm
                title={`Are you sure to ${
                  test.isBlocked ? 'unblock' : 'block'
                } this project?`}
                onConfirm={() => confirmBlock(id.id, test?.isBlocked)}
                onCancel={cancelBlock}
                okText={`${test.isBlocked ? 'Unblock' : 'Block'}`}
                cancelText="No"
              >
                <StopOutlined />
              </Popconfirm>
            </span>
          </Tooltip>
          <Tooltip placement="bottom" title={`CB Status`}>
            <span onClick={() => showCBModal(id.id)}>
              <PoweroffOutlined />
            </span>
          </Tooltip>
        </>
      )
    }
  ];

  return (
    <CustomLayout sider={false}>
      {' '}
      <div className={`${styles.breadcrub_container}`}>
        {' '}
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/projects"
              >
                Configure Projects
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              onClick={showModal}
              className="font-14 sfprotext-bold"
              type="primary"
            >
              Add Project
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={18}>
          <div className="mt-3 font-16 sfprotext-bold">Projects</div>
        </Col>
        <Col span={6}>
          <SearchBar
            onChange={handleSearchChange}
            className={`mt-3 ${styles.createdSearch}`}
            inputProps={{
              placeholder: 'Search by project name'
            }}
          />
        </Col>
      </Row>
      {data?.length === undefined ? (
        <>
          <div className="df-jc-ac mt-5">{/* <img src={emptyState} /> */}</div>
          <div className="df-jc-ac mt-3">
            <div className="sfprotext-regular font-14">
              You haven’t created any projects yet.
            </div>
          </div>
          <div className="df-jc-ac mt-3">
            <Button
              onClick={showModal}
              type="primary"
              className="sfprotext-bold font-14"
            >
              Add Project
            </Button>
          </div>
        </>
      ) : (
        <Table
          loading={tableLoading}
          rowKey={(id) => id.id.id}
          className={`mt-4 ${styles.custom_table}`}
          /*  rowSelection={true} */
          dataSource={data}
          columns={columns}
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: 10,
            total: pagination?.total_records,
            showSizeChanger: false
          }}
        />
      )}
      {isModalOpen ? (
        <AddModal
          buName={buName}
          handleGetAllBu={handleGetAllBu}
          dropDownLoading={dropDownLoading}
          onSearchBu={onSearchBu}
          handleSbuSearchChange={handleSbuSearchChange}
          handleGetAllDesignations={handleGetAllDesignations}
          setIsModalOpen={setIsModalOpen}
          isModalVisible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : null}
      {isCBModalOpen ? (
        <CBModal
          byIdLoading={byIdLoading}
          id={id}
          setId={setId}
          projectById={projectById}
          handleSbuSearchChange={handleSbuSearchChange}
          handleGetAllDesignations={handleGetAllDesignations}
          setIsEditModalOpen={setIsCBModalOpen}
          isEditModalVisible={isCBModalOpen}
          onOk={handleCBOk}
          onCancel={handleCBCancel}
        />
      ) : null}
      {isEditModalOpen ? (
        <EditModal
          setBuName={setBuName}
          buName={buName}
          handleGetAllBu={handleGetAllBu}
          dropDownLoading={dropDownLoading}
          onSearchBu={onSearchBu}
          setSbuSearch={setSbuSearch}
          byIdLoading={byIdLoading}
          //   all_SBUs={all_SBUs}
          setId={setId}
          id={id}
          handleSbuSearchChange={handleSbuSearchChange}
          handleGetAllDesignations={handleGetAllDesignations}
          projectById={projectById}
          setIsEditModalOpen={setIsEditModalOpen}
          isEditModalVisible={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
        />
      ) : null}
    </CustomLayout>
  );
};

export default Projects;
