import styles from './index.module.less';
import tata from '../images/tata.svg';
import { Col, Row } from 'antd';
import noida from '../images/Noida.png';
import mumbaihkp from '../images/Mumbai - HKP.png';
import mumbaidelphi from '../images/Mumbai Delphi.png';
import mumbaiob from '../images/Mumbai-OneBoulevard.png';
import hyd from '../images/Hyderabad.png';
import { getLoggedInUser } from 'utilities/helpers';
import { ToWords } from 'to-words';
export default function PdfViewerComponent({
  myParam,
  tempDocList,
  boq,
  supportingDoc,
  mobilisation,
  packing,
  payments,
  formValues,
  mrdt,
  warranty,
  ld,
  dlp,
  freight,
  contractPerformance,
  message0,
  message3,
  delivery
}) {
  //console.log('LOI', formValues.loiMessage);
  //console.log('LOA', formValues.loaMessage);
  //console.log('FORMVAL', formValues);
  //console.log('Payments', payments);
  //console.log('Packing', packing);
  //console.log('DLP', dlp);
  //console.log('MRDT', mrdt);
  //console.log('LD', ld);
  //console.log('Warranty', warranty);
  //console.log('mobilisation', mobilisation);
  //console.log('Fr', freight);
  //console.log('cpnt', contractPerformance);
  console.log('AA', myParam);
  const user = getLoggedInUser();
  //console.log('name', user?.user?.givenName);
  //console.log('SUPP', supportingDoc);
  let valid_packing = packing.find((obj) => {
    return obj?.vendorScope === 'yes';
  });

  let valid_delivery = delivery.find((obj) => {
    return obj?.deliveryScope === 'yes';
  });

  let valid_mobilisation = mobilisation.find((obj) => {
    return obj?.mobilisationAppicable === 'MOBApplicable';
  });

  let valid_dlp = dlp.find((obj) => {
    return obj?.dlpApplicable === 'DLPApplicable';
  });
  let valid_mrdt = mrdt.find((obj) => {
    return obj?.mrdTime === 'MinApplicable';
  });
  let valid_ld = ld.find((obj) => {
    return obj?.delayCause === 'LDApplicable';
  });

  let valid_warranty = warranty.find((obj) => {
    return obj?.warrantyApplicable === 'WarrantyApplicable';
  });

  let valid_freight = freight.find((obj) => {
    return obj?.freightVendorScope === 'yes';
  });

  let valid_contract = contractPerformance.find((obj) => {
    return obj?.pbgApplicable === 'PBGApplicable';
  });

  let valid_boq = boq.find((obj) => {
    return obj?.boqVendorScope === 'manual';
  });

  console.log('Valid', boq?.[0].clauses);

  const toWords = new ToWords({
    localeCode: formValues?.currency === 'INR' ? 'en-IN' : 'en-US',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: 'Rupee',
        plural: 'Rupees',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paise',
          symbol: ''
        }
      }
    }
  });

  const Content = () => {
    return (
      <>
        <div className="pl-3 pr-3">
          <table className={`${styles.tatatable1}`}>
            <tr>
              <th>Type</th>
              <th>PO/WO</th>
              <th>LoI/LoA Date</th>
            </tr>
            <tr>
              <td>{formValues.loi}</td>
              <td>{formValues.po}</td>
              <td>{formValues.doi}</td>
            </tr>
          </table>
          <br />
          <table className={`${styles.tatatable} ${styles.tab} text-left`}>
            <tr>
              <th>BU-JV Name</th>
              <td>{formValues.bu}</td>
            </tr>
            <tr>
              <th>Project Name</th>
              <td>{formValues.project_name}</td>
            </tr>
            {formValues.project_Code ? (
              <tr>
                <th>Project Code</th>
                <td>{formValues.project_Code}</td>
              </tr>
            ) : null}
          </table>
          <br />
          <table className={`${styles.tatatable} ${styles.tab}  text-left`}>
            <tr>
              <th> Vendor Name</th>
              <td style={{ fontWeight: 'bold' }}>{formValues.v_name} </td>
            </tr>
            <tr>
              <th>ERP Vendor Code</th>
              <td>{formValues.v_company}</td>
            </tr>
            <tr>
              <th>Vendor Site</th>
              <td>{formValues.v_site}</td>
            </tr>
            <tr>
              <th rowSpan="4" style={{ verticalAlign: 'top' }}>
                Vendor Site Address
              </th>
              <td>{formValues.v_site_address}</td>
            </tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>

            <tr>
              <th>{`Vendor's Contact Person`}</th>
              <td>{formValues.vendor_contact}</td>
            </tr>
            <tr>
              <th>{`Vendor's Contact Email ID`}</th>
              <td>{formValues.v_contact_id}</td>
            </tr>
            <tr>
              <th>Vendors Contact Number</th>
              <td>{formValues.v_contact_no}</td>
            </tr>
            <tr>
              <th>BU-JV Name</th>
              <td>{formValues.bu}</td>
            </tr>
            <tr>
              <th>Project Name</th>
              <td>{formValues.project_name}</td>
            </tr>
            {formValues.project_Code ? (
              <tr>
                <th>Project Code</th>
                <td>{formValues.project_Code}</td>
              </tr>
            ) : null}
          </table>
          <br />
          <table className={`${styles.tatatable} ${styles.tab}  text-left`}>
            <tr>
              <th>{`Creator's Name`}</th>
              <td> {user?.user?.givenName}</td>
            </tr>
            <tr>
              <th>{`Creator's Email ID`}</th>
              <td> {user?.user?.email}</td>
            </tr>
          </table>
        </div>
        <Row className="pl-3 pr-5 pt-4 pb-4">
          <Col span={18} className={`text-left`}>
            <b>Ref:</b> {formValues.vendor_quotation}
          </Col>
        </Row>
        <Row className="pl-3 pt-1">
          <Col>
            <b>To,</b>
          </Col>
        </Row>
        <Row className="pl-3 pt-1">
          <Col>
            <b>{formValues.vendor_contact}</b>
          </Col>
        </Row>
        <Row className="pl-3 pt-4">
          <span style={{ textAlign: 'left' }}>
            {formValues.loi === 'LOI'
              ? formValues.loiMessage
              : formValues.loaMessage}
          </span>
        </Row>
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b>Broad Scope </b>
        </Row>
        <Row className="pl-3 text-left">
          {' '}
          <div
            dangerouslySetInnerHTML={{ __html: formValues.broad_scope }}
          ></div>
        </Row>
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b> Basic Contact Price </b>
        </Row>
        <Row className="pl-3 ">
          {formValues.contract_price}
          {`\u000a`} {`\u00A0`}
          <span className="text-left">
            {`(${toWords.convert(formValues.contract_price, {
              currency: formValues?.currency !== 'INR' ? false : true
            })})`}
          </span>
        </Row>
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b> Currency </b>
        </Row>
        <Row className="pl-3 ">{formValues.currency}</Row>
        {valid_boq ? (
          <>
            <Row
              className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}
            >
              <b> BOQ </b>
            </Row>
            <table
              className={` ml-3  mt-2 mb-1 ${styles.tatatable1} ${styles.tab}`}
            >
              <thead>
                <th>Sl. No.</th>
                <th>Description</th>
                <th>Unit</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Amount</th>
              </thead>
              <tbody className={`${styles.tatatable}`}>
                {boq?.[0]?.clauses?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.field2}</td>
                    <td>{item?.field1}</td>
                    <td>{item?.field3}</td>
                    <td>{item?.field4}</td>
                    <td>{item?.field5}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : null}
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b> Price Basis </b>
        </Row>
        <Row className="pl-3 ">
          {formValues.price_basis === 'Others'
            ? formValues?.price_basis_text
            : formValues?.price_basis}
        </Row>
        <Row className="pl-3 pt-4 text-left">{message0}</Row>
        <Row
          className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}
        ></Row>
        <Row className={`pl-3 pt-4 ${styles.underline}`}>
          <h1>
            <b>Payment Terms</b>
          </h1>
        </Row>
        <Col>
          {payments?.map((item, index) => (
            <>
              <div className="ml-5" key={index} style={{ textAlign: 'left' }}>
                <b>
                  {item?.type} {index + 1}:
                </b>
                <ol key={index}>
                  {item?.clauses?.map((item, index) => (
                    <>
                      <li key={index}>{item}</li>
                      <br />
                    </>
                  ))}
                </ol>
              </div>
            </>
          ))}
        </Col>
        {valid_packing ? (
          <>
            <Row className={`pl-3 pt-4 ${styles.underline}`}>
              <div>
                <h1>
                  <b>Packing and Forwarding</b>
                </h1>
              </div>
            </Row>
            <Col>
              {packing?.map((item, index) => (
                <>
                  {item?.vendorScope === 'yes' ? (
                    <>
                      <h3 className="pl-3" style={{ textAlign: 'left' }}>
                        <b>Clause: {index + 1}</b>
                      </h3>
                      <div className="ml-5 mb-3" style={{ textAlign: 'left' }}>
                        <b>
                          Is in Vendor Scope:{' '}
                          <b style={{ textTransform: 'capitalize' }}>
                            {' '}
                            {item.vendorScope}
                          </b>
                        </b>
                        <br />
                      </div>
                      <div
                        className="pl-3"
                        key={index}
                        style={{ textAlign: 'left' }}
                      >
                        <ol key={index}>
                          {item?.clauses?.map((item, index) => (
                            <>
                              <li key={index}>{item?.field1}</li>
                              <br />
                            </>
                          ))}
                        </ol>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Col>
          </>
        ) : (
          ''
        )}
        {valid_delivery ? (
          <>
            <Row className={`pl-3 pt-4 ${styles.underline}`}>
              <div>
                <h1>
                  <b>Delivery Schedule/Completion</b>
                </h1>
              </div>
            </Row>
            <Col>
              {delivery?.map((item, index) => (
                <>
                  {item?.deliveryScope === 'yes' ? (
                    <>
                      <h3 className="pl-3" style={{ textAlign: 'left' }}>
                        <b>Clause: {index + 1}</b>
                      </h3>
                      <div className="ml-5 mb-3" style={{ textAlign: 'left' }}>
                        <b>
                          Is in Vendor Scope:{' '}
                          <b style={{ textTransform: 'capitalize' }}>
                            {' '}
                            {item.deliveryScope}
                          </b>
                        </b>
                        <br />
                      </div>
                      <div
                        className="pl-3"
                        key={index}
                        style={{ textAlign: 'left' }}
                      >
                        <ol key={index}>
                          {item?.clauses?.map((item, index) => (
                            <>
                              <li key={index}>{item?.field1}</li>
                              <br />
                            </>
                          ))}
                        </ol>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Col>
          </>
        ) : (
          ''
        )}
        <Row className="pl-3 pt-4 text-left">{message3}</Row>
        {valid_mobilisation ? (
          <>
            <Row className={`pl-3 pt-4 ${styles.underline}`}>
              <h1>
                <b>
                  Mobilisation Time <br />
                </b>
              </h1>
            </Row>
            <Col>
              {mobilisation?.map((item, index) => (
                <>
                  {item?.mobilisationAppicable === 'MOBApplicable' ? (
                    <>
                      <Row className="pl-3">
                        <h3 style={{ textAlign: 'left' }}>
                          <b>Clause: {index + 1}</b>
                        </h3>
                      </Row>
                      <div
                        className="pl-3"
                        key={index}
                        style={{ textAlign: 'left' }}
                      >
                        <ol key={index}>
                          {item?.clauses?.map((item, index) => (
                            <>
                              <li key={index}>{item}</li>
                              <br />
                            </>
                          ))}
                        </ol>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Col>
          </>
        ) : (
          ''
        )}
        {valid_mrdt ? (
          <>
            <Row className={`pl-3 pt-4 ${styles.underline}`}>
              <h1>
                <b>Min Resource Deployment Time</b>
              </h1>
            </Row>
            <Col>
              {mrdt?.map((item, index) => (
                <>
                  {item?.mrdTime === 'MinApplicable' ? (
                    <>
                      <Row className="pl-3">
                        <h3 style={{ textAlign: 'left' }}>
                          <b>Clause: {index + 1}</b>
                        </h3>
                      </Row>
                      <div
                        className="pl-3"
                        key={index}
                        style={{ textAlign: 'left' }}
                      >
                        <ol key={index}>
                          {item?.clauses?.map((item, index) => (
                            <div key={index}>
                              <li>{item}</li>
                              <br />
                            </div>
                          ))}
                        </ol>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Col>
          </>
        ) : (
          ''
        )}
        {valid_ld ? (
          <>
            <Row className={`pl-3 pt-4 ${styles.underline}`}>
              <h1>
                <b>
                  LD Clause <br />
                </b>
              </h1>
            </Row>
            <Col>
              {ld?.map((item, index) => (
                <>
                  {item?.delayCause === 'LDApplicable' ? (
                    <>
                      <Row className="pl-3">
                        <h3 style={{ textAlign: 'left' }}>
                          <b>Clause: {index + 1}</b>
                        </h3>
                      </Row>
                      <div
                        className="pl-3"
                        key={index}
                        style={{ textAlign: 'left' }}
                      >
                        <ol key={index}>
                          {item?.clauses?.map((item, index) => (
                            <>
                              <li key={index}>{item}</li>
                              <br />
                            </>
                          ))}
                        </ol>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Col>
          </>
        ) : (
          ''
        )}
        {valid_warranty ? (
          <>
            <Row className={`pl-3 pt-4 ${styles.underline}`}>
              <h1>
                <b>
                  Warranty Clause <br />
                </b>
              </h1>
            </Row>
            <Col>
              {warranty?.map((item, index) => (
                <>
                  {item?.warrantyApplicable === 'WarrantyApplicable' ? (
                    <>
                      <Row className="pl-3">
                        <h3 style={{ textAlign: 'left', clear: 'both' }}>
                          <b>Clause: {index + 1}</b>
                        </h3>
                      </Row>
                      <div
                        className="pl-3"
                        key={index}
                        style={{ textAlign: 'left' }}
                      >
                        <ol key={index}>
                          {item?.clauses?.map((item, index) => (
                            <>
                              <li key={index}>{item}</li>
                            </>
                          ))}
                        </ol>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Col>
          </>
        ) : (
          ''
        )}
        {valid_dlp ? (
          <>
            <Row className={`pl-3 pt-4 ${styles.underline}`}>
              <h1>
                <b>
                  DLP Clause <br />
                </b>
              </h1>
            </Row>
            <Col>
              {dlp?.map((item, index) => (
                <>
                  {item?.dlpApplicable === 'DLPApplicable' ? (
                    <>
                      <Row className="pl-3">
                        <h3 style={{ textAlign: 'left', clear: 'both' }}>
                          <b>Clause: {index + 1}</b>
                        </h3>
                      </Row>
                      <div
                        className="pl-3"
                        key={index}
                        style={{ textAlign: 'left' }}
                      >
                        <ol key={index}>
                          {item?.clauses?.map((item, index) => (
                            <>
                              <li key={index}>{item}</li>
                              <br />
                            </>
                          ))}
                        </ol>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Col>
          </>
        ) : (
          ''
        )}
        {valid_contract ? (
          <>
            <Row className={`pl-3 pt-4 ${styles.underline}`}>
              <div>
                <h1>
                  <b>Contract Performance</b>
                </h1>
              </div>
            </Row>
            <Col>
              {contractPerformance?.map((item, index) => (
                <>
                  {item?.pbgApplicable === 'PBGApplicable' ? (
                    <>
                      <h3
                        className="pl-3"
                        style={{ textAlign: 'left', clear: 'both' }}
                      >
                        <b>Clause: {index + 1}</b>
                      </h3>

                      <div
                        className="pl-3"
                        key={index}
                        style={{ textAlign: 'left' }}
                      >
                        <ol key={index}>
                          {item?.clauses?.map((item, index) => (
                            <>
                              <li key={index}>{item?.field2}</li>
                              <br />
                            </>
                          ))}
                        </ol>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Col>
          </>
        ) : (
          ''
        )}
        {formValues?.validitycontract ? (
          <>
            {' '}
            <Row
              className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}
            >
              <b> Validity of Contract (in months) </b>
            </Row>
            <Row className="pl-3 ">{formValues?.validitycontract}</Row>
          </>
        ) : null}
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b>Special Terms and Conditions</b>
        </Row>
        <Row className="pl-3 text-left">
          <div dangerouslySetInnerHTML={{ __html: formValues.spl_terms }}></div>
        </Row>
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b> Quantity Variation </b>
        </Row>
        <Row className="pl-3 text-left">{formValues.quantity_variation}</Row>
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b> Tax and Duties </b>
        </Row>
        <Row className="pl-3 text-left">
          <div
            dangerouslySetInnerHTML={{ __html: formValues.tax_duties }}
          ></div>
        </Row>
        {valid_freight ? (
          <>
            <Row className={`pl-3 pt-4 ${styles.underline}`}>
              <h1>
                <b>
                  Freight and Insurance <br />
                </b>
              </h1>
            </Row>
            <Col>
              {freight?.map((item, index) => (
                <>
                  {item?.freightVendorScope === 'yes' ? (
                    <>
                      <Row className="pl-3">
                        <h3 style={{ textAlign: 'left', clear: 'both' }}>
                          <b>Clause: {index + 1}</b>
                        </h3>
                      </Row>
                      <div
                        className="pl-3"
                        key={index}
                        style={{ textAlign: 'left' }}
                      >
                        <ol key={index}>
                          {item?.clauses?.map((item, index) => (
                            <>
                              <li key={index}>{item}</li>
                              <br />
                            </>
                          ))}
                        </ol>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </Col>
          </>
        ) : (
          ''
        )}
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b> All Other T&C Shall Be As Per Tata Projects </b>
        </Row>
        <Row className="pl-3 ">
          {formValues?.all_tpl?.length === 1
            ? formValues?.all_tpl
            : formValues?.all_tpl?.join(`, `)}
        </Row>
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b> Is Anti Bribery Accepted</b>
        </Row>
        <Row className="pl-3 ">Yes</Row>
        {formValues.cancellation_notice ? (
          <>
            <Row
              className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}
            >
              <b> Cancellation Notice</b>
            </Row>{' '}
            <Row className="pl-3 text-left">
              <b>{formValues.cancellation_notice}</b>
            </Row>
          </>
        ) : (
          ''
        )}
        <Row
          className={`pl-3 pt-4 sfprotext-regular font-14 text-center df-jc-ac`}
        >
          <b className="text-center">
            {' '}
            This is a digitally-signed document. No signature is required.
          </b>
        </Row>{' '}
        <Row className={`pl-3 sfprotext-regular font-14 text-center df-jc-ac`}>
          <b className="text-center">
            {' '}
            Refer to Document Approval Summary for approval records
          </b>
        </Row>{' '}
        <Row className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}>
          <b>List of documents for reference to vendors </b>
        </Row>
        <Row className="pl-3 text-left">
          <div dangerouslySetInnerHTML={{ __html: formValues.doc_list }}></div>
        </Row>
        {tempDocList?.length > 0 && !myParam ? (
          <>
            <Row
              className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}
            >
              <b>
                {supportingDoc?.length === 0
                  ? 'Enclosure/s'
                  : 'Previous Enclosure/s'}
              </b>
            </Row>
            <Row className="pl-3 ">
              <ol style={{ textAlign: 'left' }}>
                {tempDocList.map((item, index) => (
                  <li style={{ textAlign: 'left' }} key={index}>
                    <a href={item.fileUrl} download>
                      {item.fileName}
                    </a>
                  </li>
                ))}
              </ol>
            </Row>
          </>
        ) : null}
        {supportingDoc?.length > 0 ? (
          <>
            <Row
              className={`pl-3 pt-4 ${styles.underline} sfprotext-bold font-14`}
            >
              <b> Enclosure/s</b>
            </Row>
            <Row className="pl-3 ">
              <ol style={{ textAlign: 'left' }}>
                {supportingDoc.map((item, index) => (
                  <li style={{ textAlign: 'left' }} key={index}>
                    <a href={URL.createObjectURL(item)} download>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ol>
            </Row>
          </>
        ) : null}
        <div className={styles.footer_logo}>
          {formValues.agreement_Location === 'Mumbai-Delphi' ? (
            <img src={mumbaidelphi} alt="logo" />
          ) : formValues.agreement_Location === 'Noida' ? (
            <img src={noida} alt="logo" />
          ) : formValues.agreement_Location === 'Mumbai-HKP' ? (
            <img src={mumbaihkp} alt="logo" />
          ) : formValues.agreement_Location === 'Mumbai-OneBoulevard' ? (
            <img src={mumbaiob} alt="logo" />
          ) : formValues.agreement_Location === 'Hyderabad' ? (
            <img src={hyd} alt="logo" />
          ) : (
            ''
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.pdf_design}>
        <div className={styles.logo}>
          <img src={tata} alt="logo" />
          <div className={`mt-4 ${styles.title}`}>
            <p>
              {formValues.loi === 'LOI'
                ? `Letter of Intent (${formValues.loi}) `
                : `Letter of Award (${formValues.loi})`}
            </p>
          </div>
        </div>
        <Content />
      </div>
    </>
  );
}
