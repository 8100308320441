import React, { useState } from 'react';
import styles from './index.module.less';
import { Col, Form, Input, message, Row, Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import OtpInput from 'react-otp-input';
import TextArea from 'antd/lib/input/TextArea';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appLetter } from '../../redux/slice';
import { history } from '@/app/history';
import LocalStorage from 'utilities/localStorage';
import { CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons';

function ApproveModal({
  all_project_amount,
  loading,
  modalVisible,
  setModalVisible,
  email
}) {
  const user = LocalStorage.getItem('LOI_user');
  const [approversign, setApproversign] = useState('');
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  //console.log('ALL', all_project_amount?.approvedAmount);
  const onFinish = (values) => {
    // let formData = new FormData();

    values['id'] = params.id;
    values['id'] = params.id;
    values['status'] = 'Accepted';
    values['email'] = email;
    dispatch(appLetter(values)).then((response) => {
      if (
        (user?.user?.Role?.role === 'APPROVER' ||
          user?.user?.Role?.role === 'SBGHEAD' ||
          user?.user?.Role?.role === 'REVIEWER') &&
        response.payload.success
      ) {
        history.push('/approver-dashboard');
      } else if (user?.user?.Role?.role === 'CFO' && response.payload.success) {
        history.push(`/CFO-dashboard`);
      } else {
        message.error(response.payload.message);
        setModalVisible('');
      }
    });
    setModalVisible('');
  };
  return (
    <CustomModal
      visible={modalVisible === 'approved' ? true : false}
      className={styles.background}
      title={'Approve the LoI/LoA with signature'}
      onCancel={() => setModalVisible('')}
      width={'60%'}
      footer={null}
    >
      <Row gutter={[8, 16]} className={`mb-4 df-jc-ac  ${styles.value_div}`}>
        <Col span={11} className={`w-100 font-14 sfprotext-bold`}>
          <CheckCircleFilled className="ml-2 mr-1" style={{ color: 'green' }} />
          Value of Approved LoIs/LoAs:{' '}
          <b>{all_project_amount?.approvedAmount}</b>
        </Col>
        <div className={`${styles.vl}`} />
        <Col className={`w-100 font-14 sfprotext-bold`} span={11}>
          <ClockCircleOutlined className=" mr-1" style={{ color: 'blue' }} />{' '}
          Value of In-Progress LoIs/LoAs:{' '}
          <b>{all_project_amount?.inProgressAmount}</b>
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        onFinish={onFinish}
      >
        <FormItem
          label="Enter the name or initial to sign"
          name="sign"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          onChange={(e) => setApproversign(e.target.value)}
        >
          <Input placeholder="Enter name or initial" />
        </FormItem>
        {approversign != '' ? (
          <FormItem label="Signature preview" name="sign">
            <span className={`${styles.signpreview}`}>{approversign}</span>
          </FormItem>
        ) : (
          ''
        )}
        <FormItem
          label="Enter OTP sent to your e-mail"
          name="otp"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <OtpInput numInputs={4} separator="" className={`${styles.otp}`} />
        </FormItem>
        <FormItem
          label="Comments"
          name="comments"
          rules={[
            {
              required: false,
              message: 'Please enter comments'
            }
          ]}
        >
          <TextArea
            style={{ width: '100%' }}
            placeholder="Add any comments before you approve the letter"
            rows={4}
            allowClear
          />
        </FormItem>
        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={() => setModalVisible('')}>
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Approve
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
}

export default ApproveModal;
