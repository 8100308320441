import apiClient from 'utilities/apiClient';

export const create_LOI = (payload) => {
  return apiClient.post(`${apiClient.Urls.create_loi}`, payload, true, 'file');
};

export const getApprovers = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_approvers}`, payload, true);
};

export const getDraft = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_draft_by_id}/${payload}`,
    payload,
    true
  );
};

export const getReviewers = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_reviewers}`, payload, true);
};

export const getSbgs = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_sbgs}`, payload, true);
};

export const getBu = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_bu}`, payload, true);
};

export const getProjectStats = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_project_status}/${url}`,
    payload,
    true
  );
};

export const getProjectLimit = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_check_limit}/${payload.projectId}`,
    payload,
    true
  );
};

export const getVendorLocation = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_vendor_location}`, payload, true);
};

export const getCFO = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_cfo}`, payload, true);
};

export const getProjects = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_project}`, payload, true);
};

export const getVendors = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_vendor}`, payload, true);
};

export const getDummyData = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_dummy}`, payload, true);
};

export const deleteImages = (payload) => {
  return apiClient.delete(
    `${apiClient.Urls.delete_image}?loi_id=${payload[0].loi_id}`,
    payload,
    true
  );
};

export const deleteDrafts = (payload) => {
  return apiClient.delete(
    `${apiClient.Urls.delete_draft}/${payload.id}`,
    payload,
    true
  );
};
