import { useState } from 'react';

import { Image, Layout, Menu /* Space */ } from 'antd';

import styles from './index.module.less';

import tataLogo from './images/TataLogo.svg';

/* import Reports from './images/Reportssvg.js'; */
import UserMenu from './components/UserMenu';
import { history } from 'app/history';
import { Link, NavLink } from 'react-router-dom';

/* import Templates from './images/Templatessvg'; */
import Home from './images/Homesvg';

import All from './images/Allsvg';
import { getLoggedInUser } from 'utilities/helpers';
import {
  BackwardFilled,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  FileSyncOutlined,
  FileUnknownOutlined,
  ForwardOutlined,
  InteractionOutlined,
  ProjectOutlined,
  StopOutlined
} from '@ant-design/icons';
import LOI from './images/loisvg';
import { useSelector } from 'react-redux';
import Settings from './images/Settingssvg';

const { Header, Sider, Content } = Layout;

const CustomLayout = ({ children, sider = true, header = true }) => {
  const {
    pending_count,
    forwarded_count,
    waiting_for_others_count,
    all_counts_letters,
    all_count,
    completed_count,
    rejected_count,
    returned,
    forReview,
    draft_count,
    cancelled_count
  } = useSelector((state) => state.trackersList);
  const menuData = [
    {
      key: 'approver-dashboard',
      path: `/approver-dashboard`,

      icon: <All />,

      label: `All Letters (${
        all_counts_letters === undefined ? all_count : all_counts_letters
      })`
    },
    {
      key: 'pending',

      path: '/pending',

      icon: <InteractionOutlined />,

      label: `Pending (${
        pending_count === undefined ? all_count : pending_count
      })`
    },

    {
      key: 'approved',

      path: '/approved',

      icon: <CheckCircleOutlined />,

      label: `Approved LoA/LoIs (${
        completed_count === undefined ? all_count : completed_count
      })`
    },
    {
      key: 'rejected',

      path: '/rejected',

      icon: <CloseCircleOutlined />,

      label: `Rejected (${
        rejected_count === undefined ? all_count : rejected_count
      })`
    },
    {
      key: 'forward',

      path: '/forward',

      icon: <ForwardOutlined />,

      label: `Forwarded (${
        forwarded_count === undefined ? all_count : forwarded_count
      })`
    },
    {
      key: 'cancelled',

      path: '/cancelled',

      icon: <StopOutlined />,

      label: `Cancelled  (${
        cancelled_count === undefined ? all_count : cancelled_count
      })`
    }
  ];

  const reviewerMenu = [
    {
      key: 'approver-dashboard',
      path: `/approver-dashboard`,

      icon: <All />,

      label: `All Letters (${
        all_counts_letters === undefined ? all_count : all_counts_letters
      })`
    },
    {
      key: 'pending',

      path: '/pending',

      icon: <InteractionOutlined />,

      label: `Pending (${
        pending_count === undefined ? all_count : pending_count
      })`
    },
    {
      key: 'cancelled',

      path: '/cancelled',

      icon: <StopOutlined />,

      label: `Cancelled  (${
        cancelled_count === undefined ? all_count : cancelled_count
      })`
    }
  ];

  const trackermenuData = [
    {
      key: 'manage',

      path: '/manage',

      icon: <FileSyncOutlined />,

      label: `Initiated Letters (${
        all_counts_letters === undefined ? all_count : all_counts_letters
      })`
    },

    {
      key: 'waiting-for-others',

      path: '/waiting-for-others',

      icon: <InteractionOutlined />,

      label: `Under Approval (${
        waiting_for_others_count === undefined
          ? all_count
          : waiting_for_others_count
      })`
    },

    {
      key: 'for-review',

      path: '/for-review',

      icon: <ClockCircleOutlined />,

      label: `For Review (${forReview === undefined ? all_count : forReview})`
    },

    {
      key: 'requester-approved',

      path: '/requester-approved',

      icon: <CheckCircleOutlined />,

      label: `Approved LoA/LoIs (${
        completed_count === undefined ? all_count : completed_count
      })`
    },

    {
      key: 'requester-returned',

      path: '/requester-returned',

      icon: <BackwardFilled />,

      label: `Returned (${returned === undefined ? all_count : returned})`
    },
    {
      key: 'rejected',

      path: '/rejected',

      icon: <CloseCircleOutlined />,

      label: `Rejected  (${
        rejected_count === undefined ? all_count : rejected_count
      })`
    },

    {
      key: 'cancelled',

      path: '/cancelled',

      icon: <StopOutlined />,

      label: `Cancelled  (${
        cancelled_count === undefined ? all_count : cancelled_count
      })`
    },
    {
      key: 'drafts',

      path: '/drafts',

      icon: <FileUnknownOutlined />,

      label: `Drafts  (${draft_count === undefined ? all_count : draft_count})`
    }
  ];

  const adminTrackermenuData = [
    {
      key: 'admin-manage',

      path: '/admin-manage',

      icon: <FileSyncOutlined />,

      label: `Initiated Letters (${
        all_counts_letters === undefined ? all_count : all_counts_letters
      })`
    },

    {
      key: 'waiting-for-others',

      path: '/waiting-for-others',

      icon: <InteractionOutlined />,

      label: `Under Approval (${
        waiting_for_others_count === undefined
          ? all_count
          : waiting_for_others_count
      })`
    },

    {
      key: 'requester-approved',

      path: '/requester-approved',

      icon: <CheckCircleOutlined />,

      label: `Approved LoA/LoIs (${
        completed_count === undefined ? all_count : completed_count
      })`
    },

    {
      key: 'rejected',

      path: '/rejected',

      icon: <CloseCircleOutlined />,

      label: `Rejected  (${
        rejected_count === undefined ? all_count : rejected_count
      })`
    },

    {
      key: 'cancelled',

      path: '/cancelled',

      icon: <StopOutlined />,

      label: `Cancelled  (${
        cancelled_count === undefined ? all_count : cancelled_count
      })`
    }
  ];

  const userMenu = [
    {
      key: 'home',

      path: '/dashboard',

      icon: <Home />,

      label: 'Home'
    },

    {
      key: 'manage',

      path: '/manage',

      icon: <LOI />,

      label: 'Manage'
    },
    {
      key: 'reports',

      path: '/reports',

      icon: <ProjectOutlined className="font-18 mb-4" />,

      label: 'Reports'
    }
  ];

  const adminuserMenu = [
    {
      key: 'approver-dashboard',

      path: '/approver-dashboard',

      icon: <Home />,

      label: 'Home'
    },
    {
      key: 'reports',

      path: '/reports',

      icon: <ProjectOutlined className="font-18 mb-4" />,

      label: 'Reports'
    }
  ];

  const cfoMenu = [
    {
      key: 'CFO-dashboard',

      path: '/CFO-dashboard',

      icon: <Home />,

      label: 'Home'
    },
    {
      key: 'reports',

      path: '/reports',

      icon: <ProjectOutlined className="font-18 mb-4" />,

      label: 'Reports'
    }
  ];

  const adminMenu = [
    {
      key: 'admin-manage',

      path: '/admin-manage',

      icon: <Home />,

      label: 'Home'
    },
    {
      key: 'settings',

      path: '/settings',

      icon: <Settings className="font-18 mb-4" />,

      label: 'Settings'
    },
    {
      key: 'reports',

      path: '/reports',

      icon: <ProjectOutlined className="font-18 mb-4" />,

      label: 'Reports'
    }
  ];
  const CFO_SideBar = [
    {
      key: 'CFO-dashboard',
      path: `/CFO-dashboard`,

      icon: <All />,

      label: `All Letters (${
        all_counts_letters === undefined ? all_count : all_counts_letters
      })`
    },

    {
      key: 'pending',

      path: '/pending',

      icon: <InteractionOutlined />,

      label: `Pending (${
        pending_count === undefined ? all_count : pending_count
      })`
    },

    {
      key: 'approved',

      path: '/approved',

      icon: <CheckCircleOutlined />,

      label: `Approved (${
        completed_count === undefined ? all_count : completed_count
      })`
    },
    {
      key: 'rejected',

      path: '/rejected',

      icon: <CloseCircleOutlined />,

      label: `Rejected  (${
        rejected_count === undefined ? all_count : rejected_count
      })`
    },
    {
      key: 'forward',

      path: '/forward',

      icon: <ForwardOutlined />,

      label: `Forwarded  (${
        forwarded_count === undefined ? all_count : forwarded_count
      })`
    },
    {
      key: 'cancelled',

      path: '/cancelled',

      icon: <StopOutlined />,

      label: `Cancelled  (${
        cancelled_count === undefined ? all_count : cancelled_count
      })`
    }
  ];

  const user = getLoggedInUser();
  let role = user.user?.Role?.role;
  const headerbar =
    role === 'APPROVER'
      ? adminuserMenu
      : role === 'SBGHEAD'
      ? adminuserMenu
      : role === 'REVIEWER'
      ? adminuserMenu
      : role === 'REQUESTER'
      ? userMenu
      : role === 'ADMIN'
      ? adminMenu
      : cfoMenu;
  const [selectedMenuKeys] = useState(
    history.location.pathname.replace('/', '')
  );
  let trackerData =
    role === 'REQUESTER'
      ? trackermenuData
      : role === 'APPROVER'
      ? menuData
      : role === 'SBGHEAD'
      ? menuData
      : role === 'REVIEWER'
      ? reviewerMenu
      : role === 'ADMIN'
      ? adminTrackermenuData
      : CFO_SideBar;

  return (
    <Layout
      className={`${
        sider ? styles.custom_layout_container : styles.custom_layout_container1
      }`}
      hasSider={sider}
    >
      {sider ? (
        <Sider
          width={240}
          trigger={null}
          collapsible
          collapsed={false}
          className={styles.sider_container}
        >
          <div className={styles.sider_logo}>
            <h4 className="font-18">
              <b>LOI</b>
            </h4>
          </div>

          <Menu
            // mode="inline"
            defaultSelectedKeys={['users']}
            selectedKeys={[selectedMenuKeys]}
            className={styles.custom_sidebar_container}
          >
            {trackerData.map((item) => {
              return (
                <Menu.Item key={item.key} label={item.label}>
                  <NavLink to={item.path}>
                    <span className={`mr-2  ${styles.side_icon}`}>
                      {item.icon}
                    </span>
                    {item.label}
                  </NavLink>
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>
      ) : null}

      <Layout className={styles.site_layout}>
        {header ? (
          <Header className={`df-jb-ac ${styles.site_layout_header}`}>
            <div className="sfprotext-medium font-16">
              <Link
                to={
                  role === 'REQUESTER'
                    ? '/dashboard'
                    : role === 'CFO'
                    ? '/CFO-dashboard'
                    : '/approver-dashboard'
                }
              >
                <Image
                  className={`${styles.logo_header}`}
                  alt="TATA projects logo"
                  src={tataLogo}
                  preview={false}
                />
              </Link>
            </div>

            <Menu className={` df-jc-ac ${styles.menu}`} mode="horizontal">
              {headerbar.map((item) => {
                return (
                  <Menu.Item key={item.key}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? `${styles.active}` : `${styles.inactive}`
                      }
                      to={item.path}
                    >
                      <span className={` ${styles.icon}`}>
                        {item.icon} {item.label}
                      </span>
                    </NavLink>
                  </Menu.Item>
                );
              })}
            </Menu>
            <UserMenu />
          </Header>
        ) : null}

        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
