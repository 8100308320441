import React from 'react';

import CustomLayout from '@/common/Layout';
import CompletedPage from './components';
import { useSelector } from 'react-redux';

const ReqReturned = () => {
  const { all_count, completed_count } = useSelector(
    (state) => state.trackersList
  );
  return (
    <>
      <CustomLayout
        completed_count={completed_count}
        count={all_count}
        sider={true}
        tracker={true}
      >
        <CompletedPage count={all_count} />
      </CustomLayout>
    </>
  );
};

export default ReqReturned;
