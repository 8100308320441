import React from 'react';
import PdfViewerComponent from './components/index';

function PDFNextView({
  myParam,
  tempDocList,
  boq,
  delivery,
  supportingDoc,
  warranty,
  ld,
  dlp,
  freight,
  contractPerformance,
  mrdt,
  mobilisation,
  packing,
  payments,
  formValues
}) {
  const { message0 } = formValues;
  const { message3 } = formValues;
  return (
    <div>
      <PdfViewerComponent
        myParam={myParam}
        boq={boq}
        tempDocList={tempDocList}
        delivery={delivery}
        message3={message3}
        message0={message0}
        supportingDoc={supportingDoc}
        warranty={warranty}
        ld={ld}
        dlp={dlp}
        contractPerformance={contractPerformance}
        freight={freight}
        mrdt={mrdt}
        mobilisation={mobilisation}
        packing={packing}
        payments={payments}
        formValues={formValues}
      />
    </div>
  );
}

export default PDFNextView;
