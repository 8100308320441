import FormItem from '@/common/FormItem';
import {
  DeleteOutlined,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Form, Radio, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

import styles from '../../InitiateLOI1/index.module.less';

const UpdatedFreightInsurance = ({
  formValues,
  setFormValues,
  item,
  index,
  handleDeleteFreight,
  freightItem,
  freights,
  setFreight,
  freightIndex,
  defaultClauses,
  itemNumber
}) => {
  const handleChange = (type, value, inputName) => {
    const tempfreights = [...freights];
    tempfreights[freightIndex][type] = value;
    if (value === 'yes') {
      tempfreights[freightIndex].clauses = [
        defaultClauses?.freight?.defaultClause
      ];
    }
    setFreight(tempfreights);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
  };

  const handleClauseChange = (freightIndex, clauseIndex, val, inputName) => {
    const tempfreights = [...freights];
    tempfreights[freightIndex].clauses[clauseIndex] = val;
    //console.log(freightIndex, clauseIndex, tempfreights);
    setFreight(tempfreights);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempfreights = [...freights];
    tempfreights[freightIndex].clauses.push(
      defaultClauses?.freight?.defaultClause || ''
    );
    setFreight(tempfreights);
  };

  const handleDeleteClause = (clauseIndex) => {
    const tempfreights = [...freights];
    tempfreights[freightIndex].clauses.splice(clauseIndex, 1);
    //console.log('final contracts', tempfreights);
    setFreight(tempfreights);
  };

  const clauses = freightItem?.clauses || [];

  return (
    <>
      <Col className="font-18 sfprotext-bold mt-4 mb-2" span={22}>
        {` Freight & Insurance ${itemNumber}  `}
      </Col>
      <Row className={`${styles.grey_holder} w-100`}>
        <Col className="gutter-row ml-3" span={8}>
          <FormItem
            name={`freight_radio_${freightIndex}`}
            rules={[{ required: true, message: 'Please select' }]}
            label="Is Frieght and Insurance in vendor scope?"
          >
            <Radio.Group
              value={freightItem.freightVendorScope}
              onChange={(e) =>
                handleChange(
                  'freightVendorScope',
                  e.target.value,
                  `freight_radio_${freightIndex}`
                )
              }
            >
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </FormItem>
        </Col>{' '}
        <Col span={15}>
          {itemNumber > 1 ? (
            <div
              onClick={() => handleDeleteFreight(index, item)}
              className={` cursor-pointer ${styles.delete_holder}`}
            >
              <DeleteOutlined />
            </div>
          ) : null}
        </Col>
        {freightItem.freightVendorScope === 'yes' && (
          <>
            <Form.List
              name={`freight_${freightIndex}_clauses`}
              initialValue={freightItem.clauses}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, clauseIndex) => (
                    <Col
                      className="gutter-row  ml-3"
                      span={18}
                      key={clauseIndex}
                    >
                      <Form.Item required={false} key={field.key}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          noStyle
                          rules={[{ required: true, message: 'Please enter' }]}
                        >
                          <Row>
                            <Col span={22}>
                              <TextArea
                                defaultValue={
                                  clauses[clauseIndex]
                                  // ||
                                  // defaultClauses?.freight?.defaultClause
                                }
                                onChange={(e) =>
                                  handleClauseChange(
                                    freightIndex,
                                    clauseIndex,
                                    e.target.value,
                                    `freight_${freightIndex}clause${clauseIndex}`,
                                    fields
                                  )
                                }
                                autoSize
                                placeholder="Enter message"
                              />
                            </Col>
                            <Col span={2}>
                              {fields.length > 1 ? (
                                <span
                                  onClick={() => {
                                    remove(field.name);
                                    handleDeleteClause(clauseIndex);
                                  }}
                                  className={`  pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                                >
                                  <MinusCircleFilled
                                    style={{ color: '#D72D30' }}
                                  />
                                </span>
                              ) : null}
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  ))}
                  <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                    <Form.Item>
                      <Col className={`${styles.add_clause}  mb-3`} span={24}>
                        <span
                          onClick={() => {
                            add(defaultClauses?.freight?.defaultClause);
                            handleAddClause();
                          }}
                          className="cursor-pointer"
                        >
                          <PlusCircleFilled className="mr-1" /> Add clause
                        </span>
                      </Col>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
          </>
        )}
      </Row>
    </>
  );
};

export default UpdatedFreightInsurance;
