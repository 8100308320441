import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getDashboardStats } from './api';

const initialState = {
  loading: false,
  allStats: []
};

const actions = {
  GET_ALL_STATS: 'getAllStats/GET_ALL_STATS'
};

export const getAllStats = createAsyncThunk(
  actions.GET_ALL_STATS,
  async (payload) => {
    const response = await getDashboardStats(payload);
    return response;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllStats.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllStats.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allStats = data;
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllStats.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
  }
});
export default dashboardSlice.reducer;
