import FormItem from '@/common/FormItem';
import {
  CaretDownFilled,
  DeleteOutlined,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Form, InputNumber, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Option } from 'antd/lib/mentions';
import React from 'react';

import styles from '../../InitiateLOI1/index.module.less';

// import { MobilisationDefaultClause } from '../constants';

const UpdatedMobilisation = ({
  setFormValues,
  formValues,
  item,
  mobilisation,
  handleDeleteMobilisation,
  mobilityItem,
  setMobilisation,
  mobilityIndex,
  defaultClauses,
  itemNumber
}) => {
  const handleChange = (type, value, inputName) => {
    const tempMobilisation = [...mobilisation];
    tempMobilisation[mobilityIndex][type] = value;
    if (value === 'MOBApplicable') {
      tempMobilisation[mobilityIndex].clauses = [
        defaultClauses?.mobilisation?.defaultClause
      ];
    }
    setMobilisation(tempMobilisation);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
  };

  const handleClauseChange = (mobilityIndex, clauseIndex, val, inputName) => {
    const tempMobilisation = [...mobilisation];
    tempMobilisation[mobilityIndex].clauses[clauseIndex] = val;
    setMobilisation(tempMobilisation);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempMobilisation = [...mobilisation];
    tempMobilisation[mobilityIndex].clauses.push(
      defaultClauses?.mobilisation?.defaultClause
    );
    setMobilisation(tempMobilisation);
  };

  const handleDeleteClause = (clauseIndex) => {
    const tempMobilisation = [...mobilisation];
    tempMobilisation[mobilityIndex].clauses.splice(clauseIndex, 1);
    setMobilisation(tempMobilisation);
  };

  const clauses = mobilityItem?.clauses || [];
  //console.log(clauses);

  return (
    <>
      <Col className="font-18 sfprotext-bold mt-4 mb-2" span={22}>
        {` Mobilisation Time  ${itemNumber}`}
      </Col>
      <Row className={`${styles.grey_holder}  w-100`}>
        <Col className="gutter-row ml-3" span={6}>
          <FormItem
            label="Mobilisation Applicable"
            name={`mob_select_${mobilityIndex}`}
            rules={[{ required: true, message: 'Please select' }]}
          >
            <Select
              value={mobilityItem.mobilisationAppicable}
              onChange={(value) =>
                handleChange(
                  'mobilisationAppicable',
                  value,
                  `mob_select_${mobilityIndex}`
                )
              }
              suffixIcon={<CaretDownFilled />}
              placeholder="Select"
            >
              <Option value="MOBApplicable">Applicable</Option>
              <Option value="MOBNot Applicable">Not Applicable</Option>
            </Select>
          </FormItem>
        </Col>{' '}
        <Col span={17}>
          {itemNumber > 1 ? (
            <div
              onClick={() => handleDeleteMobilisation(mobilityIndex, item)}
              className={` cursor-pointer ${styles.delete_holder}`}
            >
              <DeleteOutlined />
            </div>
          ) : null}
        </Col>
        {mobilityItem.mobilisationAppicable === 'MOBApplicable' && (
          <>
            <Col className="gutter-row ml-3" span={24}>
              <Row gutter={[8, 8]}>
                <Col span={6}>
                  <FormItem
                    label="Claim Period"
                    name={`mob_claim_${mobilityIndex}`}
                    rules={[{ required: true, message: 'Please select' }]}
                  >
                    <InputNumber
                      onChange={(val) =>
                        handleChange(
                          'periodValue',
                          val,
                          `mob_claim_${mobilityIndex}`
                        )
                      }
                      value={mobilityItem?.periodValue}
                      className="w-100"
                      controls={false}
                      min={0}
                      placeholder="Enter value"
                    />
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem
                    name={`mob_${mobilityIndex}_claim_duration`}
                    rules={[
                      { required: true, message: 'Please select duration' }
                    ]}
                  >
                    <Select
                      value={mobilityItem.period}
                      onChange={(val) =>
                        handleChange(
                          'period',
                          val,
                          `mob_${mobilityIndex}_claim_duration`
                        )
                      }
                      showSearch
                      style={{ marginTop: '26px' }}
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Select duration"
                    >
                      <Option value="Days">Days</Option>
                      <Option value="Months">Months</Option>
                      <Option value="Years">Years</Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Form.List
              name={`mobility_${mobilityIndex}_clauses`}
              initialValue={mobilityItem.clauses}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, clauseIndex) => (
                    <Col
                      className="gutter-row  ml-3"
                      span={18}
                      key={clauseIndex}
                    >
                      <Form.Item required={false} key={field.key}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          noStyle
                          rules={[{ required: true, message: 'Please enter' }]}
                        >
                          <Row>
                            <Col span={22}>
                              <TextArea
                                defaultValue={
                                  clauses[clauseIndex]
                                  // ||
                                  // defaultClauses?.mobilisation?.defaultClause
                                }
                                onChange={(e) =>
                                  handleClauseChange(
                                    mobilityIndex,
                                    clauseIndex,
                                    e.target.value,
                                    `mob_${mobilityIndex}_clause_${clauseIndex}`,
                                    fields
                                  )
                                }
                                placeholder="Enter message"
                                autoSize
                              />
                            </Col>
                            <Col span={2}>
                              {fields.length > 1 ? (
                                <span
                                  onClick={() => {
                                    remove(field.name);
                                    handleDeleteClause(clauseIndex);
                                  }}
                                  className={`  pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                                >
                                  <MinusCircleFilled
                                    style={{ color: '#D72D30' }}
                                  />
                                </span>
                              ) : null}
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  ))}
                  <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                    <Form.Item>
                      <Col className={`${styles.add_clause}  mb-3`} span={24}>
                        <span
                          onClick={() => {
                            add(defaultClauses?.mobilisation?.defaultClause);
                            handleAddClause();
                          }}
                          className="cursor-pointer"
                        >
                          <PlusCircleFilled className="mr-1" /> Add clause
                        </span>
                      </Col>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
            {/* {clauses.map((cls, clauseIndex) => (
              <>
                <Col className="ml-3" span={18}>
                  <FormItem
                    rules={[{ required: true, message: 'Please enter' }]}
                    name={`mob_${mobilityIndex}_clause_${clauseIndex}`}
                  >
                    <TextArea
                      value={cls}
                      onChange={(e) =>
                        handleClauseChange(
                          mobilityIndex,
                          clauseIndex,
                          e.target.value,
                          `mob_${mobilityIndex}_clause_${clauseIndex}`
                        )
                      }
                      placeholder="Enter message"
                      defaultValue={defaultClauses?.mobilisation?.defaultClause}
                    />
                  </FormItem>
                </Col>
                {clauseIndex > 0 && (
                  <Col span={2}>
                    <div
                      onClick={() => handleDeleteClause(clauseIndex)}
                      className={`mt-4 pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                    >
                      <MinusCircleFilled style={{ color: '#D72D30' }} />
                    </div>
                  </Col>
                )}
              </>
            ))}
            <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
              <span onClick={handleAddClause} className="cursor-pointer">
                <PlusCircleFilled className="mr-1" /> Add clause
              </span>
            </Col> */}
          </>
        )}
      </Row>
    </>
  );
};

export default UpdatedMobilisation;
