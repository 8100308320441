import FormItem from '@/common/FormItem';
import {
  DeleteOutlined,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Form, InputNumber, Radio, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

import styles from '../../InitiateLOI1/index.module.less';
import styl from './index.module.less';

const UpdatedBoq = ({
  formValues,
  setFormValues,
  item,
  index,
  handleDeleteBoq,
  boqItem,
  boqs,
  setBoq,
  boqIndex,
  defaultClauses,
  itemNumber,
  clauseCount,
  setClauseCount
}) => {
  const handleChange = (type, value, inputName) => {
    const tempBoqs = [...boqs];
    tempBoqs[boqIndex][type] = value;

    if (value === 'manual' && clauseCount < 1) {
      tempBoqs[boqIndex].clauses = [
        {
          field1: undefined,
          field2: undefined,
          field3: undefined,
          field4: undefined,
          field5: undefined
        }
      ];
    } else if (value === 'no' && clauseCount < 1) {
      tempBoqs[boqIndex].clauses = [
        {
          field1: undefined,
          field2: undefined,
          field3: undefined,
          field4: undefined,
          field5: undefined
        }
      ];
    }

    setBoq(tempBoqs);
    console.log('tempBoqs', tempBoqs);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
    setClauseCount(
      tempBoqs[0]?.clauses?.length === 0
        ? tempBoqs[0]?.clauses?.length + 1
        : tempBoqs[0]?.clauses?.length
    );
  };

  const handleClauseChange = (boqIndex, clauseIndex, type, val, inputName) => {
    const tempBoqs = [...boqs];
    tempBoqs[boqIndex].clauses[clauseIndex][type] = val;
    setBoq(tempBoqs);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempBoqs = [...boqs];
    tempBoqs[boqIndex].clauses.push({
      field1: undefined,
      field2: undefined,
      field3: undefined,
      field4: undefined,
      field5: undefined
    });
    setBoq(tempBoqs);
    setClauseCount(
      tempBoqs[0]?.clauses?.length === 0
        ? tempBoqs[0]?.clauses?.length + 1
        : tempBoqs[0]?.clauses?.length
    );
  };

  const handleDeleteClause = (clauseIndex) => {
    console.log(clauseIndex);
    const tempBoqs = [...boqs];
    tempBoqs[boqIndex].clauses.splice(clauseIndex, 1);
    setBoq(tempBoqs);
    setClauseCount(tempBoqs[0]?.clauses?.length);
  };

  return (
    <>
      <Col className="font-18 sfprotext-bold mt-4 mb-2" span={22}>
        {`BOQ ${itemNumber}  `}
      </Col>
      <Row className={`${styles.grey_holder} w-100`}>
        <Col className="gutter-row ml-3" span={22}>
          <FormItem
            name={`boq_radio_${boqIndex}`}
            rules={[{ required: true, message: 'Please select' }]}
            label="How do you want to enter BoQ information?"
          >
            <Radio.Group
              value={boqItem.boqVendorScope}
              onChange={(e) =>
                handleChange(
                  'boqVendorScope',
                  e.target.value,
                  `boq_radio_${boqIndex}`
                )
              }
            >
              <Radio value="manual">I want to enter manually</Radio>
              <Radio value="no">I don’t want to enter</Radio>{' '}
            </Radio.Group>
          </FormItem>
        </Col>{' '}
        <Col span={1}>
          {itemNumber > 1 ? (
            <div
              onClick={() => handleDeleteBoq(index, item)}
              className={` cursor-pointer ${styles.delete_holder}`}
            >
              <DeleteOutlined />
            </div>
          ) : null}
        </Col>
        {boqItem.boqVendorScope === 'manual' && (
          <>
            <Form.List
              name={`boq_${boqIndex}_clauses`}
              initialValue={boqItem.clauses}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, clauseIndex) => (
                    <Col
                      className={`gutter-row  ml-3 ${styl.custom_form_item}`}
                      span={18}
                      key={clauseIndex}
                    >
                      <Row>
                        <Form.Item
                          className={`mr-3`}
                          rules={[
                            {
                              required: false,
                              message: 'Please enter percentage'
                            }
                          ]}
                          label={`Sr. No`}
                        >
                          <Col className="mt-2 " span={2}>
                            {clauseIndex + 1}
                          </Col>
                        </Form.Item>
                        <Col className="mr-1" span={4}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: 'Please enter description'
                              }
                            ]}
                            name={[field.name, 'field2']}
                            fieldKey={[field.fieldKey, 'field2']}
                            label={`Description`}
                          >
                            <TextArea
                              initialValue={undefined}
                              onChange={(e) =>
                                handleClauseChange(
                                  boqIndex,
                                  clauseIndex,
                                  'field2',
                                  e.target.value,
                                  `boq_description${boqIndex}clause${clauseIndex} `
                                )
                              }
                              autoSize
                              placeholder="Enter description"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="mr-1" span={4}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: 'Please enter unit'
                              }
                            ]}
                            name={[field.name, 'field1']}
                            fieldKey={[field.fieldKey, 'field1']}
                            label={`Unit`}
                          >
                            <TextArea
                              onChange={(e) =>
                                handleClauseChange(
                                  boqIndex,
                                  clauseIndex,
                                  'field1',
                                  e.target.value,
                                  `boq_unit${boqIndex}clause${clauseIndex} `
                                )
                              }
                              autoSize
                              placeholder="Enter unit"
                            />
                          </Form.Item>
                        </Col>

                        <Col className="mr-1" span={4}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: 'Please enter quantity'
                              }
                            ]}
                            name={[field.name, 'field3']}
                            fieldKey={[field.fieldKey, 'field3']}
                            label={`Quantity`}
                          >
                            <InputNumber
                              className="w-100"
                              onChange={(e) =>
                                handleClauseChange(
                                  boqIndex,
                                  clauseIndex,
                                  'field3',
                                  e,
                                  `boq_quantity${boqIndex}clause${clauseIndex} `
                                )
                              }
                              autoSize
                              placeholder="Enter quantity"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="mr-1" span={4}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: 'Please enter rate'
                              }
                            ]}
                            name={[field.name, 'field4']}
                            fieldKey={[field.fieldKey, 'field4']}
                            label={`Rate`}
                          >
                            <InputNumber
                              className="w-100"
                              onChange={(e) =>
                                handleClauseChange(
                                  boqIndex,
                                  clauseIndex,
                                  'field4',
                                  e,
                                  `boq_rate${boqIndex}clause${clauseIndex} `
                                )
                              }
                              autoSize
                              placeholder="Enter rate"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="mr-1" span={4}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: 'Please enter amount'
                              }
                            ]}
                            name={[field.name, 'field5']}
                            fieldKey={[field.fieldKey, 'field5']}
                            label={`Amount`}
                          >
                            <InputNumber
                              className="w-100"
                              onChange={(e) =>
                                handleClauseChange(
                                  boqIndex,
                                  clauseIndex,
                                  'field5',
                                  e,
                                  `boq_amount${boqIndex}clause${clauseIndex} `
                                )
                              }
                              autoSize
                              placeholder="Enter amount"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={1}>
                          {fields.length > 1 ? (
                            <span
                              onClick={() => {
                                remove(field.name);
                                handleDeleteClause(clauseIndex);
                              }}
                              className={` mt-4 pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                            >
                              <MinusCircleFilled style={{ color: '#D72D30' }} />
                            </span>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  ))}
                  <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                    <Form.Item>
                      {fields.length < 10 ? (
                        <Col className={`${styles.add_clause}  mb-3`} span={24}>
                          <span
                            onClick={() => {
                              add(defaultClauses?.freight?.defaultClause);
                              handleAddClause();
                            }}
                            className="cursor-pointer"
                          >
                            <PlusCircleFilled className="mr-1" /> Add Row
                          </span>
                        </Col>
                      ) : null}
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
          </>
        )}
      </Row>
    </>
  );
};

export default UpdatedBoq;
