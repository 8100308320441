import { Avatar, Dropdown, Menu, Typography } from 'antd';
import { CaretDownOutlined, LogoutOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { logout } from 'features/login/redux/slice';
import { useDispatch } from 'react-redux';

import { useMsal } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';

const { Text } = Typography;

const UserMenu = () => {
  const { instance } = useMsal();
  // const { accounts } = useMsal();
  const dispatch = useDispatch();
  const user = LocalStorage.getItem('LOI_user');

  const signOutClickHandler = () => {
    // //console.log(accounts);
    // instance.logoutPopup();
    // const logoutRequest = {
    //   account: instance.getAccountByHomeId('f6f942f3-20d3-48ec-a172-a7913183d9e5.a623a2c9-fed1-4cb4-b31f-ba65fbb95c00),
    //   mainWindowRedirectUri: 'your_app_main_window_redirect_uri',
    //   postLogoutRedirectUri: 'your_app_logout_redirect_uri'
    // };

    // const logoutRequest = {
    //   account:
    //     'f6f942f3-20d3-48ec-a172-a7913183d9e5.a623a2c9-fed1-4cb4-b31f-ba65fbb95c00',
    //   mainWindowRedirectUri: '/',
    //   postLogoutRedirectUri: '/'
    // };
    //    dispatch(logout());
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: () => {
        //console.log(url);
        // Return false if you would like to stop navigation after local logout
        dispatch(logout());
        return true;
      }
    });
    // logout_info.then((result) => {
    //   //console.log(result);
    //   dispatch(logout());
    // });
    // instance.logout();
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => signOutClickHandler()}
        key="1"
        icon={<LogoutOutlined />}
      >
        <Text>Logout</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown className={`${styles.icon_color}`} overlay={menu}>
      <div className={`df-jb-ac ${styles.user_menu_container}`}>
        <Avatar className={styles.user_menu_avatar}>
          {user?.user?.givenName.slice(0, 1)}
        </Avatar>
        <CaretDownOutlined />
      </div>
    </Dropdown>
  );
};

export default UserMenu;
