import React from 'react';

import CustomLayout from '@/common/Layout';
import ForwardPage from './components';

const Forwarded = () => {
  return (
    <>
      <CustomLayout sider={true} tracker={true}>
        <ForwardPage />
      </CustomLayout>
    </>
  );
};

export default Forwarded;
