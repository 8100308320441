import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb, Card, Col, Row } from 'antd';
import set1 from './images/Group 3041.svg';
// import set2 from '../images/set2.svg';
// import set3 from '../images/Group 31.svg';
// import set4 from '../images/Sbg.svg';
// import set5 from '../images/empSbg.svg';
import arrow from './images/Vector 9.svg';
import styles from './index.module.less';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomLayout from '@/common/Layout';

const SettingsPage = () => {
  const navigate = useNavigate();

  const nav = () => {
    navigate('/settings/projects');
  };

  const items = [
    {
      icon: set1,
      navig: nav,
      header: 'Configure Projects',
      subHeader: 'Configure Projects'
    }
  ];

  return (
    <CustomLayout sider={false}>
      <div className={`${styles.breadcrub_container}`}>
        <Breadcrumb separator={<RightOutlined />}>
          <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row className="mt-5">
        {items?.map((item, idx) => (
          <Col key={idx} span={24}>
            <Card
              onClick={item.navig}
              hoverable
              className={`mb-3 ${styles.settings_card}`}
            >
              <Row>
                <Col span={20}>
                  <Row>
                    <Col>
                      {' '}
                      <img src={item.icon} className="mr-2" />{' '}
                    </Col>

                    <Col span={7}>
                      <span className="sfprotext-bold font-14">
                        {item.header}
                      </span>
                      <br />
                      <span className="sfprotext-regular font-12">
                        {item.subHeader}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col className="df-jc-ac text-right" span={4}>
                  <img src={arrow} className="" />{' '}
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </CustomLayout>
  );
};

export default SettingsPage;
