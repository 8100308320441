import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { allTrackers } from './api';

const initialState = {
  all_waiting: []
};

const actions = {
  GETWAITINGLIST: 'getWaitingList/GETWAITINGLIST'
};

export const getWaitingList = createAsyncThunk(
  actions.GETWAITINGLIST,
  async () => {
    const response = await allTrackers();
    return response;
  }
);

export const getWaitingListSlice = createSlice({
  name: 'waitingList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWaitingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWaitingList.fulfilled, (state, action) => {
        const { data } = action.payload;
        //console.log('Rejected', data);
        state.all_waiting = data;
      })
      .addCase(getWaitingList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = getWaitingListSlice.actions; */
/* export const { resetTrackers } = getWaitingListSlice.actions; */

export default getWaitingListSlice.reducer;
