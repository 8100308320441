import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { editProject } from '@/features/settings/redux/slice';
//import { createProjects } from '@/features/settings/redux/slice';
// import { CaretDownFilled } from '@ant-design/icons';
import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Switch,
  message
} from 'antd';
import styles from './index.module.less';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CaretDownFilled } from '@ant-design/icons';

const EditModal = ({
  setBuName,
  buName,
  handleGetAllBu,
  dropDownLoading,
  onSearchBu,
  setSbuSearch,
  byIdLoading,
  // handleSbuSearchChange,
  // all_SBUs,
  setId,
  id,
  projectById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllDesignations
}) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(projectById?.isActive);
  const [form] = Form.useForm();

  const { allBu } = useSelector((state) => state.createLOI);

  useEffect(() => {
    handleGetAllBu();
    setBuName(projectById?.BuMaster?.buName);
  }, [buName]);

  useEffect(() => {
    console.log('HH', projectById);
    setId(projectById?.id);
    setIsActive(projectById?.isActive);
    setSbuSearch(projectById?.unit?.name);

    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      buId: projectById?.BuMasterId,
      isApproved: projectById?.isApproved,
      isActive: projectById?.isActive,
      projectName: projectById?.projectName,
      projectCode: projectById?.projectCode,
      projectValue: projectById?.value
    });
  }, [projectById, form]);

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('isActive', isActive);
    formData.append('projectName', values.projectName);
    formData.append('projectCode', values.projectCode);
    formData.append('projectValue', values.projectValue);
    formData.append('buId', values?.buId);
    const formDataObject = {
      url: id, // Change this to the appropriate URL
      payload: {
        buId: values?.buId,
        isApproved: values.isApproved,
        isActive: isActive,
        projectName: values.projectName,
        projectCode: values.projectCode,
        projectValue: Number(values.projectValue)
      }
    };

    dispatch(editProject(formDataObject)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllDesignations();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit Project"
    >
      {byIdLoading ? (
        <Skeleton />
      ) : (
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="mr-5" xs={24} xl={24} span={11}>
              <FormItem
                label="BU-JV Name"
                name="buId"
                rules={[
                  {
                    required: true,
                    message: 'Select BU-JV name'
                  }
                ]}
              >
                <Select
                  // onChange={onChangeBU}
                  onSearch={onSearchBu}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select BU-JV name"
                >
                  {dropDownLoading ? (
                    <Select.Option>
                      <Skeleton loading />
                    </Select.Option>
                  ) : (
                    allBu?.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.buName}
                      </Select.Option>
                    ))
                  )}
                </Select>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Project Name'}
                name="projectName"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <Input placeholder="Enter Project Name" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Project Code'}
                name="projectCode"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <Input placeholder="Enter Project Code" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Project Value'}
                name="projectValue"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <InputNumber
                  min={1}
                  className="w-100"
                  placeholder="Enter Project Value"
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Approved'}
                name="isApproved"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select'
                  }
                ]}
              >
                <Select placeholder="Select" suffixIcon={<CaretDownFilled />}>
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Active'}
                name="isActive"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select'
                  }
                ]}
              >
                <div
                  className={
                    isActive
                      ? `${styles.switcher_container}`
                      : `${styles.switcher_container1}`
                  }
                >
                  <Switch checked={isActive} onChange={handleSwitchChange} />
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      )}
    </CustomModal>
  );
};

export default EditModal;
