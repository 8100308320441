import LocalStorage from './localStorage';

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('LOI_user') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    loggedIn: loginUser !== ''
  };
};
