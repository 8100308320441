import Layout from '@/common/Layout';
import React from 'react';
import { useSelector } from 'react-redux';
import AdminManagePage from './components';

const AdminManage = () => {
  const { count } = useSelector((state) => state.trackersList);

  return (
    <>
      <Layout count={count} sider={true} tracker={true}>
        <AdminManagePage count={count} />
      </Layout>
    </>
  );
};

export default AdminManage;
