import apiClient from '../../../utilities/apiClient';

export const allLetters = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_req_all}`, payload, true);
};

export const history = (url) => {
  return apiClient.get(`${apiClient.Urls.get_history}/${url}`, '', true);
};

export const env_flow = (url) => {
  return apiClient.get(`${apiClient.Urls.get_env_flow}/${url}`, '', true);
};
