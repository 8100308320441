import CustomButton from '@/common/CustomButton';
import PDFNextView from '@/features/pdf-next';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Card, Col, Layout, Modal, Row, Spin, Tooltip } from 'antd';
import Close from 'assets/images/Closesvg';
import React from 'react';
import LocalStorage from 'utilities/localStorage';

import styles from './index.module.less';

const InitiateLOI2 = ({
  boq,
  delivery,
  supportingDoc,
  initiate,
  mrdt,
  handleFormSubmit,
  current,
  setCurrent,
  selectedApprovers,
  formValues,
  payments,
  packing,
  mobilisation,
  ld,
  dlp,
  freight,
  contractPerformance,
  warranty
  // message0
}) => {
  const checkLimit = LocalStorage.getItem('checkLimit');
  const handleOnclickPrev = () => {
    //console.log('Prev', current);
    setCurrent(current - 1);
  };
  //console.log('ES', selectedApprovers);
  //console.log('Warranty', warranty);
  //console.log(formValues);

  return (
    <>
      <Layout className={`${styles.layout}`}>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <div className={`df-jc-ac ${styles.x_button}`}>
              <Close />
            </div>
            <Col span={15}></Col>

            <Col className={`text-right `} span={8}>
              <CustomButton
                className={`mr-2`}
                onClick={() => handleOnclickPrev()}
              >
                {' '}
                <ArrowLeftOutlined /> Back to the LoI/LoA form
              </CustomButton>
              <CustomButton onClick={() => handleFormSubmit()} type="primary">
                Initiate
              </CustomButton>
            </Col>
          </Row>
        </div>
        <div className={`${styles.container}`}>
          <Row>
            <Col className={` mr-4 text-left `} span={17}>
              <Card
                className={` text-center ${styles.image_viewer_card}`}
                title={
                  <div className="df-jc-ac sfprotext-bold font-18">
                    Preview your document below
                  </div>
                }
                bordered={false}
              >
                <PDFNextView
                  // message0={message0}
                  boq={boq.filter((itm) => !itm.deleted)}
                  supportingDoc={supportingDoc}
                  warranty={warranty.filter((itm) => !itm.deleted)}
                  ld={ld.filter((itm) => !itm.deleted)}
                  dlp={dlp.filter((itm) => !itm.deleted)}
                  contractPerformance={contractPerformance.filter(
                    (itm) => !itm.deleted
                  )}
                  freight={freight.filter((itm) => !itm.deleted)}
                  mrdt={mrdt.filter((itm) => !itm.deleted)}
                  mobilisation={mobilisation.filter((itm) => !itm.deleted)}
                  packing={packing.filter((itm) => !itm.deleted)}
                  delivery={delivery.filter((itm) => !itm.deleted)}
                  payments={payments.filter((itm) => !itm.deleted)}
                  formValues={formValues}
                />
              </Card>
            </Col>

            <Col className={`ml-2 text-center`} span={6}>
              <Card className={` text-left ${styles.env_flow}`}>
                <p className="font-18">
                  <b>Envelope Flow</b>
                </p>
                <div className={`${styles.container1}`}>
                  {checkLimit?.isApproved === false &&
                  checkLimit?.isLimitExceeded === false &&
                  checkLimit?.isCbNull === false ? (
                    <>
                      <div className={`${styles.step} `}>
                        <div className={`${styles.v_stepper}`}>
                          <div className={`${styles.circle}`}>
                            <p>{1}</p>
                          </div>
                          <div className={`${styles.line}`}></div>
                        </div>
                        <div className={`${styles.content}`}>
                          <b className="font-14">
                            <Tooltip
                              placement="top"
                              title={selectedApprovers[0]?.approver}
                            >
                              {selectedApprovers[0]?.approver?.length > 30
                                ? selectedApprovers[0]?.approver?.substring(
                                    0,
                                    30
                                  ) + '...'
                                : selectedApprovers[0]?.approver}
                            </Tooltip>
                          </b>
                          <div className={`${styles.small_text} font-12`}>
                            Needs to sign
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {' '}
                      {selectedApprovers?.map((item, index) => (
                        <div className={`${styles.step} `} key={index}>
                          <div className={`${styles.v_stepper}`}>
                            <div className={`${styles.circle}`}>
                              <p>{index + 1}</p>
                            </div>
                            <div className={`${styles.line}`}></div>
                          </div>
                          <div className={`${styles.content}`}>
                            <b className="font-14">
                              <Tooltip placement="top" title={item.approver}>
                                {item.approver?.length > 30
                                  ? item.approver.substring(0, 30) + '...'
                                  : item.approver}
                              </Tooltip>
                            </b>
                            <div className={`${styles.small_text} font-12`}>
                              Needs to sign
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <br />
        </div>
        <Modal visible={initiate} footer={false} closable={false}>
          <p style={{ textAlign: 'center' }}>Sending the document</p>
          <p style={{ textAlign: 'center' }}>
            <Spin />
          </p>
        </Modal>
      </Layout>
    </>
  );
};

export default InitiateLOI2;
