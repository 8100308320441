import FormItem from '@/common/FormItem';
import {
  CaretDownFilled,
  DeleteOutlined,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Form, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Option } from 'antd/lib/mentions';
import React from 'react';

import styles from '../../InitiateLOI1/index.module.less';

const UpdatedWarranty = ({
  formValues,
  setFormValues,
  item,
  warranty,
  index,
  handleDeleteWarranty,
  warrantyItem,
  setWarranty,
  warrantyIndex,
  defaultClauses,
  itemNumber
}) => {
  const handleChange = (type, value, inputName) => {
    const tempWarranty = [...warranty];
    tempWarranty[warrantyIndex][type] = value;
    //console.log('warrenty change', warrantyIndex, tempWarranty);
    if (value === 'WarrantyApplicable') {
      tempWarranty[warrantyIndex].clauses = [
        defaultClauses?.warranty?.defaultClause
      ];
    }
    setWarranty(tempWarranty);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
  };

  const handleClauseChange = (warrantyIndex, clauseIndex, val, inputName) => {
    const tempWarranty = [...warranty];
    tempWarranty[warrantyIndex].clauses[clauseIndex] = val;
    //console.log(warrantyIndex, clauseIndex, tempWarranty);
    setWarranty(tempWarranty);
    setWarranty(tempWarranty);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempWarranty = [...warranty];
    tempWarranty[warrantyIndex].clauses.push(
      defaultClauses?.warranty?.defaultClause
    );
    setWarranty(tempWarranty);
  };

  const handleDeleteClause = (clauseIndex) => {
    const tempWarranty = [...warranty];
    tempWarranty[warrantyIndex].clauses.splice(clauseIndex, 1);
    //console.log('final contracts', tempWarranty);
    setWarranty(tempWarranty);
  };

  const clauses = warrantyItem?.clauses || [];
  return (
    <>
      <Col className="font-18 sfprotext-bold mt-4 mb-2" span={24}>
        {` Warranty Clause ${itemNumber}`}
      </Col>
      <Row className={`${styles.grey_holder} w-100`}>
        <Col className="gutter-row ml-3" span={6}>
          <FormItem
            label="Warranty "
            name={`warranty_select_${warrantyIndex}`}
            rules={[{ required: true, message: 'Please select' }]}
          >
            <Select
              value={warrantyItem.warrantyApplicable}
              onChange={(value) =>
                handleChange(
                  'warrantyApplicable',
                  value,
                  `warranty_select_${warrantyIndex}`
                )
              }
              suffixIcon={<CaretDownFilled />}
              placeholder="Select"
            >
              <Option value="WarrantyApplicable">Applicable</Option>
              <Option value="WarrantyNot Applicable">Not Applicable</Option>
            </Select>
          </FormItem>
        </Col>{' '}
        <Col span={17}>
          {itemNumber > 1 ? (
            <div
              onClick={() => handleDeleteWarranty(index, item)}
              className={` cursor-pointer ${styles.delete_holder}`}
            >
              <DeleteOutlined />
            </div>
          ) : null}
        </Col>
        {warrantyItem.warrantyApplicable === 'WarrantyApplicable' && (
          <>
            <Form.List
              name={`warranty_${warrantyIndex}_clauses`}
              initialValue={warrantyItem.clauses}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, clauseIndex) => (
                    <Col
                      className="gutter-row  ml-3"
                      span={18}
                      key={clauseIndex}
                    >
                      <Form.Item required={false} key={field.key}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          noStyle
                          rules={[{ required: true, message: 'Please enter' }]}
                        >
                          <Row>
                            <Col span={22}>
                              <TextArea
                                defaultValue={
                                  clauses[clauseIndex]
                                  // ||
                                  // defaultClauses?.warranty?.defaultClause
                                }
                                onChange={(e) =>
                                  handleClauseChange(
                                    warrantyIndex,
                                    clauseIndex,
                                    e.target.value,
                                    `warranty_${warrantyIndex}clause${clauseIndex}`,
                                    fields
                                  )
                                }
                                autoSize
                              />
                            </Col>
                            <Col span={2}>
                              {fields.length > 1 ? (
                                <span
                                  onClick={() => {
                                    remove(field.name);
                                    handleDeleteClause(clauseIndex);
                                  }}
                                  className={`  pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                                >
                                  <MinusCircleFilled
                                    style={{ color: '#D72D30' }}
                                  />
                                </span>
                              ) : null}
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  ))}
                  <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                    <Form.Item>
                      <Col className={`${styles.add_clause}  mb-3`} span={24}>
                        <span
                          onClick={() => {
                            add(defaultClauses?.warranty?.defaultClause);
                            handleAddClause();
                          }}
                          className="cursor-pointer"
                        >
                          <PlusCircleFilled className="mr-1" /> Add clause
                        </span>
                      </Col>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
            {/* {clauses.map((cls, clauseIndex) => (
              <>
                <Col className="gutter-row ml-3" span={18}>
                  <FormItem
                    rules={[{ required: true, message: 'Please enter' }]}
                    name={`warranty_${warrantyIndex}_clause_${clauseIndex}`}
                  >
                    <div>
                      <TextArea
                        value={cls}
                        onChange={(e) =>
                          handleClauseChange(
                            warrantyIndex,
                            clauseIndex,
                            e.target.value,
                            `warranty_${warrantyIndex}_clause_${clauseIndex}`
                          )
                        }
                        autoSize
                        defaultValue={
                          defaultClauses?.warranty?.defaultClause || ''
                        }
                      />
                    </div>
                  </FormItem>
                </Col>
                {clauseIndex > 0 && (
                  <Col span={2}>
                    <div
                      onClick={() => handleDeleteClause(clauseIndex)}
                      className={` pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                    >
                      <MinusCircleFilled style={{ color: '#D72D30' }} />
                    </div>
                  </Col>
                )}
              </>
            ))}

            <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
              <span onClick={handleAddClause} className="cursor-pointer">
                <PlusCircleFilled className="mr-1" /> Add clause
              </span>
            </Col> */}
          </>
        )}
      </Row>
    </>
  );
};

export default UpdatedWarranty;
