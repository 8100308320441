import PDFVIEW from '@/features/pdf-view';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  StarOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  Alert,
  Card,
  Col,
  Form,
  Layout,
  Row,
  Skeleton,
  Spin,
  Tooltip
} from 'antd';
import React from 'react';
import styles from './index.module.less';
import moment from 'moment';
import MessageIcon from '../images/shape.svg';
import CustomButton from '@/common/CustomButton';
import ApproveModal from './ApproveModal';
import ForwardModal from './ForwardModal';
import RejectModal from './RejectModal';
import ReviewModal from './ReviewModal';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import TextArea from 'antd/lib/input/TextArea';

const ApproverReviewPage = ({
  cancelModalVisible,
  setCancelModalVisible,
  reviewerName,
  allReviewers,
  setReviewerName,
  userDetails,
  handleGetProjectAmountById,
  all_project_amount,
  envelope_flow,
  // history_data,
  all_file_data,
  setModalVisible,
  allApprovers,
  sentotp,
  modalVisible,
  isApproveModalVisible,
  isForwardModalVisible,
  isRejectModalVisible,
  handleApproveCancel,
  handleForwardCancel,
  handleRejectCancel,
  onFinish,
  otp,
  loading,
  isLoading,
  onClickCancel
}) => {
  const onOpenCancel = () => {
    setCancelModalVisible(true);
  };

  const onCloseCancel = () => {
    setCancelModalVisible(false);
  };

  const [form] = Form.useForm();
  console.log(all_file_data);
  const Header = () => {
    return isLoading ? (
      <Skeleton />
    ) : (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <Col span={15}>
              <div className={`ml-3 sfprotext-bold font-18 mb-3 `}>
                Please review and sign the documents
              </div>

              <div className={`ml-3 sfprotext-bold font-16`}>
                {' '}
                {userDetails?.user?.email}
              </div>
              <div className={`ml-3 font-14`}></div>
              <div className={`ml-3 sfprotext-medium font-16`}>
                Hi{' '}
                {userDetails?.user?.givenName?.split(' ').slice(0, 1).join(' ')}
                !{' '}
              </div>
            </Col>

            {all_file_data?.status === 'Draft' && (
              <Col className={`text-right`} span={9}>
                {' '}
                <CustomButton
                  type="primary"
                  className={`mr-2`}
                  href={`${process.env.REACT_APP_API_URL}/fetch-file?fileName=${all_file_data?.Document?.fileName}`}
                  download
                >
                  Download
                </CustomButton>
              </Col>
            )}

            {userDetails?.user?.Role?.role === 'ADMIN' &&
            all_file_data?.status !== 'Cancelled' ? (
              <Col className={`text-right`} span={9}>
                {' '}
                <CustomButton
                  onClick={onOpenCancel}
                  type="primary"
                  className={`mr-2`}
                >
                  {' '}
                  Cancel LOI
                </CustomButton>
              </Col>
            ) : null}

            {(userDetails?.user?.Role?.role === 'ADMIN' &&
              all_file_data?.status !== 'Rejected') ||
            all_file_data?.status !== 'Cancelled'
              ? all_file_data?.Approvers?.map((item, index) =>
                  item?.email === userDetails?.user?.email &&
                  item.sign_in === true &&
                  item?.is_forward === false ? (
                    <Col className={`text-right`} span={9} key={index}>
                      <CustomButton
                        className={`mr-2`}
                        onClick={() => setModalVisible('forward')}
                      >
                        {' '}
                        Forward
                      </CustomButton>
                      <CustomButton
                        className={`mr-2`}
                        onClick={() => setModalVisible('reject')}
                      >
                        {' '}
                        Reject
                      </CustomButton>
                      <CustomButton type="primary" onClick={sentotp}>
                        Approve
                      </CustomButton>
                    </Col>
                  ) : item?.status === 'Needs to review' &&
                    item?.email === userDetails?.user?.email ? (
                    <>
                      <Col className={`text-right`} span={9} key={index}>
                        <CustomButton
                          className={`mr-2`}
                          onClick={() => setModalVisible('review')}
                        >
                          Review
                        </CustomButton>
                      </Col>
                    </>
                  ) : (
                    ''
                  )
                )
              : ''}
          </Row>
        </div>
      </>
    );
  };

  return (
    <div>
      {isLoading ? (
        <div style={{ height: '60vh' }} className="df-jc-ac">
          <Spin />
        </div>
      ) : (
        <>
          <Layout className={`${styles.layout}`}>
            <Header />
            <div className={`${styles.container}`}>
              <Row>
                <Col className={` mr-4 text-left `} span={17}>
                  <Card
                    hoverable
                    className={` text-center ${styles.image_viewer_card}`}
                    title={
                      <div className="df-jc-ac sfprotext-bold font-18">
                        Preview your document below
                      </div>
                    }
                    bordered={false}
                  >
                    <PDFVIEW />
                  </Card>
                  <Card
                    hoverable
                    className={`mt-3 text-center ${styles.image_viewer_card}`}
                    bordered={false}
                  >
                    <div className={`${styles.att_bg}`}>
                      <div className={`ml-5 text-left sfprotext-bold font-18`}>
                        Attachments:
                      </div>
                      {all_file_data.Document?.Attachments?.length === 0 ? (
                        <div className="ml-5 text-center">
                          {' '}
                          No attachments to display
                        </div>
                      ) : (
                        <ol className="ml-5 text-left">
                          {all_file_data?.Document?.Attachments?.map(
                            (item, index) => (
                              <li key={index}>
                                {' '}
                                <a
                                  rel="noreferrer"
                                  // href={item?.fileUrl}
                                  href={`${process.env.REACT_APP_API_URL}/fetch-file?fileName=${item?.fileName}`}
                                  // download
                                  target={'_blank'}
                                  download
                                >
                                  Attachment {index + 1}
                                </a>
                              </li>
                            )
                          )}
                        </ol>
                      )}
                    </div>
                    {all_file_data?.status === 'Cancelled' && (
                      <>
                        <Alert
                          type="error"
                          className="mt-4 text-left"
                          message={
                            <b className="font-16 sfprotext-bold">
                              Cancelled Remarks:
                            </b>
                          }
                          description={
                            <Tooltip
                              placement="top"
                              title={all_file_data?.cancelRemarks}
                            >
                              {all_file_data?.cancelRemarks?.length > 200
                                ? all_file_data?.cancelRemarks?.substring(
                                    0,
                                    200
                                  ) + '...'
                                : all_file_data?.cancelRemarks}
                            </Tooltip>
                          }
                          // type="info"
                          showIcon
                        />
                      </>
                    )}
                  </Card>
                </Col>

                <Col className={`ml-2 text-center`} span={6}>
                  <Card hoverable className={` text-left ${styles.env_flow}`}>
                    <p className="font-18">
                      <b>Envelope Flow</b>
                    </p>
                    <div className={`${styles.container1}`}>
                      {envelope_flow?.map((item, index) => (
                        <div className={`${styles.step} `} key={index}>
                          <div className={`${styles.v_stepper}`}>
                            <div className={`${styles.circle}`}>
                              <p>{index + 1}</p>
                            </div>
                            <div className={`${styles.line}`}></div>
                          </div>

                          <div className={`${styles.content}`}>
                            <b className="font-12">
                              <Tooltip placement="top" title={item.name}>
                                {item?.name?.length > 30
                                  ? item?.name?.substring(0, 30) + '...'
                                  : item?.name}
                              </Tooltip>
                            </b>
                            <div className={`${styles.small_text} font-12`}>
                              {item.status ? (
                                <>
                                  <span
                                    style={{
                                      color:
                                        item.status === 'Initiated'
                                          ? 'blue'
                                          : item.status === 'Signed'
                                          ? 'green'
                                          : item.status === 'Rejected'
                                          ? 'red'
                                          : item.status === 'Hold'
                                          ? 'orange'
                                          : item.status === 'Forwarded'
                                          ? 'blue'
                                          : item.status === 'Initiated'
                                          ? 'orange'
                                          : item.status === 'Verified'
                                          ? 'green'
                                          : '',
                                      fontSize: '11px',
                                      fontWeight: '400'
                                    }}
                                  >
                                    {item.status === 'Signed' ? (
                                      <CheckOutlined />
                                    ) : item.status === 'Rejected' ? (
                                      <CloseOutlined />
                                    ) : item.status === 'Hold' ? (
                                      <WarningOutlined />
                                    ) : item.status === 'Forwarded' ? (
                                      <DoubleRightOutlined />
                                    ) : item.status === 'Returned' ? (
                                      <DoubleLeftOutlined />
                                    ) : item.status === 'Initiated' ? (
                                      <StarOutlined />
                                    ) : item.status === 'Verified' ? (
                                      <CheckCircleOutlined />
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                  <span style={{ marginLeft: '4px' }}>
                                    {item.status === 'Initiated' &&
                                    item.is_forward
                                      ? 'Initiated'
                                      : item.status === 'Accepted' &&
                                        item.is_forward
                                      ? 'Reviewed'
                                      : item.status === 'Signed' &&
                                        !item.is_forward
                                      ? 'Signed'
                                      : item.status === 'Hold'
                                      ? 'Need Clarification'
                                      : item.status}
                                    {` on ${moment(item.updatedAt).format(
                                      'DD MMM YY, h:mm A'
                                    )} `}
                                  </span>
                                  {item.comments !== '' &&
                                  item.comments !== null &&
                                  item.comments !== '' &&
                                  item.comments !== null ? (
                                    <>
                                      <br />
                                      <span>
                                        <img src={MessageIcon} />
                                      </span>
                                      <span style={{ marginLeft: '4px' }}>
                                        <Tooltip
                                          placement="top"
                                          title={item?.comments}
                                        >
                                          {item?.comments?.length > 40
                                            ? item?.comments.substring(0, 40) +
                                              '...'
                                            : item?.comments}
                                        </Tooltip>
                                      </span>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                'Needs to sign'
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {userDetails?.user?.Role?.role !== 'REQUESTER' ? (
                      <Alert
                        className="mt-4"
                        message={<b className="font-14">Price Justification</b>}
                        description={
                          <Tooltip
                            placement="top"
                            title={
                              all_file_data?.priceJustificationAndSelectionOfVendors
                            }
                          >
                            {all_file_data
                              ?.priceJustificationAndSelectionOfVendors
                              ?.length > 200
                              ? all_file_data?.priceJustificationAndSelectionOfVendors?.substring(
                                  0,
                                  200
                                ) + '...'
                              : all_file_data?.priceJustificationAndSelectionOfVendors}
                          </Tooltip>
                        }
                        type="info"
                        showIcon
                      />
                    ) : null}
                  </Card>
                </Col>
              </Row>
            </div>
            {modalVisible === 'approved' ? (
              <ApproveModal
                handleGetProjectAmountById={handleGetProjectAmountById}
                all_project_amount={all_project_amount}
                handleApproveCancel={handleApproveCancel}
                isApproveModalVisible={isApproveModalVisible}
                loading={loading}
                otp={otp}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                email={userDetails?.user?.email}
              />
            ) : null}
            {modalVisible === 'forward' ? (
              <ForwardModal
                reviewerName={reviewerName}
                allReviewers={allReviewers}
                setReviewerName={setReviewerName}
                allApprovers={allApprovers}
                handleForwardCancel={handleForwardCancel}
                isForwardModalVisible={isForwardModalVisible}
                onFinish={onFinish}
                loading={loading}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                all_file_data={all_file_data}
                email={userDetails?.user?.email}
              />
            ) : null}
            {modalVisible === 'reject' ? (
              <RejectModal
                allApprovers={allApprovers}
                all_file_data={all_file_data}
                handleRejectCancel={handleRejectCancel}
                isRejectModalVisible={isRejectModalVisible}
                onFinish={onFinish}
                loading={loading}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                email={userDetails?.user?.email}
              />
            ) : null}
            {modalVisible === 'review' ? (
              <ReviewModal
                allApprovers={allApprovers}
                all_file_data={all_file_data}
                handleRejectCancel={handleRejectCancel}
                isRejectModalVisible={isRejectModalVisible}
                onFinish={onFinish}
                loading={loading}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                email={userDetails?.user?.email}
              />
            ) : null}

            {cancelModalVisible ? (
              <CustomModal
                visible={cancelModalVisible}
                className={styles.background}
                title={'Cancel the LoI/LoA'}
                onCancel={onCloseCancel}
                width={'45%'}
                footer={null}
              >
                <div className="">
                  <Form
                    form={form}
                    layout="vertical"
                    className="ant-form ant-form-vertical"
                    onFinish={onClickCancel}
                  >
                    <FormItem
                      label="Remarks"
                      name="remarks"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter remarks'
                        }
                      ]}
                    >
                      <TextArea
                        style={{ width: '100%' }}
                        placeholder="Add any remarks before you cancel the letter"
                        rows={4}
                        allowClear
                      />
                    </FormItem>

                    <FormItem className="mb-1 text-right">
                      <CustomButton
                        className="mr-2"
                        htmlType="button"
                        onClick={onCloseCancel}
                      >
                        Back
                      </CustomButton>
                      <CustomButton
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Cancel LOI
                      </CustomButton>
                    </FormItem>
                  </Form>
                </div>
              </CustomModal>
            ) : null}
          </Layout>
        </>
      )}
    </div>
  );
};

export default ApproverReviewPage;
