import Header from '@/common/Content/header';
import SearchBar from '@/common/Content/searchBar';
import CustomButton from '@/common/CustomButton';
import { getHistoryList } from '@/features/requester-manage/redux/slice';
import {
  CheckOutlined,
  DownOutlined,
  MessageOutlined,
  StopOutlined
} from '@ant-design/icons';
import {
  Badge,
  Col,
  Dropdown,
  Empty,
  Form,
  InputNumber,
  Menu,
  message,
  Modal,
  Popover,
  Progress,
  Row,
  Space,
  Table,
  Timeline,
  Tooltip
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.less';
import moment from 'moment';
import { getTrackersList } from '@/features/pending/redux/slice';
import { shareVendor } from '../redux/slice';
import FormItem from '@/common/FormItem';
import { getfilebyid } from '@/features/approver-review/redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
import { Link } from 'react-router-dom';

const CompletedPage = () => {
  const user = getLoggedInUser().user?.Role?.role;
  const { all_file_data } = useSelector((state) => state.reviewActions);
  const [form] = Form.useForm();
  const [letterId, setLetterId] = useState('');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [showPOModal, setShowPOModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeclinedModal, setDeclinedModal] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const current_status =
    window.location.pathname.replace('/', '') === 'requester-approved'
      ? 'completed'
      : window.location.pathname.replace('/', '');
  const gcd = function (a, b) {
    if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

    return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
  };

  const onFinishVendor = () => {
    // let formData = new FormData();
    let payload = { shared_with_vendor: 'yes', id: letterId };

    //
    dispatch(shareVendor(payload)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
      } else {
        message.error(response.payload.message);
      }
    });
    setShowVendorModal('');
  };

  const onFinishPO = (values) => {
    let payload = { po_wo: 'yes', id: letterId, po_wo_number: values.poNumber };
    form.setFieldsValue({ poNumber: undefined });
    /*  ; */

    dispatch(shareVendor(payload)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        form.setFieldsValue({ poNumber: undefined });
      } else {
        message.error(response.payload.message);
        form.setFieldsValue({ poNumber: undefined });
      }
    });
    setShowPOModal(false);
  };

  const onFinishConfirmation = () => {
    let payload = { confirmed_by_vendor: 'yes', id: letterId };

    dispatch(shareVendor(payload)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
      } else {
        message.error(response.payload.message);
      }
    });
    setShowConfirmationModal('');
  };
  // const final = Math.floor(5) + '/' + Math.floor(5);

  const onFinishDecline = () => {
    let payload = { declined_by_vendor: 'yes', id: letterId };

    dispatch(shareVendor(payload)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
      } else {
        message.error(response.payload.message);
      }
    });
    setDeclinedModal('');
  };

  const menu = (id) => (
    <Menu
      items={[
        {
          label: <div onClick={() => handleHistory(id)}>View audit trail</div>,
          key: '0'
        },
        {
          label: (
            <div onClick={() => handleVendorModal(id)}>Share with vendor</div>
          ),
          key: '1'
        },
        {
          label: (
            <div onClick={() => handleConfirmationModal(id)}>
              Mark as confirmed
            </div>
          ),
          key: '2'
        },
        {
          label: (
            <div onClick={() => handlePOModal(id)}>Mark as PO/WO issued</div>
          ),
          key: '3'
        },
        {
          label: (
            <div onClick={() => handleDeclinedModal(id)}>Decline by vendor</div>
          ),
          key: '4'
        },
        user === 'REQUESTER'
          ? {
              label: (
                <Link to={`/drafts/draft-edit/${id}?mode=clone`}>Clone</Link>
              ),
              key: '5'
            }
          : null
      ]}
    />
  );

  const adminMenu = (id) => (
    <Menu
      items={[
        {
          label: <div onClick={() => handleHistory(id)}>View audit trail</div>,
          key: '0'
        }
      ]}
    />
  );

  const columns = [
    {
      title: '',
      dataIndex: 'icons',
      render: () => {
        return (
          <>
            <div className={`${styles.table_icon}`}>{<CheckOutlined />}</div>
          </>
        );
      }
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: '45%',
      render: (subject, record) => (
        <>
          <div className="mb-1">
            <b className="font-14 ">
              <a href={`/review-actions/${record.id}`} target="__blank">
                {subject.title}
                {`# ${record.loiOrLoa}-${record.loiId}`}
                {record?.projectName ? `${' || '}${record?.projectName}` : ''}
              </a>
            </b>
          </div>
          <div>
            To:{' '}
            {subject.Approvers.length > 2
              ? subject.Approvers.slice(0, 2).map((item) => item?.name).join`, `
              : subject.Approvers.map((item) => item?.name).join`, `}
            {'\u00a0'}
            <Popover
              content={
                <>
                  <h2 className="sfprotext-bold">Approvers</h2>
                  {subject.Approvers.map((item, index) => (
                    <p key={index}>{item?.name}</p>
                  ))}
                </>
              }
              placement="rightTop"
            >
              {subject.Approvers.length > 2 ? (
                <Badge
                  className={`site-badge-count-109 ${styles.badge}`}
                  count={`+ ${subject.Approvers.length - 2}`}
                />
              ) : (
                ''
              )}
            </Popover>
          </div>
        </>
      )
    },
    {
      title: 'Status',
      key: 'description',
      dataIndex: 'description',
      width: '30%',
      render: (description) => (
        <>
          <Row>
            <Col span={18}>
              <div className="mr-5 mb-1">
                <h1>
                  <Progress
                    size="small"
                    // success={{ percent: 30 }}
                    percent={`${Math.floor(
                      (description.actionTakenApprovers /
                        description.totalApprovers) *
                        100
                    )}`}
                    strokeColor={'#1CB697'}
                    status="active"
                    format={gcd}
                    showInfo={false}
                  />
                </h1>
                <div>
                  {description.approverStatus
                    ? description.approverStatus
                    : description.status}
                </div>
              </div>
            </Col>
            <Col
              span={6}
              style={{
                marginLeft: '-40px',
                fontSize: '13px',
                marginTop: '3px'
              }}
            >{`${description.actionTakenApprovers}/${description.totalApprovers} done`}</Col>
          </Row>
        </>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (text, record) => {
        return (
          <>
            {window.location.pathname.replace('/', '') === 'completed' ? (
              <CustomButton className={`${styles.use_button} mr-2`}>
                <a
                  href={`${process.env.REACT_APP_API_URL}/fetch-file?fileName=${record?.Document?.fileName}`}
                  download
                  target="__blank"
                >
                  Download
                </a>
              </CustomButton>
            ) : window.location.pathname.replace('/', '') === 'pending' ? (
              <CustomButton className={`${styles.use_button} mr-2`}>
                <a
                  href={`${process.env.REACT_APP_API_URL}/fetch-file?fileName=${record?.Document?.fileName}`}
                  target="__blank"
                  download
                >
                  View
                </a>
              </CustomButton>
            ) : record.status === 'Completed' ? (
              <CustomButton className={`${styles.use_button} mr-2`}>
                <a
                  href={`${process.env.REACT_APP_API_URL}/fetch-file?fileName=${record?.Document?.fileName}`}
                  download
                  target="__blank"
                >
                  Download
                </a>
              </CustomButton>
            ) : (
              <CustomButton className={`${styles.use_button} mr-2`}>
                <a
                  href={`${process.env.REACT_APP_API_URL}/fetch-file?fileName=${record?.Document?.fileName}`}
                  download
                  target="__blank"
                >
                  Download
                </a>
              </CustomButton>
            )}

            <CustomButton className={`${styles.down_button}`}>
              <Dropdown
                overlay={
                  user === 'ADMIN' ? adminMenu(record?.id) : menu(record.id)
                }
                trigger={['click']}
              >
                <a onClick={(e) => e.preventDefault(e)}>
                  <Space>
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </CustomButton>
          </>
        );
      }
    }
  ];

  const { all_trackers, all_count } = useSelector(
    (state) => state.trackersList
  );
  const { history_data } = useSelector((state) => state.adminManage);

  const data = all_trackers?.map((item) => {
    const {
      approverStatus,
      Approvers,
      loiId,
      loiOrLoa,
      status,
      totalApprovers,
      actionTakenApprovers
    } = item;

    return {
      ...item,
      subject: {
        Approvers,
        loiOrLoa,
        loiId
      },
      description: {
        approverStatus,
        status,
        totalApprovers,
        actionTakenApprovers
      }
    };
  });

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
  }, [search, page]);

  const handleGetStats = () => {
    let payload = search !== '' ? { search: search } : { page: page };
    current_status !== 'manage' ? (payload['status'] = current_status) : '';
    dispatch(getTrackersList(payload));
  };

  const handleHistory = (id) => {
    setShowHistoryModal(true);
    dispatch(getHistoryList(id));
  };

  let locale = {
    emptyText: (
      <div>
        <Empty description={`No LoI/LoAs to display`} />
      </div>
    )
  };

  const handleVendorModal = (id) => {
    setShowVendorModal(true);
    setLetterId(id);
  };

  const handlePOModal = (id) => {
    dispatch(getfilebyid(id));
    //console.log(all_file_data);
    //console.log();
    setShowPOModal(true);
    setLetterId(id);
  };

  const handlePOCancel = () => {
    setShowPOModal(false);
    form.setFieldsValue({
      poNumber: undefined
    });
  };

  const handleConfirmationModal = (id) => {
    setShowConfirmationModal(true);
    setLetterId(id);
  };

  const handleDeclinedModal = (id) => {
    dispatch(getfilebyid(id));
    setDeclinedModal(true);
    setLetterId(id);
  };

  return (
    <>
      <div className={`mb-4 ${styles.MRS_container}`}>
        <Header
          justify="space-between"
          align="middle"
          title={`Approved LoA/LoIs`}
          buttons={[]}
        />
        <div>
          <SearchBar
            justify="start"
            align="middle"
            className="mt-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search LoI/LoA by Project Name'
            }}
            filters={false}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div>
        <Table
          locale={locale}
          rowKey={(record) => record.id}
          className={`${styles.custom_table}`}
          columns={columns}
          dataSource={data}
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            showSizeChanger: false,

            total: all_count
          }}
        />
        {showHistoryModal ? (
          <Modal
            title="Audit Trail"
            visible={showHistoryModal}
            onCancel={() => setShowHistoryModal(false)}
            footer={false}
          >
            <Timeline>
              {history_data.map((item, index) => {
                return (
                  <Timeline.Item
                    key={index}
                    color={
                      item.status === 'Rejected'
                        ? 'red'
                        : item.status === 'Signed'
                        ? 'green'
                        : item.status === 'Verified'
                        ? 'green'
                        : item.status === 'Initiated'
                        ? 'orange'
                        : 'blue'
                    }
                  >
                    {item.name}
                    {item.status !== '' ? (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === 'Signed'
                            ? 'Signed'
                            : item.status === 'Rejected'
                            ? 'Rejected'
                            : item.status === ''
                            ? `Needs to sign on ${moment(
                                history_data[0].createdAt
                              ).format('DD MMM YY, h:mm A')}`
                            : item.status
                        } on ${moment(item.updatedAt).format(
                          'DD MMM YY, h:mm A'
                        )}`}</span>
                      </div>
                    ) : (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === ''
                            ? `Needs to sign on ${moment(
                                history_data[0].createdAt
                              ).format('DD MMM YY, h:mm A')}`
                            : item.status
                        }`}</span>
                      </div>
                    )}
                    {item.comments !== '' && item.comments !== null ? (
                      <>
                        <div className={`${styles.content_text}`}>
                          <span style={{ fontSize: '9px' }}>
                            <MessageOutlined />
                          </span>
                          <span style={{ marginLeft: '4px', fontSize: '8px' }}>
                            <Tooltip placement="top" title={item?.comments}>
                              {item?.comments?.length > 40
                                ? item?.comments.substring(0, 40) + '...'
                                : item?.comments}
                            </Tooltip>
                          </span>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Modal>
        ) : (
          ''
        )}
        {showVendorModal ? (
          <Modal
            visible={showVendorModal}
            onCancel={() => setShowVendorModal(false)}
            footer={false}
          >
            <div className="df-jc-ac mt-4 font-18 sfprotext-bold">
              Do you want to share this LoI/LoA with the vendor?
            </div>
            <Form
              form={form}
              layout="vertical"
              className="ant-form ant-form-vertical"
              onFinish={onFinishVendor}
            >
              <Row className="mt-5" gutter={[8, 16]}>
                <Col span={12}>
                  <CustomButton
                    className={`${styles.modal_button} pull-right`}
                    type="primary"
                    htmlType="submit"
                  >
                    Share
                  </CustomButton>
                </Col>

                <Col span={12}>
                  <CustomButton
                    className={`${styles.modal_button} pull-left`}
                    htmlType="button"
                    onClick={() => setShowVendorModal('')}
                  >
                    No
                  </CustomButton>
                </Col>
              </Row>
            </Form>
          </Modal>
        ) : (
          ''
        )}
        {showPOModal ? (
          <Modal
            visible={showPOModal}
            // closable={true}
            closable={true}
            onCancel={handlePOCancel}
            footer={false}
          >
            <div className="df-jc-ac mt-4 font-18 sfprotext-bold">
              Update PO/WO details
            </div>
            {all_file_data?.poWo ? (
              <div className="font-16 sfprotext-medium df-jc-ac mt-4 mb-4">
                {all_file_data.poOrWo} has been raised under number :{' '}
                {all_file_data.poWoNumber}
              </div>
            ) : (
              <Form
                form={form}
                layout="vertical"
                className="ant-form ant-form-vertical"
                onFinish={onFinishPO}
              >
                <Row
                  className="mt-4"
                  gutter={{
                    span: 24,
                    sm: 16,
                    md: 24,
                    lg: 32
                  }}
                >
                  <Col className="gutter-row" span={24}>
                    <FormItem
                      label="Enter PO/WO number"
                      name="poNumber"
                      rules={[
                        {
                          required: all_file_data?.poWo ? false : true,
                          message: 'Enter PO/WO number'
                        }
                      ]}
                    >
                      <InputNumber
                        className="w-100"
                        disabled={all_file_data?.poWo ? true : false}
                        placeholder="Enter PO/WO number"
                      />
                    </FormItem>
                  </Col>
                </Row>
                {}
                <Row className="mt-2" gutter={[8, 16]}>
                  <Col span={12}>
                    <CustomButton
                      className={`${styles.modal_button} pull-right`}
                      type="primary"
                      htmlType="submit"
                    >
                      Update
                    </CustomButton>
                  </Col>

                  <Col span={12}>
                    <CustomButton
                      className={`${styles.modal_button} pull-left`}
                      htmlType="button"
                      onClick={() =>
                        setShowPOModal(
                          form.setFieldsValue({ poNumber: undefined })
                        )
                      }
                    >
                      No
                    </CustomButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Modal>
        ) : (
          ''
        )}
        {showConfirmationModal ? (
          <Modal
            visible={showConfirmationModal}
            onCancel={() => setShowConfirmationModal(false)}
            footer={false}
          >
            <div className="df-jc-ac mt-4 font-18 sfprotext-bold">
              Do you want to mark this LoI/LoA as confirmed by the vendor?
            </div>
            <Form
              form={form}
              layout="vertical"
              className="ant-form ant-form-vertical"
              onFinish={onFinishConfirmation}
            >
              <Row className="mt-5" gutter={[8, 16]}>
                <Col span={12}>
                  <CustomButton
                    className={`${styles.modal_button} pull-right`}
                    type="primary"
                    htmlType="submit"
                  >
                    Confirm
                  </CustomButton>
                </Col>

                <Col span={12}>
                  <CustomButton
                    className={`${styles.modal_button} pull-left`}
                    htmlType="button"
                    onClick={() => setShowConfirmationModal('')}
                  >
                    No
                  </CustomButton>
                </Col>
              </Row>
            </Form>
          </Modal>
        ) : (
          ''
        )}

        {showDeclinedModal ? (
          <Modal
            visible={showDeclinedModal}
            onCancel={() => setDeclinedModal(false)}
            footer={false}
          >
            {all_file_data?.declinedByVendor === true ? (
              <div className="df-jc-ac font-18 sfprotext-bold">
                <StopOutlined className="mr-3" style={{ color: 'red' }} /> This
                LoI/LoA is declined by the vendor
              </div>
            ) : (
              <>
                {' '}
                <div className="df-jc-ac mt-4 font-18 sfprotext-bold">
                  Is this LoI/LoA declined by the vendor?
                </div>
                <Form
                  form={form}
                  layout="vertical"
                  className="ant-form ant-form-vertical"
                  onFinish={onFinishDecline}
                >
                  <Row className="mt-5" gutter={[8, 16]}>
                    <Col span={12}>
                      <CustomButton
                        className={`${styles.modal_button} pull-right`}
                        type="primary"
                        htmlType="submit"
                      >
                        Decline
                      </CustomButton>
                    </Col>

                    <Col span={12}>
                      <CustomButton
                        className={`${styles.modal_button} pull-left`}
                        htmlType="button"
                        onClick={() => setDeclinedModal('')}
                      >
                        No
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>{' '}
              </>
            )}
          </Modal>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default CompletedPage;
