import apiClient from '../../../utilities/apiClient';

export const review_Actions = (url) => {
  return apiClient.get(
    `${apiClient.Urls.all_actions_required}/${url}`,
    '',
    true
  );
};
export const get_otp = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.otp}/${url}`, payload, true);
};

export const getProjectValue = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_project_amount}/${payload}`,
    payload,
    true
  );
};

export const approveLetter = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.approve_letter}?${url}`,
    payload,
    true
  );
};

export const rejectLetter = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.reject_letter}?${url}`,
    payload,
    true
  );
};
export const reviewLetter = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.review_letter}?${url}`,
    payload,
    true
  );
};

export const forwardLetter = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.forward_letter}?${url}`,
    payload,
    true
  );
};

export const cancelLoi = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.cancel_loi}/${url}`, payload, true);
};
