import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL;
export const apiUrl_new = process.env.REACT_APP_API_NEW_URL;

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

const getLocalToken = () => {
  const user = LocalStorage.getItem('LOI_user');
  const authToken = user?.accessToken;
  if (authToken !== null && authToken !== '') {
    return `${authToken}`;
  } else {
    return '';
  }
};

const apiClient = {
  Urls: {
    apiUrl,
    create_loi: `${apiUrl}/create-loi`,
    signin: `${apiUrl}/signin`,
    get_cfo: `${apiUrl}/cfo`,
    get_dashboard_details: `${apiUrl_new}/envelope/count`,
    get_reviewers: `${apiUrl}/reviewers`,
    get_approvers: `${apiUrl}/approvers`,
    get_sbgs: `${apiUrl}/sbg-head`,
    get_reports: `${apiUrl}/export-to-csv`,
    get_bu: `${apiUrl}/bu`,
    get_project: `${apiUrl}/project`,
    get_vendor: `${apiUrl}/vendor`,
    get_vendor_location: `${apiUrl}/vendorLocation`,
    get_req_pending: `${apiUrl}/loi-loa-pending`,
    get_req_all: `${apiUrl_new}/envelope/all`,
    get_req_completed: `${apiUrl}/loi-loa-completed`,
    get_history: `${apiUrl_new}/envelope/history`,
    get_env_flow: `${apiUrl}/loi-flow`,
    get_req_rejected: `${apiUrl}/loi-loa-rejected`,
    get_req_waiting: `${apiUrl}/loi-loa-waiting-for-others`,
    all_actions_required: `${apiUrl_new}/envelope`,
    otp: `${apiUrl}/send-otp`,
    approve_letter: `${apiUrl_new}/approvers/update`,
    reject_letter: `${apiUrl_new}/approvers/update`,
    forward_letter: `${apiUrl_new}/approvers/update`,
    review_letter: `${apiUrl_new}/approvers/update`,
    get_dummy: `${apiUrl}/dummy-data`,
    all_letters: `${apiUrl_new}/envelope`,
    share_vendor: `${apiUrl_new}/envelope/vendor`,
    get_project_amount: `${apiUrl}/project-amounts`,
    get_project_status: `${apiUrl}/get-project-status`,
    get_check_limit: `${apiUrl}/check-limit`,
    get_draft_by_id: `${apiUrl}/get-draft`,
    delete_image: `${apiUrl}/delete-draft-items`,
    delete_draft: `${apiUrl}/delete-draft`,
    cancel_loi: `${apiUrl}/cancel-loi`
  },
  /* TEST */
  make: async function (url, method, params, auth, type) {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    let authHeaders = {
      Authorization: getLocalToken()
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders
      };
    }

    if (method === 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params)
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: function (url, params, auth) {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, 'DELETE', params, auth);
  }
};

export default apiClient;
