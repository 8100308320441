import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';

import { createProjects } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
//import { CaretDownFilled } from '@ant-design/icons';
import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Switch,
  message
} from 'antd';
import styles from './index.module.less';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';

const AddModal = ({
  onSearchBu,
  // handleSbuSearchChange,
  // all_SBUs,
  buName,
  handleGetAllBu,
  dropDownLoading,
  onCancel,
  isModalVisible,
  setIsModalOpen,
  handleGetAllDesignations
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { allBu } = useSelector((state) => state.createLOI);
  const [isActive, setIsActive] = useState(true);

  form.setFieldsValue({ isActive: isActive });
  useEffect(() => {
    handleGetAllBu();
  }, [buName]);

  const onFinish = (values) => {
    dispatch(createProjects(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsModalOpen(false);
        handleGetAllDesignations();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };

  return (
    <CustomModal
      footer={
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                /* onClick={onOk} */ type="primary"
                htmlType="submit"
              >
                Add
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isModalVisible}
      title="Add Project"
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          <Col className="mr-5" xs={24} xl={24} span={11}>
            <FormItem
              label="BU-JV Name"
              name="buId"
              rules={[
                {
                  required: true,
                  message: 'Select BU-JV name'
                }
              ]}
            >
              <Select
                // onChange={onChangeBU}
                onSearch={onSearchBu}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                suffixIcon={<CaretDownFilled />}
                placeholder="Select BU-JV name"
              >
                {dropDownLoading ? (
                  <Select.Option>
                    <Skeleton loading />
                  </Select.Option>
                ) : (
                  allBu?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.buName}
                    </Select.Option>
                  ))
                )}
              </Select>
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Project Name'}
              name="projectName"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <Input placeholder="Enter Project Name" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Project Code'}
              name="projectCode"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <Input placeholder="Enter Project Code" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Project Value'}
              name="projectValue"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <InputNumber
                min={1}
                className="w-100"
                placeholder="Enter Project Value"
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Approved'}
              name="isApproved"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <Select placeholder="Select" suffixIcon={<CaretDownFilled />}>
                <Select.Option value={true}>Yes</Select.Option>
                <Select.Option value={false}>No</Select.Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Active'}
              name="isActive"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <div
                className={
                  isActive
                    ? `${styles.switcher_container}`
                    : `${styles.switcher_container1}`
                }
              >
                <Switch checked={isActive} onChange={handleSwitchChange} />
              </div>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default AddModal;
