import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getfilebyid } from '../approver-review/redux/slice';
import PdfViewerComponent from './components';

function PDFVIEW() {
  const { all_file_data } = useSelector((state) => state.reviewActions);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    handleGetfileById();
  }, []);
  const handleGetfileById = () => {
    dispatch(getfilebyid(params.id));
  };
  return (
    <div>
      <PdfViewerComponent
        document={'document.pdf'}
        all_file_data={all_file_data}
      />
    </div>
  );
}

export default PDFVIEW;
