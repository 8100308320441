import FormItem from '@/common/FormItem';
import {
  CaretDownFilled,
  DeleteOutlined,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Form, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Option } from 'antd/lib/mentions';
import React from 'react';

import styles from '../../InitiateLOI1/index.module.less';

const UpdatedMRDT = ({
  formValues,
  setFormValues,
  item,
  mrdt,
  handleDeleteMRDT,
  mrdtItem,
  setMrdt,
  mrdtIndex,
  defaultClauses,
  itemNumber
}) => {
  const handleChange = (type, value, inputName) => {
    const tempMrdt = [...mrdt];
    tempMrdt[mrdtIndex][type] = value;
    if (value === 'MinApplicable') {
      tempMrdt[mrdtIndex].clauses = [defaultClauses?.mrdt?.defaultClause];
    }
    setMrdt(tempMrdt);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
  };

  const handleClauseChange = (mrdtIndex, clauseIndex, val, inputName) => {
    const tempMrdt = [...mrdt];
    tempMrdt[mrdtIndex].clauses[clauseIndex] = val;
    setMrdt(tempMrdt);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempMrdt = [...mrdt];
    tempMrdt[mrdtIndex].clauses.push(defaultClauses?.mrdt?.defaultClause);
    setMrdt(tempMrdt);
  };

  const handleDeleteClause = (clauseIndex) => {
    const tempMrdt = [...mrdt];
    tempMrdt[mrdtIndex].clauses.splice(clauseIndex, 1);
    //console.log('final contracts', tempMrdt);
    setMrdt(tempMrdt);
  };

  const clauses = mrdtItem?.clauses || [];

  return (
    <>
      <Col className="font-18 sfprotext-bold mt-4 mb-2" span={22}>
        {`Minimum Resource Deployment Time  ${itemNumber}`}
      </Col>
      <Row className={`${styles.grey_holder} w-100`}>
        <Col className="gutter-row ml-3" span={6}>
          <FormItem
            label=" Minimum Resource Deployment Time"
            name={`mrdt_select_${mrdtIndex}`}
            rules={[{ required: true, message: 'Please select' }]}
          >
            <Select
              value={mrdtItem['mrdTime']}
              onChange={(value) =>
                handleChange('mrdTime', value, `mrdt_select_${mrdtIndex}`)
              }
              suffixIcon={<CaretDownFilled />}
              placeholder="Select"
            >
              <Option value="MinApplicable">Applicable</Option>
              <Option value="MinNot Applicable">Not Applicable</Option>
            </Select>
          </FormItem>
        </Col>{' '}
        <Col span={17}>
          {itemNumber > 1 ? (
            <div
              onClick={() => handleDeleteMRDT(mrdtIndex, item)}
              className={` cursor-pointer ${styles.delete_holder}`}
            >
              <DeleteOutlined />
            </div>
          ) : null}
        </Col>
        {mrdtItem.mrdTime === 'MinApplicable' && (
          <>
            <Form.List
              name={`mrdt_${mrdtIndex}_clauses`}
              initialValue={mrdtItem.clauses}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, clauseIndex) => (
                    <Col
                      className="gutter-row  ml-3"
                      span={18}
                      key={clauseIndex}
                    >
                      <Form.Item required={false} key={field.key}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          noStyle
                          rules={[{ required: true, message: 'Please enter' }]}
                        >
                          <Row>
                            <Col span={22}>
                              <TextArea
                                defaultValue={
                                  clauses[clauseIndex] ||
                                  defaultClauses?.mrdt?.defaultClause
                                }
                                onChange={(e) =>
                                  handleClauseChange(
                                    mrdtIndex,
                                    clauseIndex,
                                    e.target.value,
                                    `mrdt_${mrdtIndex}_clause_${clauseIndex}`,
                                    fields
                                  )
                                }
                                autoSize
                                placeholder="Enter message"
                              />
                            </Col>
                            <Col span={2}>
                              {fields.length > 1 ? (
                                <span
                                  onClick={() => {
                                    remove(field.name);
                                    handleDeleteClause(clauseIndex);
                                  }}
                                  className={`  pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                                >
                                  <MinusCircleFilled
                                    style={{ color: '#D72D30' }}
                                  />
                                </span>
                              ) : null}
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  ))}
                  <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                    <Form.Item>
                      <Col className={`${styles.add_clause}  mb-3`} span={24}>
                        <span
                          onClick={() => {
                            add(defaultClauses?.mrdt?.defaultClause);
                            handleAddClause();
                          }}
                          className="cursor-pointer"
                        >
                          <PlusCircleFilled className="mr-1" /> Add clause
                        </span>
                      </Col>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
            {/* {clauses.map((cls, clauseIndex) => (
              <>
                <Col className="gutter-row ml-3" span={18}>
                  <FormItem
                    rules={[{ required: true, message: 'Please enter' }]}
                    name={`mrdt_${mrdtIndex}_clause_${clauseIndex}`}
                  >
                    <TextArea
                      value={cls}
                      onChange={(e) =>
                        handleClauseChange(
                          mrdtIndex,
                          clauseIndex,
                          e.target.value,
                          `mrdt_${mrdtIndex}_clause_${clauseIndex}`
                        )
                      }
                      placeholder="Enter minimum resource deployment plan"
                      defaultValue={defaultClauses?.mrdt?.defaultClause}
                    />
                  </FormItem>
                </Col>
                <Col span={2}>
                  <div
                    onClick={() => handleDeleteClause(clauseIndex)}
                    className={`mt-4 pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                  >
                    <MinusCircleFilled style={{ color: '#D72D30' }} />
                  </div>
                </Col>
              </>
            ))}

            <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
              <span onClick={handleAddClause} className="cursor-pointer">
                <PlusCircleFilled className="mr-1" /> Add clause
              </span>
            </Col> */}
          </>
        )}
      </Row>
    </>
  );
};

export default UpdatedMRDT;
