import React from 'react';
import {
  Alert,
  Form,
  InputNumber,
  Select,
  Skeleton,
  Space,
  notification
} from 'antd';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { useDispatch } from 'react-redux';
import {
  getAllProjects,
  getCheckLimit
} from '@/features/initiate-loi/redux/slice';
import { CaretDownFilled, ExclamationCircleFilled } from '@ant-design/icons';
import yes from '../../images/yesFlow.png';
import no from '../../images/noFlow.png';
import LocalStorage from 'utilities/localStorage';
import styles from './index.module.less';
const { Option } = Select;

const InitModal = ({
  dropDownLoading,
  check,
  setCheck,
  setApproverFlow,
  setLoiVal,
  setBuId,
  setProjectCode,
  loiVal,
  setProjectId,
  setBuJv,
  setBuName,
  setProjectName,
  allBu,
  allProjects,
  handleCancel,
  isModalVisible,
  onFinish,
  loading
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  console.log('ISAPP', check?.isBlocked);

  const messageHead = (
    <div className="font-13">
      CB status is not updated for this project code. Requesting to verify the
      CB approval status with Finance Team and then share the status with ADMIN
      <b>{` Gayatri B D (gayatribd@tataprojects.com)`}</b>
    </div>
  );

  const onChangeBU = (value) => {
    notification.destroy();
    dispatch(getAllProjects(value));
    const selectValue = value;

    const fil = allBu.filter((allBu) => allBu.buName == selectValue);

    setBuJv(fil[0].id);
    setApproverFlow('');
    setCheck('');
    localStorage.removeItem('checkLimit');
    setBuId({ buId: fil[0].id });
    form.setFieldsValue({
      project_name: undefined
    });
    form.setFieldsValue({
      project_Code: undefined
    });
    form.setFieldsValue({
      controlBudget: undefined
    });
  };

  const onBuSearch = (value) => {
    //console.log(value);
    setBuName(value);

    return value;
  };

  const onChangeProjectName = (e) => {
    const selectValue = e;
    const filtered = allProjects.filter(
      (allProjects) => allProjects.projectName == selectValue
    );
    //console.log(filtered[0]);
    setProjectId({ id: filtered[0].id });
    setProjectCode({ projectCode: filtered[0].projectCode });
    form.setFieldsValue({
      project_Code: filtered[0].projectCode
    });

    const payload = {
      projectId: filtered[0].id,
      loiValue: loiVal
    };
    dispatch(getCheckLimit(payload)).then((response) => {
      //console.log('Proj', response.payload.data);
      LocalStorage.setItem('checkLimit', JSON.stringify(response.payload.data));
      if (response.payload.data.isBlocked === true) {
        notification.destroy();
        notification.error({
          message: (
            <strong className="sfprotext-bold font-16">Project Blocked!</strong>
          ),
          description: (
            <p className="font-12">
              This project is blocked for further issuance of LOI/ LOA until all
              the existing LOIs are converted to POs. For the list of
              unconverted LOA/LOIs to POs, please contact{' '}
              <b>tanuja@tataprojects.com</b> or
              <b> gayatribd@tataprojects.com</b>
            </p>
          ),
          placement: 'bottomLeft',
          duration: null
        });
      } else {
        notification.destroy();
      }
    });
  };

  const onProjectSearch = (value) => {
    //console.log(value);
    setProjectName(value);

    return value;
  };

  return (
    <CustomModal
      footer={null}
      onCancel={handleCancel}
      visible={isModalVisible}
      title="Initiate LoI/LoA"
    >
      <Form
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        onFinish={onFinish}
      >
        <FormItem
          label="LOI Value"
          name="loi_Value"
          rules={[
            {
              required: true,
              message: 'Enter LOI Value'
            }
          ]}
        >
          <InputNumber
            onChange={(e) => setLoiVal(e)}
            className="w-100"
            controls={false}
            placeholder="Enter LOI Value"
          />
        </FormItem>
        <FormItem
          label="BU-JV Name"
          name="bu"
          rules={[
            {
              required: true,
              message: 'Select BU-JV name'
            }
          ]}
        >
          <Select
            onChange={onChangeBU}
            onSearch={onBuSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            suffixIcon={<CaretDownFilled />}
            placeholder="Select BU-JV name"
          >
            {dropDownLoading ? (
              <Option>
                <Skeleton loading />
              </Option>
            ) : (
              allBu?.map((item, index) => (
                <Option key={index} value={item.buName}>
                  {item.buName}
                </Option>
              ))
            )}
          </Select>
        </FormItem>
        <FormItem
          label="Project Name "
          name="project_name"
          rules={[
            {
              required: true,
              message: 'Select Project Name'
            }
          ]}
        >
          <Select
            onChange={(e) => onChangeProjectName(e)}
            showSearch
            onSearch={onProjectSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<CaretDownFilled />}
            placeholder="Select Project Name"
          >
            {dropDownLoading ? (
              <Option key={1}>
                <Skeleton loading />
              </Option>
            ) : (
              allProjects?.map((item, index) => (
                <Option key={index} value={item.projectName}>
                  {item.projectName}
                </Option>
              ))
            )}
          </Select>
        </FormItem>
        {check?.isApproved === false &&
        check?.isLimitExceeded === false &&
        check?.isCbNull === false &&
        check?.isBlocked === false ? (
          <FormItem label="Approval Flow" name="sign">
            <div className="text-center">
              <img className="w-40 mt-4 mb-3" src={no} />
            </div>
          </FormItem>
        ) : check?.isApproved === true &&
          check?.isLimitExceeded === false &&
          check?.isCbNull === false &&
          check?.isBlocked === false ? (
          <FormItem label="Approval Flow" name="sign">
            <div className="text-center">
              <img className="w-60 mt-4 mb-3" src={yes} />
            </div>
          </FormItem>
        ) : check?.isApproved === true &&
          check?.isLimitExceeded === true &&
          check?.isCbNull === false &&
          check?.isBlocked === false ? (
          <FormItem label="Approval Flow" name="sign">
            <div className="text-center">
              <img className="w-60 mt-4 mb-3" src={yes} />
            </div>
          </FormItem>
        ) : check?.isApproved === true &&
          check?.isLimitExceeded === false &&
          check?.isCbNull === false &&
          check?.isBlocked === false ? (
          <FormItem label="Approval Flow" name="sign">
            <div className="text-center">
              <img className="w-60 mt-4 mb-3" src={yes} />
            </div>
          </FormItem>
        ) : check?.isApproved === false &&
          check?.isLimitExceeded === true &&
          check?.isCbNull === false &&
          check?.isBlocked === false ? (
          <FormItem label="Approval Flow" name="sign">
            <div className="text-center">
              <img className="w-60 mt-4 mb-3" src={yes} />
            </div>
          </FormItem>
        ) : null}
        {check?.isCbNull === true ? (
          <Alert
            description={messageHead}
            className={`mb-4 ${styles}`}
            type="error"
            showIcon
            icon={<ExclamationCircleFilled />}
          />
        ) : null}

        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton
              disabled={
                check?.isCbNull === true || check?.isBlocked === true
                  ? true
                  : false
              }
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Next
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};

export default InitModal;
