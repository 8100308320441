import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  create_LOI,
  getApprovers,
  getBu,
  getCFO,
  getDummyData,
  getProjects,
  getProjectStats,
  getVendorLocation,
  getVendors,
  getProjectLimit,
  getSbgs,
  getReviewers,
  getDraft,
  deleteImages,
  deleteDrafts
} from './api';

const initialState = {
  loading: false,
  allApprovers: [],
  allReviewers: [],
  allSbgs: [],
  allBu: [],
  allCFO: [],
  allVendors: [],
  allProjects: [],
  dummyData: {},
  allVendorLocations: [],
  projectStatus: {},
  checkLimit: {},
  draftDetails: {}
};

const actions = {
  CREATELOI: 'createLOI/CREATELOI',
  GETALLAPPROVERS: 'getAllApprovers/GETALLAPPROVERS',
  GETALLREVIEWERS: 'getAllReviewers/GETALLREVIEWERS',
  GETALLSBGS: 'getAllSbgs/GETALLSBGS',
  GETALLBU: 'getAllBu/GETALLBU',
  GETALLCFO: 'getAllCFO/GETALLCFO',
  GETALLVENDORS: 'getAllVendors/GETALLVENDORS',
  GETALLPROJECTS: 'getAllProjects/GETALLPROJECTS',
  GETALLDUMMY: 'getAllDummy/GETALLDUMMY',
  GETALLVENDORLOCATIONS: 'getAllVendorLocations/GETALLVENDORLOCATIONS',
  GETPROJECTSTATUS: 'getProjectStatus/GETPROJECTSTATUS',
  GETCHECKLIMIT: 'getCheckLimit/GETCHECKLIMIT',
  GETDRAFTBYID: 'getDraftById/GETDRAFTBYID',
  DELETEDRAFTIMAGES: 'deleteDraftImages/DELETEDRAFTIMAGES',
  DELETEDRAFT: 'deleteDraft/DELETEDRAFT'
};

export const getAllApprovers = createAsyncThunk(
  actions.GETALLAPPROVERS,
  async (payload) => {
    const response = await getApprovers(payload);
    return response;
  }
);

export const getAllReviewers = createAsyncThunk(
  actions.GETALLREVIEWERS,
  async (payload) => {
    const response = await getReviewers(payload);
    return response;
  }
);

export const deleteDraftImages = createAsyncThunk(
  actions.DELETEDRAFTIMAGES,
  async (payload) => {
    const response = await deleteImages(payload);
    return response;
  }
);

export const deleteDraft = createAsyncThunk(
  actions.DELETEDRAFT,
  async (payload) => {
    const response = await deleteDrafts(payload);
    return response;
  }
);

export const getDraftById = createAsyncThunk(
  actions.GETDRAFTBYID,
  async (payload) => {
    const response = await getDraft(payload.id);
    return response;
  }
);

export const getAllSbgs = createAsyncThunk(
  actions.GETALLSBGS,
  async (payload) => {
    const response = await getSbgs(payload);
    return response;
  }
);

export const createLOI = createAsyncThunk(
  actions.CREATELOI,
  async (payload) => {
    const response = await create_LOI(payload);
    return response;
  }
);

export const getAllBu = createAsyncThunk(actions.GETALLBU, async (payload) => {
  const response = await getBu(payload);
  return response;
});

export const getAllCFO = createAsyncThunk(
  actions.GETALLCFO,
  async (payload) => {
    const response = await getCFO(payload);
    return response;
  }
);
export const getAllVendors = createAsyncThunk(
  actions.GETALLVENDORS,
  async (payload) => {
    const response = await getVendors(payload);
    return response;
  }
);

export const getAllVendorLocations = createAsyncThunk(
  actions.GETALLVENDORLOCATIONS,
  async (payload) => {
    const response = await getVendorLocation(payload);
    return response;
  }
);
export const getAllProjects = createAsyncThunk(
  actions.GETALLPROJECTS,
  async (payload) => {
    const response = await getProjects(payload);
    return response;
  }
);

export const getAllDummy = createAsyncThunk(
  actions.GETALLDUMMY,
  async (payload) => {
    const response = await getDummyData(payload);
    return response;
  }
);

export const getProjectStatus = createAsyncThunk(
  actions.GETPROJECTSTATUS,
  async (payload) => {
    const response = await getProjectStats(payload);
    return response;
  }
);

export const getCheckLimit = createAsyncThunk(
  actions.GETCHECKLIMIT,
  async (payload) => {
    const response = await getProjectLimit(payload);
    return response;
  }
);

export const createLOISlice = createSlice({
  name: 'createLOI',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllApprovers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllApprovers.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.allApprovers = data;
      })
      .addCase(getAllApprovers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllReviewers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllReviewers.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.allReviewers = data;
      })
      .addCase(getAllReviewers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllSbgs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSbgs.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.allSbgs = data;
      })
      .addCase(getAllSbgs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getDraftById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDraftById.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.draftDetails = data;
      })
      .addCase(getDraftById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createLOI.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(createLOI.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          state.draftDetails = {};
          message.success(msg);
        }
      })
      .addCase(createLOI.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteDraftImages.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(deleteDraftImages.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          state.draftDetails = {};
          message.success('Document deleted successfully');
        }
      })
      .addCase(deleteDraftImages.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteDraft.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(deleteDraft.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          state.draftDetails = {};
          message.success(msg);
        }
      })
      .addCase(deleteDraft.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllBu.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBu.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.allBu = data;
      })
      .addCase(getAllBu.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllCFO.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCFO.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.allCFO = data;
      })
      .addCase(getAllCFO.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.allProjects = data;
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllDummy.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDummy.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.dummyData = data;
      })
      .addCase(getAllDummy.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllVendors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVendors.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.allVendors = data;
      })
      .addCase(getAllVendors.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllVendorLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVendorLocations.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.allVendorLocations = data;
      })
      .addCase(getAllVendorLocations.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getProjectStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectStatus.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.projectStatus = data;
      })
      .addCase(getProjectStatus.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getCheckLimit.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCheckLimit.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.checkLimit = data;
      })
      .addCase(getCheckLimit.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default createLOISlice.reducer;
