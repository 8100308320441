import React, { useState } from 'react';
import styles from './index.module.less';
import { Form, Input, Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import OtpInput from 'react-otp-input';
import TextArea from 'antd/lib/input/TextArea';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appLetter } from '../../redux/slice';
import { history } from '@/app/history';

function ApproveModal({ loading, modalVisible, setModalVisible, email }) {
  const [approversign, setApproversign] = useState('');
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    values['id'] = params.id;
    values['id'] = params.id;
    values['status'] = 'Accepted';
    values['email'] = email;
    dispatch(appLetter(values)).then((response) => {
      if (response.payload.success) {
        history.push('/CFO-dashboard');
      }
    });
    setModalVisible('');
  };
  return (
    <CustomModal
      visible={modalVisible === 'approved' ? true : false}
      className={styles.background}
      title={'Approve the document with signature'}
      onCancel={() => setModalVisible('')}
      width={'40%'}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        onFinish={onFinish}
      >
        <FormItem
          label="Enter the name or initial to sign"
          name="sign"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          onChange={(e) => setApproversign(e.target.value)}
        >
          <Input placeholder="Enter name or initial" />
        </FormItem>
        {approversign != '' ? (
          <FormItem label="Signature preview" name="sign">
            <span className={`${styles.signpreview}`}>{approversign}</span>
          </FormItem>
        ) : (
          ''
        )}
        <FormItem
          label="Enter OTP sent to your e-mail"
          name="otp"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <OtpInput numInputs={4} separator="" className={`${styles.otp}`} />
        </FormItem>
        <FormItem
          label="Comments"
          name="comments"
          rules={[
            {
              required: true,
              message: 'Please enter comments'
            }
          ]}
        >
          <TextArea
            style={{ width: '100%' }}
            placeholder="Add your reason for approved and let others know..."
            rows={4}
            allowClear
          />
        </FormItem>
        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={() => setModalVisible('')}>
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Approve
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
}

export default ApproveModal;
