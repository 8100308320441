export const DefaultPaymentObject = {
  type: null
};

export const formatDummyData = (data) => {
  //console.log('FORMAT DUMMT DATA', data);
  const result = {
    contract: {},
    dlp: {},
    freight: {},
    ldp: {},
    mrdt: {},
    mobilisation: {},
    packing: {},
    paymentAdvance: {},
    paymentProgress: {},
    paymentRetention: {},
    warranty: {},
    checkbox: {},
    taxAndDuties: {},
    loiMessage: {},
    loaMessage: {},
    message0: {},
    message3: {},
    delivery: {}
  };

  // ContractPerformancePlaceHolder[0]
  // DLPPlaceHolder[0]
  // FreightAndInsurancePlaceHolder[0]
  // LDPlaceHolder[0]
  // MinResourcePlaceHolder[0]
  // MobilisationTimePlaceHolder[0]
  // PackingAndForwardingPlaceHolder[0]
  // PaymentTerms[0]
  // WarrantyPlaceHolder[0]
  // checkbox

  // ContractPerformanceClausePHs[0]-> clause
  // DLPClausePHs[0]-> clause
  // FreightAndInsuranceClausePHs[0].clause
  // LDClausePHs[0].clause
  // MinResourceClausePHs[0].clause
  // MobilisationTimeClausePHs[0].clause
  // PackingAndForwardingClausePHs[0].clause
  // PaymentTermsPlaceHolder[0], [1], [2] .clauses[0]
  // WarrantyClausePHs[0].clause

  Object.keys(data).forEach((catKey) => {
    switch (catKey) {
      case 'ContractPerformancePlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.ContractPerformanceClausePHs || [];
        const clause1 = clauses[0]?.clause;
        const clause2 = clauses[1]?.clause;
        result['contract'].defaultClause1 = clause1;
        result['contract'].defaultClause2 = clause2;
        break;
      }
      case 'DLPPlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.DLPClausePHs || [];
        const clause1 = clauses[0]?.clause;
        result['dlp'].defaultClause = clause1;
        break;
      }
      case 'FreightAndInsurancePlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.FreightAndInsuranceClausePHs || [];
        const clause1 = clauses[0]?.clause;
        result['freight'].defaultClause = clause1;
        break;
      }
      case 'LDPlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.LDClausePHs || [];
        const clause1 = clauses[0]?.clause;
        result['ldp'].defaultClause = clause1;
        break;
      }
      case 'MinResourcePlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.MinResourceClausePHs || [];
        const clause1 = clauses[0]?.clause;
        result['mrdt'].defaultClause = clause1;
        break;
      }
      case 'MobilisationTimePlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.MobilisationTimeClausePHs || [];
        const clause1 = clauses[0]?.clause;
        result['mobilisation'].defaultClause = clause1;
        break;
      }
      case 'PackingAndForwardingPlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.PackingAndForwardingClausePHs || [];
        const clause1 = clauses[0]?.clause;
        const clause2 = clauses[1]?.clause;
        result['packing'].defaultClause1 = clause1;
        result['packing'].defaultClause2 = clause2;
        break;
      }
      case 'DeliverySchedulePlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.DeliveryScheduleClausePHs || [];
        const clause1 = clauses[0]?.clause;
        const clause2 = clauses[1]?.clause;
        result['delivery'].defaultClause1 = clause1;
        result['delivery'].defaultClause2 = clause2;
        break;
      }
      case 'PaymentTerms': {
        // assuming advance, progress, retention comes in the same order.
        const terms = data[catKey].PaymentTermsPlaceHolder || [];
        const advanceClauses = terms[0]?.clauses || [];
        const progressClauses = terms[1]?.clauses;
        const retentionClauses = terms[2]?.clauses;

        const advanceClause = advanceClauses[0]?.clause;
        const progressClause = progressClauses[0]?.clause;
        const retentionClause = retentionClauses[0]?.clause;
        result['paymentAdvance'].defaultClause = advanceClause;
        result['paymentProgress'].defaultClause = progressClause;
        result['paymentRetention'].defaultClause = retentionClause;
        break;
      }
      case 'WarrantyPlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.WarrantyClausePHs;
        const clause = clauses[0]?.clause;
        result['warranty'].defaultClause = clause;
        break;
      }
      case 'checkbox': {
        result['checkbox'].defaultClause = data[catKey];
        break;
      } //
      case 'taxAndDuties': {
        result['taxAndDuties'].defaultClause = data[catKey];
        break;
      }
      case 'loaMessage': {
        result['loaMessage'].defaultClause = data[catKey];
        break;
      }
      case 'loiMessage': {
        result['loiMessage'].defaultClause = data[catKey];
        break;
      }
      case 'Message0PlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.Message0ClausePHs;
        const clause = clauses[0]?.clause;
        result['message0'].defaultClause = clause;
        break;
      }
      case 'Message3PlaceHolder': {
        const term = data[catKey][0] || {};
        const clauses = term.Message3ClausePHs;
        const clause = clauses[0]?.clause;
        result['message3'].defaultClause = clause;
        break;
      }
      default:
        break;
    }
  });

  //console.log('FINAL FORMATTED cluases', result);

  return result;
};
