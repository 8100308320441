import React from 'react';

import CustomLayout from '@/common/Layout';
import AdminManagePage from './components';

const Pending = () => {
  return (
    <>
      <CustomLayout sider={true} tracker={true}>
        <AdminManagePage />
      </CustomLayout>
    </>
  );
};

export default Pending;
