import { history } from '@/app/history';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';

import { CaretDownFilled } from '@ant-design/icons';

import { Form, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';
import { forLetter } from '../../redux/slice';

// import { useParams } from 'react-router-dom';

import styles from './index.module.less';

function ForwardModal({
  setReviewerName,
  // all_file_data,
  modalVisible,
  setModalVisible,
  loading,
  allReviewers,
  email
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  // const envelope_id = params.id;
  const user = LocalStorage.getItem('LOI_user');

  const onReviewerSearch = (value) => {
    setReviewerName(value);
    return value;
  };

  var filteredReviewersName = [];
  var len = allReviewers.length;
  for (var i = 0; i < len; i++) {
    filteredReviewersName.push({
      id: allReviewers[i]?.id,
      email: allReviewers[i].email,
      fullname: `${allReviewers[i].givenName} ${allReviewers[i].surname} (${allReviewers[i].email})`
    });
  }

  const onFinish = (values) => {
    // let formData = new FormData();

    values['id'] = params.id;
    // formData.append('reviewer', values.approverId);
    // formData.append('comments', values.comments);
    // formData.append('status', 'Forwarded-envelope-reviewer');
    values['reviewer'] = values.approverId;
    values['status'] = 'Forwarded';
    values['email'] = email;
    //console.log(values);
    dispatch(forLetter(values)).then((response) => {
      if (
        (user?.user?.Role?.role === 'APPROVER' ||
          user?.user?.Role?.role === 'SBGHEAD' ||
          user?.user?.Role?.role === 'REVIEWER') &&
        response.payload.success
      ) {
        history.push('/approver-dashboard');
      } else {
        history.push(`/CFO-dashboard`);
      }
    });
    setModalVisible('');
  };

  return (
    <CustomModal
      visible={
        modalVisible === 'forward' ||
        modalVisible === 'forward-comments' ||
        modalVisible === 'rejected-comments'
          ? true
          : false
      }
      className={styles.background}
      title={
        modalVisible === 'forward'
          ? 'Forward the LoI/LoA'
          : 'Review the LoI/LoA'
      }
      onCancel={() => setModalVisible('')}
      width={'45%'}
      footer={null}
    >
      <div className="">
        <Form
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
          onFinish={onFinish}
        >
          <FormItem
            label="Comments"
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please enter comments'
              }
            ]}
          >
            <TextArea
              style={{ width: '100%' }}
              placeholder="Add your reason for forwarding the letter"
              rows={4}
              allowClear
            />
          </FormItem>
          {modalVisible === 'forward' ? (
            <FormItem
              label="Select reviewer"
              name="approverId"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
            >
              <Select
                onSearch={onReviewerSearch}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Select reviewer"
                suffixIcon={<CaretDownFilled />}
                style={{ width: '100%' }}
              >
                {filteredReviewersName?.map((item, index) => (
                  <Select.Option value={item?.email} key={index}>
                    {item?.fullname}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          ) : (
            ''
          )}

          <FormItem className="mb-1 text-right">
            <CustomButton
              className="mr-2"
              htmlType="button"
              onClick={() => setModalVisible('')}
            >
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              {modalVisible === 'forward' ? 'Forward' : 'Return'}
            </CustomButton>
          </FormItem>
        </Form>
      </div>
    </CustomModal>
  );
}

export default ForwardModal;
