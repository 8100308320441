import { configureStore } from '@reduxjs/toolkit';
import loginReducer from 'features/login/redux/slice';
import createLOIReducer from 'features/initiate-loi/redux/slice';
import dashboardReducer from 'features/dashboard/redux/slice';
import trackersListReducer from 'features/pending/redux/slice';
import completedListReducer from '@/features/approved/redux/slice';
import adminManageReducer from '@/features/requester-manage/redux/slice';
import rejectedListReducer from '@/features/rejected/redux/slice';
import waitingListReducer from '@/features/waiting-for-others/redux/slice';
import reviewActionsReducer from '@/features/approver-review/redux/slice';
import settingsReducer from '@/features/settings/redux/slice';
export const store = configureStore({
  reducer: {
    login: loginReducer,
    createLOI: createLOIReducer,
    dashboard: dashboardReducer,
    trackersList: trackersListReducer,
    completedList: completedListReducer,
    rejectedList: rejectedListReducer,
    waitingList: waitingListReducer,
    adminManage: adminManageReducer,
    reviewActions: reviewActionsReducer,
    settings: settingsReducer
  }
});
