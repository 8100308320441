import FormItem from '@/common/FormItem';
import {
  DeleteOutlined,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Row, Radio, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import React from 'react';
import styles from '../../InitiateLOI1/index.module.less';

const UpdatedDelivery = ({
  formValues,
  setFormValues,
  item,
  delivery,
  handleDeleteDelivery,
  deliveryItem,
  setDelivery,
  deliveryIndex,
  defaultClauses,
  itemNumber
}) => {
  const handleChange = (type, value, inputName) => {
    const tempDelivery = [...delivery];
    tempDelivery[deliveryIndex][type] = value;
    if (value === 'yes') {
      tempDelivery[deliveryIndex].clauses = [
        {
          field1: defaultClauses?.delivery?.defaultClause1,
          field2: defaultClauses?.delivery?.defaultClause2
        }
      ];
    }
    setDelivery(tempDelivery);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
  };

  const handleClauseChange = (
    deliveryIndex,
    clauseIndex,
    type,
    val,
    inputName
  ) => {
    const tempDelivery = [...delivery];
    tempDelivery[deliveryIndex].clauses[clauseIndex][type] = val;
    setDelivery(tempDelivery);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempDelivery = [...delivery];
    tempDelivery[deliveryIndex].clauses.push({
      field1: defaultClauses?.delivery?.defaultClause1,
      field2: defaultClauses?.delivery?.defaultClause2
    });
    setDelivery(tempDelivery);
  };

  const handleDeleteClause = (clauseIndex) => {
    const tempDelivery = [...delivery];
    tempDelivery[deliveryIndex].clauses.splice(clauseIndex, 1);
    setDelivery(tempDelivery);
  };

  const clauses = deliveryItem?.clauses || [];
  //console.log('Delivery', clauses);

  //console.log('delivery default value', form.getFieldsValue());

  return (
    <>
      <Col className="font-18 sfprotext-bold mt-4 mb-2" span={24}>
        {` Delivery Schedule/Completion ${itemNumber}`}
      </Col>
      <Row className={`${styles.grey_holder}   w-100`}>
        <Col className="gutter-row ml-3" span={8}>
          <FormItem
            name={`delivery_radio_${deliveryIndex}`}
            label="Is Delivery Schedule/Completion in vendor scope?"
            rules={[{ required: true, message: 'Please select' }]}
          >
            <Radio.Group
              value={deliveryItem?.deliveryScope}
              onChange={(e) =>
                handleChange(
                  'deliveryScope',
                  e.target.value,
                  `delivery_radio_${deliveryIndex}`
                )
              }
            >
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </FormItem>
        </Col>{' '}
        <Col span={15}>
          {itemNumber > 1 ? (
            <div
              onClick={() => handleDeleteDelivery(deliveryIndex, item)}
              className={` cursor-pointer ${styles.delete_holder}`}
            >
              <DeleteOutlined />
            </div>
          ) : null}
        </Col>
        {deliveryItem.deliveryScope === 'yes' && (
          <>
            <Form.List
              initialValue={clauses}
              name={`delivery_${deliveryIndex}_clauses`}
            >
              {(fields, { add, remove }) => {
                return (
                  <div style={{ width: '100%' }}>
                    {fields.map((field, clauseIndex) => (
                      <>
                        <Row key={field.key}>
                          <Col className="gutter-row ml-3" span={20}>
                            <Form.Item
                              name={[field.name, 'field1']}
                              fieldKey={[field.fieldKey, 'field1']}
                              rules={[
                                { required: true, message: 'Please enter' }
                              ]}
                            >
                              <TextArea
                                defaultValue={
                                  clauses.field1 ||
                                  defaultClauses?.delivery?.defaultClause1
                                }
                                onChange={(e) =>
                                  handleClauseChange(
                                    deliveryIndex,
                                    clauseIndex,
                                    'field1',
                                    e.target.value,
                                    `delivery_field1${deliveryIndex}_clause_${clauseIndex}`
                                  )
                                }
                                autoSize
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col className="gutter-row ml-3" span={20}>
                            <Form.Item
                              name={[field.name, 'field2']}
                              fieldKey={[field.fieldKey, 'field2']}
                              rules={[
                                { required: true, message: 'Please enter' }
                              ]}
                            >
                              <TextArea
                                defaultValue={
                                  clauses[clauseIndex] ||
                                  defaultClauses?.delivery?.defaultClause2
                                }
                                onChange={(e) =>
                                  handleClauseChange(
                                    deliveryIndex,
                                    clauseIndex,
                                    'field2',
                                    e.target.value,
                                    `delivery_field1${deliveryIndex}_clause_${clauseIndex}`
                                  )
                                }
                                autoSize
                              />
                            </Form.Item>
                          </Col> */}
                          <Col span={2}>
                            {fields.length > 1 ? (
                              <span
                                onClick={() => {
                                  remove(field.name);
                                  handleDeleteClause(clauseIndex);
                                }}
                                className={`  pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                              >
                                <MinusCircleFilled
                                  style={{ color: '#D72D30' }}
                                />
                              </span>
                            ) : null}
                          </Col>
                        </Row>
                      </>
                    ))}
                    <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                      <Form.Item>
                        <Col className={`${styles.add_clause}  mb-3`} span={24}>
                          <span
                            onClick={() => {
                              add({
                                field1: defaultClauses?.delivery?.defaultClause1
                                // field2: defaultClauses?.delivery?.defaultClause2
                              });
                              handleAddClause();
                            }}
                            className="cursor-pointer"
                          >
                            <PlusCircleFilled className="mr-1" /> Add clause
                          </span>
                        </Col>
                      </Form.Item>
                    </Col>
                  </div>
                );
              }}
            </Form.List>

            {/* {clauses.map((cls, clauseIndex) => (
              <>
                <Col className="gutter-row ml-3" span={20}>
                  <FormItem
                    rules={[{ required: true, message: 'Please enter' }]}
                    name={`packing_field1${deliveryIndex}_clause_${clauseIndex}`}
                  >
                    <div>
                      <TextArea
                        value={cls['field1']}
                        onChange={(e) =>
                          handleClauseChange(
                            deliveryIndex,
                            clauseIndex,
                            'field1',
                            e.target.value,
                            `packing_field1${deliveryIndex}_clause_${clauseIndex}`
                          )
                        }
                        autoSize
                        defaultValue={defaultClauses?.delivery?.defaultClause1}
                      />
                    </div>
                  </FormItem>
                </Col>

                <Col className="gutter-row ml-3" span={20}>
                  <FormItem
                    rules={[{ required: true, message: 'Please enter' }]}
                    name={`packing_field2${deliveryIndex}_clause_${clauseIndex}`}
                  >
                    <div>
                      <TextArea
                        value={cls['field2']}
                        onChange={(e) =>
                          handleClauseChange(
                            deliveryIndex,
                            clauseIndex,
                            'field2',
                            e.target.value,
                            `packing_field2${deliveryIndex}_clause_${clauseIndex}`
                          )
                        }
                        autoSize
                        defaultValue={
                          defaultClauses?.delivery?.defaultClause2 || ''
                        }
                      />
                    </div>
                  </FormItem>
                </Col>
                {clauses.length > 1 && (
                  <Col span={2}>
                    <div
                      onClick={() => handleDeleteClause(clauseIndex)}
                      className={` pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                    >
                      <MinusCircleFilled style={{ color: '#D72D30' }} />
                    </div>
                  </Col>
                )}
              </>
            ))}
            <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
              <span onClick={handleAddClause} className="cursor-pointer">
                <PlusCircleFilled className="mr-1" /> Add clause
              </span>
            </Col> */}
          </>
        )}
      </Row>
    </>
  );
};

export default UpdatedDelivery;
