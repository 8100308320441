import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';
import { getAllApprovers, getAllReviewers } from '../initiate-loi/redux/slice';
import { getEnvFlow, getHistoryList } from '../requester-manage/redux/slice';
import ApproverReviewPage from './components';
import {
  cancelLOI,
  getfilebyid,
  getOtp,
  getProjectAmountById
} from './redux/slice';
import { history } from '@/app/history';

function ApproverReview() {
  const user = LocalStorage.getItem('LOI_user');
  const { allApprovers, allReviewers } = useSelector(
    (state) => state.createLOI
  );
  const { all_file_data, otp, all_project_amount } = useSelector(
    (state) => state.reviewActions
  );
  const { history_data, envelope_flow } = useSelector(
    (state) => state.adminManage
  );

  const [modalVisible, setModalVisible] = useState('');
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [reviewerName, setReviewerName] = useState();

  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    handleGetfileById();
    handleHistory();
    handleGetAllApprovers();
    handleEnvFlow();
    setUserDetails(user);
  }, []);

  /*  //console.log('ALL', all_file_data[0]?.Approvers); */
  const onClickCancel = (values) => {
    // values['remarks'] = values.remarks;
    const payload = { id: params.id, remarks: values.remarks };
    dispatch(cancelLOI(payload)).then((res) => {
      if (res.payload.success) {
        history.push('/cancelled');
      }
    });
  };

  useEffect(() => {
    handleGetAllReviewers();
  }, [reviewerName]);

  const handleGetfileById = () => {
    dispatch(getfilebyid(params.id)).then((response) => {
      if (response.payload.success === true) {
        setIsLoading(false);
      }
    });
  };

  const handleGetProjectAmountById = () => {
    const payload = all_file_data?.Project?.id;
    dispatch(getProjectAmountById(payload));
    setModalVisible('approved');
  };

  const handleHistory = () => {
    dispatch(getHistoryList(params.id)).then((response) => {
      if (response.payload.success === true) {
        setIsLoading(false);
      }
    });
  };

  const handleEnvFlow = () => {
    dispatch(getEnvFlow(params.id)).then((response) => {
      if (response.payload.success === true) {
        setIsLoading(false);
      }
    });
  };
  const handleGetAllApprovers = () => {
    dispatch(getAllApprovers()).then((response) => {
      if (response.payload.success === true) {
        setIsLoading(false);
      }
    });
  };

  const handleGetAllReviewers = () => {
    const payload = {
      page: 1,
      perPage: 20,
      search: reviewerName !== undefined ? reviewerName : ''
    };
    //console.log('HELLOOOOO', reviewerName);
    dispatch(getAllReviewers(payload));
  };

  const sentotp = () => {
    const paylaod = params.id;
    dispatch(getOtp(paylaod));
    const payload = all_file_data?.Project?.id;
    dispatch(getProjectAmountById(payload));
    setModalVisible('approved');
  };
  return (
    <ApproverReviewPage
      cancelModalVisible={cancelModalVisible}
      setCancelModalVisible={setCancelModalVisible}
      onClickCancel={onClickCancel}
      reviewerName={reviewerName}
      allReviewers={allReviewers}
      setReviewerName={setReviewerName}
      userDetails={userDetails}
      setUserDetails={setUserDetails}
      all_project_amount={all_project_amount}
      envelope_flow={envelope_flow}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      allApprovers={allApprovers}
      handleGetfileById={handleGetfileById}
      history_data={history_data}
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      all_file_data={all_file_data}
      sentotp={sentotp}
      handleGetProjectAmountById={handleGetProjectAmountById}
      otp={otp}
    />
  );
}

export default ApproverReview;
