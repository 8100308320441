import CustomLayout from '@/common/Layout';
import React from 'react';
import ApproverDashboardPage from './components';

const ApproverDashboard = () => {
  return (
    <CustomLayout>
      <ApproverDashboardPage />
    </CustomLayout>
  );
};

export default ApproverDashboard;
