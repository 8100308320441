import React from 'react';
import { Card, Col, Row } from 'antd';
import leftTriangle from '../../images/leftTriangle.svg';
import flippedTriangle from '../../images/flippedTriangle.svg';
import flippedTriangle1 from '../../images/flippedTriangle1.svg';
import fullSquare from '../../images/fullSquare.svg';
import vrDots from '../../images/vrDots.svg';
import hrDots from '../../images/hrDots.svg';
import btDots from '../../images/btDots.svg';
import styles from './index.module.less';
import CustomButton from '@/common/CustomButton';

import { Link } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';
import InitModal from '../InitModal';

const DashBoardPage = ({
  dropDownLoading,
  check,
  setCheck,
  approverFlow,
  setApproverFlow,
  loiVal,
  setLoiVal,
  setBuId,
  setProjectCode,
  handleGetCheckLimit,
  setProjectId,
  setBuJv,
  setBuName,
  setProjectName,
  allBu,
  allProjects,
  loading,
  onFinish,
  isModalVisible,
  handleCancel,
  showModal,
  stats,
  envelopeTypes
}) => {
  //console.log('DBVAL', loiVal);
  const user = LocalStorage.getItem('LOI_user');

  return (
    <>
      <Card hoverable className={`${styles.upper_card_container}`}>
        <img
          className={`${styles.left_triangle}`}
          src={leftTriangle}
          alt="lt"
        />
        <img className={`${styles.full_square}`} src={fullSquare} alt="fs" />
        <img className={`${styles.hr_dots}`} src={hrDots} alt="hd" />

        <Row>
          <Col flex={2}>
            <div className={`df-sb-ac ${styles.left_greeting}`}>
              Hi{' '}
              <b>{user?.user?.givenName?.split(' ').slice(0, 1).join(' ')}!</b>
            </div>
          </Col>
          {stats.map((item, index) => {
            const { label, value, link } = item;
            return (
              <>
                <Col
                  key={label}
                  flex={label === 'Rejected' || label === 'Drafts' ? 3 : 2}
                >
                  <Link to={link}>
                    <Col
                      span={2}
                      className={`font-14   ${styles.upper_card_right_side}`}
                    >
                      {label}
                    </Col>

                    <Col className={` ${styles.upper_card_right_side_b}`}>
                      {value}
                    </Col>
                  </Link>
                </Col>
                {index != stats.length - 1 ? (
                  <div className={`${styles.upper_card_divider}`} />
                ) : null}
              </>
            );
          })}
        </Row>
        <img
          className={`${styles.flipped_triangle1}`}
          src={flippedTriangle}
          alt="ft"
        />
        <img
          className={`${styles.flipped_triangle2}`}
          src={flippedTriangle1}
          alt="ft1"
        />
        <img className={`${styles.vr_dots}`} src={vrDots} alt="vd" />
        <img className={`${styles.bt_dots}`} src={btDots} alt="vd" />
      </Card>

      <Card hoverable className={` ${styles.lower_card_container} mt-3`}>
        <Row gutter={[48, 16]} className={`df-jc-ac`}>
          {envelopeTypes.map((item, index) => {
            const { title, subText, btnText, image } = item;

            return (
              <>
                <Col key={title} className={`df-jc-ac p-3`} span={7}>
                  <div className={`${styles.item}`}>
                    <img src={image} />
                    <span className={`${styles.caption}`}>{title}</span>
                    <span className={`${styles.description}`}>{subText}</span>
                    <CustomButton
                      onClick={showModal}
                      className={`${styles.lower_card_button}`}
                    >
                      {btnText}
                    </CustomButton>
                  </div>
                </Col>
                {index != envelopeTypes.length - 1 ? (
                  <div className={`${styles.vertical_divider}`} />
                ) : null}
              </>
            );
          })}
        </Row>
      </Card>
      {isModalVisible ? (
        <InitModal
          dropDownLoading={dropDownLoading}
          check={check}
          setCheck={setCheck}
          approverFlow={approverFlow}
          setApproverFlow={setApproverFlow}
          loiVal={loiVal}
          setLoiVal={setLoiVal}
          setBuId={setBuId}
          setProjectCode={setProjectCode}
          handleGetCheckLimit={handleGetCheckLimit}
          setProjectId={setProjectId}
          setBuJv={setBuJv}
          setBuName={setBuName}
          setProjectName={setProjectName}
          allBu={allBu}
          allProjects={allProjects}
          handleCancel={handleCancel}
          isModalVisible={isModalVisible}
          onFinish={onFinish}
          loading={loading}
        />
      ) : null}
    </>
  );
};

export default DashBoardPage;
