import CustomLayout from '@/common/Layout';
import React from 'react';
import ReportsPage from './components';

function Reports() {
  return (
    <CustomLayout sider={false} tracker={false}>
      <ReportsPage />
    </CustomLayout>
  );
}

export default Reports;
