import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { allLetters, env_flow, history } from './api';

const initialState = {
  Letters: [],
  history_data: [],
  envelope_flow: []
};

const actions = {
  GETALLLETTERS: 'getAllLetters/GETALLLETTERS',
  GET_HISTORY: 'getHistoryList/GET_HISTORY',
  GET_ENV_FLOW: 'getEnvFlow/GET_ENV_FLOW'
};

export const getAllLetters = createAsyncThunk(
  actions.GETALLLETTERS,
  async (payload) => {
    const response = await allLetters(payload);
    return response;
  }
);

export const getHistoryList = createAsyncThunk(
  actions.GET_HISTORY,
  async (payload) => {
    const response = await history(payload);
    return response;
  }
);
export const getEnvFlow = createAsyncThunk(
  actions.GET_ENV_FLOW,
  async (payload) => {
    const response = await env_flow(payload);
    return response;
  }
);

export const getAllLettersSlice = createSlice({
  name: 'adminManage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLetters.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLetters.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.Letters = data ? data : [];
        //console.log(data);
      })
      .addCase(getAllLetters.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getHistoryList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHistoryList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.history_data = data ? data : [];
      })
      .addCase(getHistoryList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getEnvFlow.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEnvFlow.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.envelope_flow = data ? data : [];
      })
      .addCase(getEnvFlow.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = getAllLettersSlice.actions; */
/* export const { resetTrackers } = getAllLettersSlice.actions; */

export default getAllLettersSlice.reducer;
