import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { allTrackers, share_Vendor } from './api';

const initialState = {
  all_completed: []
};

const actions = {
  GETCOMPLETEDLIST: 'getCompletedList/GETCOMPLETEDLIST',
  SHARE_VENDOR: 'shareVendor/SHARE_VENDOR'
};

export const getCompletedList = createAsyncThunk(
  actions.GETCOMPLETEDLIST,
  async () => {
    const response = await allTrackers();
    return response;
  }
);

export const shareVendor = createAsyncThunk(
  actions.SHARE_VENDOR,
  async (payload) => {
    const response = await share_Vendor(payload);
    return response;
  }
);

export const getCompletedListSlice = createSlice({
  name: 'completedList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompletedList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompletedList.fulfilled, (state, action) => {
        const { data } = action.payload;
        //console.log('COMPLTED', data);
        state.all_completed = data;
      })
      .addCase(getCompletedList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(shareVendor.pending, (state) => {
        state.approveLetterLoading = true;
      })
      .addCase(shareVendor.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.approveLetterLoading = false;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(shareVendor.rejected, (state) => {
        state.approveLetterLoading = false;
      });
  }
});

/* export const getTrackersActions = getCompletedListSlice.actions; */
/* export const { resetTrackers } = getCompletedListSlice.actions; */

export default getCompletedListSlice.reducer;
