import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  activateDeactivate,
  allProjects,
  blockUnblock,
  createProject,
  editProjects,
  project_ById,
  updateCb
} from './api';

const initialState = {
  byIdLoading: false,
  all_Doa_List: [],
  all_Projects: [],
  all_SBUs: [],
  all_approvers: [],
  all_designations: [],
  all_designationMappings: [],
  approverById: {},
  projectById: {},
  designationById: {},
  designationMappingById: {},
  sbuById: {},
  category_by_id: {},
  all_subcategories: [],
  subcategory_by_id: {},
  tableLoading: false,
  pagination: null,
  sbu_pagination: null,
  designation_pagination: null
};

const actions = {
  GETALLAPROVERSLIST: 'getAllApproversList/GETALLAPROVERSLIST',
  GETALLDOALIST: 'getAllDoaList/GETALLDOALIST',
  GETALLPROJECTS: 'getAllProjects/GETALLPROJECTS',
  GETALLSBUS: 'getAllSbus/GETALLSBUS',
  GETALLSUBCATGEORIES: 'getAllSubCategories/GETALLSUBCATGEORIES',
  GETAPROVERBYID: 'getApproverById/GETAPROVERBYID',
  GETDESIGNATIONBYID: 'getDesignationById/GETDESIGNATIONBYID',
  GETPROJECTBYID: 'getProjectById/GETPROJECTBYID',
  GETALLDESIGNATIONSLIST: 'getAllDesignationsList/GETALLDESIGNATIONSLIST',
  GETSBUBYID: 'getSbuById/GETSBUBYID',

  GETCATEGORYBYID: 'getCategoryById/GETCATEGORYBYID',
  GETSUBCATEGORYBYID: 'getSubCategoryById/GETSUBCATEGORYBYID',
  CREATESBU: 'createSbu/CREATESBU',
  CREATEAPPROVERS: 'createApprovers/CREATEAPPROVERS',
  CREATEDESIGNATIONS: 'createDesignations/CREATEDESIGNATIONS',
  GETDESIGNATIONMAPPINGBYID:
    'getDesignationMappingById/GETDESIGNATIONMAPPINGBYID',
  GETALLDESIGNATIONMAPPINGSLIST:
    'getAllDesignationMappingsList/GETALLDESIGNATIONMAPPINGSLIST',
  CREATEDESIGNATIONMAPPINGS:
    'createDesignationMappings/CREATEDESIGNATIONMAPPINGS',
  CREATEPROJECTS: 'createProjects/CREATEPROJECTS',
  CREATECATEGORY: 'createCategory/CREATECATEGORY',
  CREATESUBCATEGORY: 'createSubCategory/CREATESUBCATEGORY',
  CREATEDOAFLOW: 'createDoaFlow/CREATEDOAFLOW',
  BLOCKPROJECT: 'blockProject/BLOCKPROJECT',
  ACTIVATEPROJECT: 'activateProject/ACTIVATEPROJECT',
  EDITPROJECT: 'editProject/EDITPROJECT',
  UPDATECONTORLBUDGET: 'updateControlBudget/UPDATECONTROLBUDGET'
};

// export const getAllApproversList = createAsyncThunk(
//   actions.GETALLAPROVERSLIST,
//   async (payload) => {
//     const response = await allApprovers(payload);
//     return response;
//   }
// );

// export const getAllDoaList = createAsyncThunk(
//   actions.GETALLDOALIST,
//   async (payload) => {
//     const response = await allDoaFlows(payload);
//     return response;
//   }
// );

// export const getAllSbus = createAsyncThunk(
//   actions.GETALLSBUS,
//   async (payload) => {
//     const response = await allSBUs(payload);
//     return response;
//   }
// );

// export const getAllSubCategories = createAsyncThunk(
//   actions.GETALLSUBCATGEORIES,
//   async (payload) => {
//     const response = await allSubCategories(payload);
//     return response;
//   }
// );

export const getAllProjects = createAsyncThunk(
  actions.GETALLPROJECTS,
  async (payload) => {
    const response = await allProjects(payload);
    return response;
  }
);

export const getProjectById = createAsyncThunk(
  actions.GETPROJECTBYID,
  async (payload) => {
    const response = await project_ById(payload);
    return response;
  }
);

// export const getApproverById = createAsyncThunk(
//   actions.GETAPROVERBYID,
//   async (payload) => {
//     const response = await approver_ById(payload);
//     return response;
//   }
// );

// export const getDesignationById = createAsyncThunk(
//   actions.GETDESIGNATIONBYID,
//   async (payload) => {
//     const response = await designation_ById(payload);
//     return response;
//   }
// );

// export const getSbuById = createAsyncThunk(
//   actions.GETSBUBYID,
//   async (payload) => {
//     const response = await SBU_ById(payload);
//     return response;
//   }
// );

// export const getCategoryById = createAsyncThunk(
//   actions.GETCATEGORYBYID,
//   async (payload) => {
//     const response = await categories_ById(payload);
//     return response;
//   }
// );

// export const getSubCategoryById = createAsyncThunk(
//   actions.GETSUBCATEGORYBYID,
//   async (payload) => {
//     const response = await subcategories_ById(payload);
//     return response;
//   }
// );

// export const getAllDesignationsList = createAsyncThunk(
//   actions.GETALLDESIGNATIONSLIST,
//   async (payload) => {
//     const response = await allDesignations(payload);
//     return response;
//   }
// );

// export const getDesignationMappingById = createAsyncThunk(
//   actions.GETDESIGNATIONMAPPINGBYID,
//   async (payload) => {
//     const response = await designationMappings_ById(payload);
//     return response;
//   }
// );

// export const getAllDesignationMappingsList = createAsyncThunk(
//   actions.GETALLDESIGNATIONMAPPINGSLIST,
//   async (payload) => {
//     const response = await allDesignationsMappings(payload);
//     return response;
//   }
// );

// export const createApprovers = createAsyncThunk(
//   actions.CREATEAPPROVERS,
//   async (payload) => {
//     const response = await create_Approvers(payload);
//     return response;
//   }
// );

// export const createDesignations = createAsyncThunk(
//   actions.CREATEDESIGNATIONS,
//   async (payload) => {
//     const response = await create_Designations(payload);
//     return response;
//   }
// );

// export const createDesignationMappings = createAsyncThunk(
//   actions.CREATEDESIGNATIONMAPPINGS,
//   async (payload) => {
//     const response = await create_DesignationMappings(payload);
//     return response;
//   }
// );

export const createProjects = createAsyncThunk(
  actions.CREATEPROJECTS,
  async (payload) => {
    const response = await createProject(payload);
    return response;
  }
);

// export const createDoaFlow = createAsyncThunk(
//   actions.CREATEDOAFLOW,
//   async (payload) => {
//     const response = await create_DOA_Flow(payload);
//     return response;
//   }
// );

// export const createSbu = createAsyncThunk(
//   actions.CREATESBU,
//   async (payload) => {
//     const response = await create_SBUs(payload);
//     return response;
//   }
// );

// export const createCategory = createAsyncThunk(
//   actions.CREATECATEGORY,
//   async (payload) => {
//     const response = await create_Categories(payload);
//     return response;
//   }
// );

// export const createSubCategory = createAsyncThunk(
//   actions.CREATESUBCATEGORY,
//   async (payload) => {
//     const response = await create_SubCategories(payload);
//     return response;
//   }
// );

export const blockProject = createAsyncThunk(
  actions.BLOCKPROJECT,
  async (payload) => {
    const response = await blockUnblock(payload.url, payload.payload);
    return response;
  }
);

export const activateProject = createAsyncThunk(
  actions.ACTIVATEPROJECT,
  async (formData) => {
    const response = await activateDeactivate(formData.url, formData.payload);
    return response; // Assuming your response has a "data" property
  }
);

export const editProject = createAsyncThunk(
  actions.EDITPROJECT,
  async (formData) => {
    const response = await editProjects(formData.url, formData.payload);
    return response; // Assuming your response has a "data" property
  }
);

export const updateControlBudget = createAsyncThunk(
  actions.UPDATECONTORLBUDGET,
  async (formData) => {
    const response = await updateCb(formData.url, formData.payload);
    return response;
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder
    //   .addCase(getAllApproversList.pending, (state) => {
    //     state.tableLoading = true;
    //   })
    //   .addCase(getAllApproversList.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     console.log('AAAA', data);
    //     state.all_approvers = data.data;
    //     state.sbu_pagination = data.pagination || null;
    //     state.tableLoading = false;
    //   })
    //   .addCase(getAllApproversList.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.tableLoading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(getAllDoaList.pending, (state) => {
    //     state.tableLoading = true;
    //   })
    //   .addCase(getAllDoaList.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     state.all_Doa_List = data.data;
    //     state.pagination = data.pagination || null;
    //     state.tableLoading = false;
    //   })
    //   .addCase(getAllDoaList.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.tableLoading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(getAllSbus.pending, (state) => {
    //     state.tableLoading = true;
    //   })
    //   .addCase(getAllSbus.fulfilled, (state, action) => {
    //     const { data, pagination } = action.payload;
    //     console.log('AAAA', data);
    //     state.all_SBUs = data;
    //     state.tableLoading = false;
    //     state.sbu_pagination = pagination || null;
    //   })
    //   .addCase(getAllSbus.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.tableLoading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(getAllSubCategories.pending, (state) => {
    //     state.tableLoading = true;
    //   })
    //   .addCase(getAllSubCategories.fulfilled, (state, action) => {
    //     const { data, pagination } = action.payload;
    //     console.log('AAAA', data);
    //     state.all_subcategories = data;
    //     state.tableLoading = false;
    //     state.sbu_pagination = pagination || null;
    //   })
    //   .addCase(getAllSubCategories.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.tableLoading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(getApproverById.pending, (state) => {
    //     state.byIdLoading = true;
    //   })
    //   .addCase(getApproverById.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     console.log('AAAA', data);
    //     state.approverById = data;
    //     state.byIdLoading = false;
    //   })
    //   .addCase(getApproverById.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.byIdLoading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(getSbuById.pending, (state) => {
    //     state.byIdLoading = true;
    //   })
    //   .addCase(getSbuById.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     console.log('AAAA', data);
    //     state.sbuById = data;
    //     state.byIdLoading = false;
    //   })
    //   .addCase(getSbuById.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.byIdLoading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(getCategoryById.pending, (state) => {
    //     state.byIdLoading = true;
    //   })
    //   .addCase(getCategoryById.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     console.log('AAAA', data);
    //     state.category_by_id = data;
    //     state.byIdLoading = false;
    //   })
    //   .addCase(getCategoryById.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.byIdLoading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(getSubCategoryById.pending, (state) => {
    //     state.byIdLoading = true;
    //   })
    //   .addCase(getSubCategoryById.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     console.log('AAAA', data);
    //     state.subcategory_by_id = data;
    //     state.byIdLoading = false;
    //   })
    //   .addCase(getSubCategoryById.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.byIdLoading = false;

    //     message.error(msg);
    //   });

    builder
      .addCase(getProjectById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getProjectById.fulfilled, (state, action) => {
        const { data } = action.payload;
        console.log('AAAA', data);
        state.projectById = data;
        state.byIdLoading = false;
      })
      .addCase(getProjectById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });

    // builder
    //   .addCase(getDesignationById.pending, (state) => {
    //     state.byIdLoading = true;
    //   })
    //   .addCase(getDesignationById.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     console.log('AAAA', data);
    //     state.designationById = data;
    //     state.byIdLoading = false;
    //   })
    //   .addCase(getDesignationById.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.byIdLoading = false;

    //     message.error(msg);
    //   });

    builder
      .addCase(getAllProjects.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        console.log('AAAA', data);
        state.all_Projects = data;
        state.tableLoading = false;
        state.pagination = pagination || null;
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });

    // builder
    //   .addCase(getDesignationMappingById.pending, (state) => {
    //     state.tableLoading = true;
    //   })
    //   .addCase(getDesignationMappingById.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     console.log('AAAA', data);
    //     state.designationMappingById = data;
    //   })
    //   .addCase(getDesignationMappingById.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.tableLoading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(getAllDesignationsList.pending, (state) => {
    //     state.tableLoading = true;
    //   })
    //   .addCase(getAllDesignationsList.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     console.log('AAAA', data);
    //     state.sbu_pagination = data.pagination || null;
    //     state.all_designations = data.data;

    //     state.tableLoading = false;
    //   })
    //   .addCase(getAllDesignationsList.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.tableLoading = false;

    //     message.error(msg);
    //   });
    // builder
    //   .addCase(getAllDesignationMappingsList.pending, (state) => {
    //     state.tableLoading = true;
    //   })
    //   .addCase(getAllDesignationMappingsList.fulfilled, (state, action) => {
    //     const { data } = action.payload;
    //     console.log('AAAA', data);
    //     state.all_designationMappings = data.data;
    //     state.designation_pagination = data.pagination || null;
    //     state.tableLoading = false;
    //   })
    //   .addCase(getAllDesignationMappingsList.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.tableLoading = false;

    //     message.error(msg);
    //   });
    // builder
    //   .addCase(createApprovers.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(createApprovers.fulfilled, (state) => {
    //     state.loading = false;
    //   })
    //   .addCase(createApprovers.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.loading = false;

    //     message.error(msg);
    //   });
    // builder
    //   .addCase(createDesignations.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(createDesignations.fulfilled, (state) => {
    //     state.loading = false;
    //   })
    //   .addCase(createDesignations.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.loading = false;

    //     message.error(msg);
    //   });
    // builder
    //   .addCase(createDesignationMappings.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(createDesignationMappings.fulfilled, (state) => {
    //     state.loading = false;
    //   })
    //   .addCase(createDesignationMappings.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.loading = false;

    //     message.error(msg);
    //   });
    builder
      .addCase(createProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProjects.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createProjects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    // builder
    //   .addCase(createCategory.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(createCategory.fulfilled, (state) => {
    //     state.loading = false;
    //   })
    //   .addCase(createCategory.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.loading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(createSubCategory.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(createSubCategory.fulfilled, (state) => {
    //     state.loading = false;
    //   })
    //   .addCase(createSubCategory.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.loading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(createDoaFlow.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(createDoaFlow.fulfilled, (state) => {
    //     state.loading = false;
    //   })
    //   .addCase(createDoaFlow.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.loading = false;

    //     message.error(msg);
    //   });

    builder
      .addCase(blockProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(blockProject.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(blockProject.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(activateProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(activateProject.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(activateProject.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(editProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(editProject.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editProject.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = settingsSlice.actions; */
/* export const { resetTrackers } = settingsSlice.actions; */

export default settingsSlice.reducer;
