import Layout from '@/common/Layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';
import {
  getAllBu,
  getAllProjects,
  getCheckLimit
} from '../initiate-loi/redux/slice';
import DashBoardPage from './components/DashBoardPage';
import ADHOC from './images/ADHOC.svg';
import { getAllStats } from './redux/slice';
import { notification } from 'antd';

const DashBoard = () => {
  const { allStats } = useSelector((state) => state.dashboard);
  const checkLimit = LocalStorage.getItem('checkLimit');
  const { allBu, allProjects } = useSelector((state) => state.createLOI);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [approverFlow, setApproverFlow] = useState('');
  const [buJv, setBuJv] = useState();
  const [loiVal, setLoiVal] = useState();
  const [check, setCheck] = useState();
  const [buName, setBuName] = useState();
  const [dropDownLoading, setDropDownLoading] = useState(true);

  const [buId, setBuId] = useState();
  const [projectId, setProjectId] = useState();
  const [projectCode, setProjectCode] = useState();
  const [projectName, setProjectName] = useState();

  const onFinish = (values) => {
    localStorage.setItem(
      'formValues',
      JSON.stringify({
        project_name: values.project_name,
        loi_Value: values.loi_Value,
        bu: values.bu,
        controlBudget:
          checkLimit?.isApproved === true ? 'yes' : values?.controlBudget,
        projectId: projectId?.id,
        projectCode: projectCode?.projectCode,
        buId: buId?.buId
      })
    );

    navigate('/initiate-loi');
  };

  useEffect(() => {
    handleGetAllBu();
  }, [buName]);

  useEffect(() => {
    handleGetAllProjects();
  }, [projectName, buJv]);

  useEffect(() => {
    handleGetCheckLimit();
  }, [loiVal, projectId]);

  const handleGetCheckLimit = () => {
    const payload = { projectId: projectId?.id, loiValue: loiVal };
    dispatch(getCheckLimit(payload)).then((response) => {
      //console.log(response.payload.data);
      setCheck(response.payload.data);
      //console.log('CHECK', check);
      LocalStorage.setItem('checkLimit', JSON.stringify(response.payload.data));
    });
  };
  const handleGetAllProjects = () => {
    const payload = {
      buId: buJv,
      search: projectName !== undefined ? projectName : '',
      offset: 0,
      limit: 50,
      fetch: 'active'
    };
    dispatch(getAllProjects(payload)).then((response) => {
      if (response.payload.success === true) {
        setDropDownLoading(false);
      }
    });
  };

  const handleGetAllBu = () => {
    const payload = {
      search: buName !== undefined ? buName : '',
      offset: 0,
      limit: 50
    };
    dispatch(getAllBu(payload)).then((response) => {
      if (response.payload.success === true) {
        setDropDownLoading(false);
      }
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    notification.destroy();
    localStorage.removeItem('checkLimit');
    setProjectId();
    setCheck();
    setIsModalVisible(false);
    setBuJv();
    setBuName();
    setLoiVal();
    setProjectName();
  };
  //console.log(allStats);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
  }, []);
  const handleGetStats = () => {
    dispatch(getAllStats());
  };
  const envelopeTypes = [
    {
      title: 'Initiate LoI/LoA',
      subText:
        'Create LoI/LoA documents with standard and customisable agreement clauses',
      btnText: 'Initiate LoI/LoA',
      image: ADHOC
    }
  ];

  const stats = [
    {
      key: 1,
      label: 'Under Approval',
      value: allStats?.waiting_for_others || 0,
      link: '/waiting-for-others'
    },
    {
      key: 2,
      label: 'Approved LoA/LoIs',
      value: allStats?.completed || 0,
      link: '/requester-approved'
    },

    {
      key: 3,
      label: 'Rejected',
      value: allStats?.rejected || 0,
      link: '/rejected'
    },
    {
      key: 4,
      label: 'Drafts',
      value: allStats?.draftsCount || 0,
      link: '/drafts'
    }
  ];
  return (
    <>
      <Layout sider={false}>
        <DashBoardPage
          dropDownLoading={dropDownLoading}
          check={check}
          setCheck={setCheck}
          approverFlow={approverFlow}
          setApproverFlow={setApproverFlow}
          loiVal={loiVal}
          setLoiVal={setLoiVal}
          setBuId={setBuId}
          projectCode={projectCode}
          setProjectCode={setProjectCode}
          handleGetCheckLimit={handleGetCheckLimit}
          setProjectId={setProjectId}
          setBuJv={setBuJv}
          setBuName={setBuName}
          setProjectName={setProjectName}
          allBu={allBu}
          allProjects={allProjects}
          onFinish={onFinish}
          handleCancel={handleCancel}
          isModalVisible={isModalVisible}
          showModal={showModal}
          stats={stats}
          envelopeTypes={envelopeTypes}
        />
      </Layout>
    </>
  );
};

export default DashBoard;
