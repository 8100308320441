import FormItem from '@/common/FormItem';
import {
  CaretDownFilled,
  DeleteOutlined,
  MinusCircleFilled,
  PlusCircleFilled
} from '@ant-design/icons';

import { Col, Form, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Option } from 'antd/lib/mentions';
import React from 'react';
import styles from '../../InitiateLOI1/index.module.less';

const UpdatedContractPerformance = ({
  formValues,
  setFormValues,
  item,
  contractPerformance,
  index,
  handleDeleteContractPerformance,
  contractItem,
  setContractPerformance,
  contractIndex,
  defaultClauses,
  itemNumber
}) => {
  const handleChange = (type, value, inputName) => {
    const tempContracts = [...contractPerformance];
    tempContracts[contractIndex][type] = value;
    if (value === 'PBGApplicable') {
      tempContracts[contractIndex].clauses = [
        {
          field1: defaultClauses?.contract?.defaultClause1,
          field2: defaultClauses?.contract?.defaultClause2
        }
      ];
    }
    setContractPerformance(tempContracts);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: value
      }
    });
  };

  const handleClauseChange = (
    contractIndex,
    clauseIndex,
    type,
    val,
    inputName
  ) => {
    const tempContracts = [...contractPerformance];
    tempContracts[contractIndex].clauses[clauseIndex][type] = val;
    setContractPerformance(tempContracts);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
  };

  const handleAddClause = () => {
    const tempContracts = [...contractPerformance];
    tempContracts[contractIndex].clauses.push({
      field1: defaultClauses?.contract?.defaultClause1,
      field2: defaultClauses?.contract?.defaultClause2
    });
    setContractPerformance(tempContracts);
  };

  const handleDeleteClause = (clauseIndex) => {
    const tempContracts = [...contractPerformance];
    tempContracts[contractIndex].clauses.splice(clauseIndex, 1);
    setContractPerformance(tempContracts);
  };

  const clauses = contractItem?.clauses || [];

  return (
    <>
      <Col className="font-18 sfprotext-bold mt-4 mb-2" span={24}>
        {` Contract Performance ${itemNumber}`}
      </Col>
      <Row className={`${styles.grey_holder}   w-100`}>
        <Col className="gutter-row ml-3" span={8}>
          <FormItem
            label="Performance bank Guarantee "
            name={`contract_select_${contractIndex}`}
            rules={[{ required: true, message: 'Please select' }]}
          >
            <Select
              value={contractItem.pbgApplicable}
              onChange={(value) =>
                handleChange(
                  'pbgApplicable',
                  value,
                  `contract_select_${contractIndex}`
                )
              }
              suffixIcon={<CaretDownFilled />}
              placeholder="Select"
            >
              <Option value="PBGApplicable">Applicable</Option>
              <Option value="PBGNot Applicable">Not Applicable</Option>
            </Select>
          </FormItem>
        </Col>{' '}
        <Col span={15}>
          {itemNumber > 1 ? (
            <div
              onClick={() =>
                handleDeleteContractPerformance(
                  index,
                  item,
                  `contract_select_${contractIndex}`
                )
              }
              className={` cursor-pointer ${styles.delete_holder}`}
            >
              <DeleteOutlined />
            </div>
          ) : null}
        </Col>
        {contractItem.pbgApplicable === 'PBGApplicable' && (
          <>
            <Form.List
              initialValue={clauses}
              name={`contract${contractIndex}_clauses`}
            >
              {(fields, { add, remove }) => {
                return (
                  <div style={{ width: '100%' }}>
                    {fields.map((field, clauseIndex) => (
                      <>
                        <Row key={field.key}>
                          <Col className="gutter-row ml-3" span={20}>
                            <Form.Item
                              name={[field.name, 'field1']}
                              fieldKey={[field.fieldKey, 'field1']}
                              rules={[
                                { required: true, message: 'Please enter' }
                              ]}
                            >
                              <TextArea
                                defaultValue={
                                  clauses.field1 ||
                                  defaultClauses?.contract?.defaultClause1
                                }
                                onChange={(e) =>
                                  handleClauseChange(
                                    contractIndex,
                                    clauseIndex,
                                    'field1',
                                    e.target.value,
                                    `contract_field1${contractIndex}_clause_${clauseIndex}`
                                  )
                                }
                                autoSize
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row ml-3" span={20}>
                            <Form.Item
                              name={[field.name, 'field2']}
                              fieldKey={[field.fieldKey, 'field2']}
                              rules={[
                                { required: true, message: 'Please enter' }
                              ]}
                            >
                              <TextArea
                                defaultValue={
                                  clauses[clauseIndex] ||
                                  defaultClauses?.contract?.defaultClause2
                                }
                                onChange={(e) =>
                                  handleClauseChange(
                                    contractIndex,
                                    clauseIndex,
                                    'field2',
                                    e.target.value,
                                    `contract_field2${contractIndex}_clause_${clauseIndex}`
                                  )
                                }
                                autoSize
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            {fields.length > 1 ? (
                              <span
                                onClick={() => {
                                  remove(field.name);
                                  handleDeleteClause(clauseIndex);
                                }}
                                className={`  pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                              >
                                <MinusCircleFilled
                                  style={{ color: '#D72D30' }}
                                />
                              </span>
                            ) : null}
                          </Col>
                        </Row>
                      </>
                    ))}
                    <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
                      <Form.Item>
                        <Col className={`${styles.add_clause}  mb-3`} span={24}>
                          <span
                            onClick={() => {
                              add({
                                field1:
                                  defaultClauses?.contract?.defaultClause1,
                                field2: defaultClauses?.contract?.defaultClause2
                              });
                              handleAddClause();
                            }}
                            className="cursor-pointer"
                          >
                            <PlusCircleFilled className="mr-1" /> Add clause
                          </span>
                        </Col>
                      </Form.Item>
                    </Col>
                  </div>
                );
              }}
            </Form.List>

            {/* {clauses.map((cls, clauseIndex) => (
              <>
                <Col className="gutter-row ml-3" span={20}>
                  <FormItem
                    rules={[{ required: true, message: 'Please enter' }]}
                    name={`contract_field1${contractIndex}_clause_${clauseIndex}`}
                  >
                    <div>
                      <TextArea
                        value={cls['field1']}
                        onChange={(e) =>
                          handleClauseChange(
                            contractIndex,
                            clauseIndex,
                            'field1',
                            e.target.value,
                            `contract_field1${contractIndex}_clause_${clauseIndex}`
                          )
                        }
                        autoSize
                        defaultValue={
                          defaultClauses?.contract?.defaultClause1 || ''
                        }
                      />
                    </div>
                  </FormItem>
                </Col>

                <Col className="gutter-row ml-3" span={20}>
                  <FormItem
                    rules={[{ required: true, message: 'Please enter' }]}
                    name={`contract_field2${contractIndex}_clause_${clauseIndex}`}
                  >
                    <div>
                      <TextArea
                        value={cls['field2']}
                        onChange={(e) =>
                          handleClauseChange(
                            contractIndex,
                            clauseIndex,
                            'field2',
                            e.target.value,
                            `contract_field2${contractIndex}_clause_${clauseIndex}`
                          )
                        }
                        autoSize
                        defaultValue={
                          defaultClauses?.contract?.defaultClause2 || ''
                        }
                      />
                    </div>
                  </FormItem>
                </Col>
                {clauses.length > 1 && (
                  <Col span={2}>
                    <div
                      onClick={() => handleDeleteClause(clauseIndex)}
                      className={` pull-left ml-3 cursor-pointer ${styles.delete_holder}`}
                    >
                      <MinusCircleFilled style={{ color: '#D72D30' }} />
                    </div>
                  </Col>
                )}
              </>
            ))}
            <Col className={`${styles.add_clause} ml-3 mb-3`} span={24}>
              <span onClick={handleAddClause} className="cursor-pointer">
                <PlusCircleFilled className="mr-1" /> Add clause
              </span>
            </Col> */}
          </>
        )}
      </Row>
    </>
  );
};

export default UpdatedContractPerformance;
