import FormItem from '@/common/FormItem';
import { CaretDownFilled, DeleteOutlined } from '@ant-design/icons';
import { Col, Select } from 'antd';
import React from 'react';
import styles from '../InitiateLOI1/index.module.less';

const { Option } = Select;

const Payments = ({
  formValues,
  // form,
  index,
  item,
  handleDeletePayment,
  setPayments,
  payments,
  defaultClauses,
  setFormValues,
  itemNumber
}) => {
  const handleTypeChange = (val, inputName) => {
    const tempPayments = [...payments];
    tempPayments[index].type = val;
    switch (val) {
      case 'Advance':
        tempPayments[index].clauses = [
          defaultClauses?.paymentAdvance?.defaultClause || ''
        ];
        break;
      case 'Progress':
        tempPayments[index].clauses = [
          defaultClauses?.paymentProgress?.defaultClause || ''
        ];
        break;
      case 'Retention':
        tempPayments[index].clauses = [
          defaultClauses?.paymentRetention?.defaultClause || ''
        ];
        break;
      default:
        break;
    }
    setPayments(tempPayments);
    setFormValues({
      ...formValues,
      dynamicFields: {
        ...(formValues.dynamicFields || {}),
        [inputName]: val
      }
    });
    //console.log('Temp', tempPayments);
  };

  return (
    <>
      <Col className="ml-3 gutter-row" span={9}>
        <FormItem
          label={`Payment Terms & Condtions ${itemNumber}`}
          rules={[{ required: true, message: 'Please select' }]}
          name={`payment_options_${index}`}
        >
          <Select
            value={item.type}
            onChange={(value) =>
              handleTypeChange(value, `payment_options_${index}`)
            }
            suffixIcon={<CaretDownFilled />}
            placeholder="Select payment type"
          >
            <Option value="Advance">Advance</Option>
            <Option value="Progress">Progress</Option>
            <Option value="Retention">Retention</Option>
          </Select>
        </FormItem>
      </Col>
      <Col span={14}>
        {itemNumber > 1 ? (
          <div
            onClick={() =>
              handleDeletePayment(index, item, `payment_options_${index}`)
            }
            className={` cursor-pointer ${styles.delete_holder}`}
          >
            <DeleteOutlined />
          </div>
        ) : null}
      </Col>
    </>
  );
};

export default Payments;
