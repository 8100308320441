import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllApprovers } from '../initiate-loi/redux/slice';
import { getHistoryList } from '../requester-manage/redux/slice';
import ApproverReviewPage from './components';
import { getfilebyid, getOtp } from './redux/slice';

function CFOReview() {
  const { allApprovers } = useSelector((state) => state.createLOI);
  const { all_file_data, otp } = useSelector((state) => state.reviewActions);
  const { history_data } = useSelector((state) => state.adminManage);
  const [modalVisible, setModalVisible] = useState('');
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    handleGetfileById();
    handleHistory();
    handleGetAllApprovers();
  }, []);
  const handleGetfileById = () => {
    dispatch(getfilebyid(params.id));
  };
  const handleHistory = () => {
    dispatch(getHistoryList(params.id));
  };
  const handleGetAllApprovers = () => {
    dispatch(getAllApprovers());
  };

  const sentotp = () => {
    const paylaod = params.id;
    dispatch(getOtp(paylaod));
    setModalVisible('approved');
  };
  return (
    <ApproverReviewPage
      allApprovers={allApprovers}
      handleGetfileById={handleGetfileById}
      history_data={history_data}
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      all_file_data={all_file_data}
      sentotp={sentotp}
      otp={otp}
    />
  );
}

export default CFOReview;
