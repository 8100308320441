import { Layout, message } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './components/InitiateLOI1/index.module.less';

import InitiateLOI2 from './components/InitiateLOI2';
import { useDispatch, useSelector } from 'react-redux';
import {
  createLOI,
  getAllApprovers,
  getAllBu,
  getAllCFO,
  getAllDummy,
  getAllProjects,
  getAllSbgs,
  getAllVendorLocations,
  getAllVendors
} from './redux/slice';
import InitiateLOI1 from './components/InitiateLOI1/index';
import { history } from '@/app/history';
import moment from 'moment';
import { formatDummyData } from './constants';
import LocalStorage from 'utilities/localStorage';
import { ToWords } from 'to-words';

const InitiateLOI = () => {
  const dispatch = useDispatch();

  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: 'Rupee',
        plural: 'Rupees',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paise',
          symbol: ''
        }
      }
    }
  });
  const modalFormValues = LocalStorage.getItem('formValues');
  const checkLimit = LocalStorage.getItem('checkLimit');
  const {
    allVendorLocations,
    allBu,
    allCFO,
    allVendors,
    allProjects,
    allApprovers,
    allSbgs,
    dummyData
  } = useSelector((state) => state.createLOI);
  const getValues = LocalStorage.getItem('formValues');
  console.log('BU', getValues?.bu);
  //console.log('SBGSS', allSbgs);

  const [loi, setLOI] = useState('');

  const [po, setPo] = useState('');
  const [taxDuties, setTaxDuties] = useState('');
  const [vqrd, setVqrd] = useState('');
  const [broadScope, setBroadScope] = useState('');
  const [currency, setCurrency] = useState('');
  const [priceBasis, setPriceBasis] = useState('');
  const [priceBasisText, setPriceBasisText] = useState('');
  const [contractValidity, setContractValidity] = useState('');
  const [isLimitExceeded, setIsLimitExceeded] = useState(
    checkLimit?.isLimitExceeded
  );
  const [emailMessage, setEmailMessage] = useState('');
  const [specialTerms, setSpecialTerms] = useState('');
  const [allTpl, setAllTpl] = useState('');
  const [priceJustification, setPriceJustification] = useState('');
  const [vendorContact, setVendorContact] = useState('');
  const [vendorAddress, setVendorAddress] = useState('');
  const [vendorSite, setVendorSite] = useState('');
  const [initiateDraft, setInitiateDraft] = useState(false);
  const [vendorContactNumber, setVendorContactNumber] = useState('');
  const [quantityVariation, setQuantityVariation] = useState('');
  const [agreementLocation, setAgreementLocation] = useState('');
  const [attachmentList, setAttachmentList] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [vendorName, setVendorName] = useState();
  const [approverName, setApproverName] = useState();
  const [sbgName, setSbgName] = useState();
  const [vendorLocation, setVendorLocation] = useState();
  const [buName, setBuName] = useState();
  const [buJv, setBuJv] = useState();
  const [projectName, setProjectName] = useState();
  const [defaultClauses, setDefaultClauses] = useState({});
  const [cancellationMessage, setCancellationMessage] = useState('');
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [select, setSelect] = useState('');
  const [initiate, setInitiate] = useState(false);
  const [projectId, setProjectId] = useState();
  const [message1, setMessage1] = useState([{ clauses: [''] }]);
  const [message2, setMessage2] = useState([{ clauses: [''] }]);
  const [value, setValue] = useState(false);
  const [minValue, setMinValue] = useState('');
  const [payments, setPayments] = useState([{ clauses: [''] }]);
  const [vendorId, setVendorId] = useState(0);
  const [buId, setBuId] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [advanceMessage, setAdvanceMessage] = useState([]);
  const [retentionMessage, setRetentionMessage] = useState([
    'retention_message_0'
  ]);
  const [retention, setRetention] = useState([{ clauses: [''] }]);
  const [mobilisation, setMobilisation] = useState([{ clauses: [''] }]);
  const [packing, setPacking] = useState([
    {
      clauses: [
        {
          field1: ''
          /*  field2: '' */
        }
      ]
    }
  ]);
  const [delivery, setDelivery] = useState([
    {
      clauses: [
        {
          field1: '',
          field2: ''
        }
      ]
    }
  ]);
  const [warrantyMessage, setWarrantyMessage] = useState([]);
  const [warranty, setWarranty] = useState([{ clauses: [''] }]);
  const [freightMessage, setFreightMessage] = useState([]);
  const [progressPercent1, setProgressPercent1] = useState([]);
  const [mrdtMessage, setMrdtMessage] = useState([]);
  const [mrdt, setMrdt] = useState([{ clauses: [''] }]);
  const [dlp, setDlp] = useState([{ clauses: [''] }]);
  const [contractPerformance, setContractPerformance] = useState([
    {
      clauses: [
        {
          field1: '',
          field2: ''
        }
      ]
    }
  ]);
  const [progressPercent2, setProgressPercent2] = useState([]);
  const [progressMessage, setProgressMessage] = useState([
    'progress_message_0'
  ]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [lcMessage1, setLCMessage1] = useState([]);
  const [ld, setLD] = useState([{ clauses: [''] }]);
  const [lcMessage2, setLCMessage2] = useState([]);
  const [mobilisationMessage, setMobilisationMessage] = useState([]);
  const [taxMessage, setTaxMessage] = useState([]);
  const [deliveryMessage, setDeliveryMessage] = useState([]);
  const [deliveryMessage1, setDeliveryMessage1] = useState([]);
  const [clauseMessage, setClauseMessage] = useState([]);
  const [defectLiabilityMessage1, setDefectLiabilityMessage1] = useState([]);
  const [contractMessage1, setContractMessage1] = useState([]);
  const [contractMessage2, setContractMessage2] = useState([]);
  const [freight, setFreight] = useState([
    { clauses: [defaultClauses?.freight?.defaultClause || ''] }
  ]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [check, setCheck] = useState(false);
  const [acceptance, setAcceptance] = useState(true);
  const [persons, setPersons] = useState([]);
  const [projects, setProjects] = useState([]);
  const [formValues, setformValues] = useState({});
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const [selectedApprovers, setSelectedApprovers] = useState([]);
  const [boq, setBoq] = useState([{ clauses: '' }]);
  const [vendorSites, setVendorSites] = useState([]);
  const [projectCodes, setProjectCodes] = useState([]);
  const [emailreg, setEmailreg] = useState(false);
  const [clauseCount, setClauseCount] = useState(0);
  const [isDraft, setIsDraft] = useState(false);
  const [loiMessage, setLOIMessage] = useState(
    defaultClauses?.loiMessage?.defaultClause
  );
  const [loaMessage, setLOAMessage] = useState(
    defaultClauses?.loaMessage?.defaultClause
  );
  //console.log('NEW', defaultClauses?.loiMessage?.defaultClause);
  const handleGetAllApprovers = () => {
    dispatch(getAllApprovers());
  };

  const handleGetAllSBGS = () => {
    dispatch(getAllSbgs());
  };

  const handleGetAllBu = () => {
    const payload = {
      search: buName !== undefined ? buName : '',
      offset: 0,
      limit: 50
    };
    dispatch(getAllBu(payload));
  };

  const handleGetAllCFO = () => {
    dispatch(getAllCFO()).then((response) => {
      if (response.payload.success === true) {
        setIsLoading(false);
      }
    });
  };

  const handleGetAllProjects = () => {
    const payload = {
      buId: buJv,
      search: projectName !== undefined ? projectName : '',
      offset: 0,
      limit: 50
    };
    dispatch(getAllProjects(payload));
  };
  const handleGetAllVendors = () => {
    const payload = {
      search: vendorName !== undefined ? vendorName : '',
      offset: 0,
      limit: 50
    };
    dispatch(getAllVendors(payload));
  };

  const handleGetAllVendorLocations = () => {
    const payload = {
      vendorName: vendorName,
      search: vendorLocation !== undefined ? vendorLocation : '',
      offset: 0,
      limit: 50
    };
    dispatch(getAllVendorLocations(payload));
  };

  const handleGetAllDummyData = () => {
    dispatch(getAllDummy());
  };

  useEffect(() => {
    handleGetAllApprovers();
    handleGetAllCFO();
    handleGetAllSBGS();
    handleGetAllDummyData();
  }, []);

  useEffect(() => {
    handleGetAllVendorLocations();
  }, [vendorName]);

  useEffect(() => {
    handleGetAllVendors();
  }, [vendorName]);

  useEffect(() => {
    handleGetAllBu();
  }, [buName]);

  useEffect(() => {
    handleGetAllProjects();
  }, [projectName, buJv]);

  useEffect(() => {
    if (dummyData) {
      setDefaultClauses(formatDummyData(dummyData));
    }
  }, [dummyData]);

  const [current, setCurrent] = useState(0);
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!'
    }
  };

  const PaymentOptionsKeysMap = {
    advance: 'Payment_Terms_Advance',
    progress: 'Payment_Terms_Progress',
    retention: 'Payment_Terms_Retention'
  };

  const getPaymentOptionsPayload = () => {
    const result = {
      Payment_Terms_Advance: [],
      Payment_Terms_Progress: [],
      Payment_Terms_Retention: []
    };

    const adv = payments.filter((payments) => payments.type === 'Retention');
    console.log(adv);
    payments
      .filter((itm) => !itm.deleted)
      .forEach((itm) => {
        console.log('ITEM', itm?.type);
        const itmType = itm?.type?.toLowerCase();
        /*  if (itm?.type === undefined) {
          result.Payment_Terms_Advance = { is_defined: false };
          result.Payment_Terms_Retention = { is_defined: false };
          result.Payment_Terms_Progress = { is_defined: false };
        } else {
          result.Payment_Terms_Advance = [];
          result.Payment_Terms_Progress = [];
          result.Payment_Terms_Retention = [];
        } */
        switch (itmType) {
          case 'advance':
            result[PaymentOptionsKeysMap[itmType]].push({
              type: 'advance',
              sequence: 1,
              is_defined: true,
              Percentage_of_Total_Contract_Price_as_advance: itm.percentage,
              Claim_period: itm.periodValue,
              Claim_period_unit: itm.period,
              Clauses: itm.clauses.map((itm, idx) => ({
                clause: itm,
                sequence: idx + 1
              }))
            });

            break;

          case 'progress':
            result[PaymentOptionsKeysMap[itmType]].push({
              type: 'progress',
              sequence: 1,
              is_defined: true,
              Percentage_Of_Total_Contract_Price_After: itm.periodValue,
              Claim_period_unit: itm.period,
              Progress_percentage: itm.percentage,
              Clauses: itm.clauses.map((itm, idx) => ({
                clause: itm,
                sequence: idx + 1,
                is_null: itm === '' ? true : false
              }))
            });
            break;
          case 'retention':
            result[PaymentOptionsKeysMap[itmType]].push({
              type: 'retention',
              is_defined: true,
              sequence: 1,
              Retention_percentage: itm.percentage,
              percentage_payment_will_be_released_after_Defect_Liability_Period_of:
                itm.periodValue,
              Defect_Liability_Period_unit: itm.period,
              Clauses: itm.clauses.map((itm, idx) => ({
                clause: itm,
                sequence: idx + 1,
                is_null: itm === '' ? true : false
              }))
            });
            break;
        }
      });
    const tempResult = {};
    Object.keys(result).forEach((key) => {
      console.log('KEYYY', result[key]);
      if (result[key]?.length === 0) {
        if (key === 'Payment_Terms_Advance') {
          result[key].push({
            is_defined: false,
            Percentage_of_Total_Contract_Price_as_advance: 0,
            Clauses: [{ clauses: [], sequence: 0 }]
          });
        } else if (key === 'Payment_Terms_Progress') {
          result[key].push({
            is_defined: false,
            Progress_percentage: 0,
            Clauses: [{ clauses: [], sequence: 0 }]
          });
        } else if (key === 'Payment_Terms_Retention') {
          result[key].push({
            is_defined: false,
            Retention_percentage: 0,
            Clauses: [{ clauses: [], sequence: 0 }]
          });
        } else {
          message.error('Please Check Payment Terms');
        }
        tempResult[key] = result[key];
      }
    });
    return result;
  };

  const getPackingOptionsPayload = () => {
    const result = [];
    packing
      .filter((itm) => !itm.deleted)
      .forEach((itm, idx) => {
        result.push({
          sequence: idx + 1,
          is_defined: itm.vendorScope !== 'yes' ? false : true,
          Is_Packaging_and_Forwarding_in_scope: itm.vendorScope,
          Clauses: itm.clauses.map((cls, idx) => ({
            clause: cls.field1,
            sequence: idx + 1
          }))
        });
      });
    return result;
  };

  const getDeliveryPayload = () => {
    const result = [];
    delivery
      .filter((itm) => !itm.deleted)
      .forEach((itm, idx) => {
        result.push({
          sequence: idx + 1,
          is_defined: itm.deliveryScope !== 'yes' ? false : true,
          Is_Packaging_and_Forwarding_in_scope: itm.deliveryScope,
          Clauses: itm.clauses.map((cls, idx) => ({
            field1: cls.field1,
            // field2: cls.field2,
            sequence: idx + 1
          }))
        });
      });
    return result;
  };

  const getMobilisationOptionsPayload = () => {
    const result = [];
    mobilisation
      .filter((itm) => !itm.deleted)
      .forEach((itm, idx) => {
        result.push({
          sequence: idx + 1,
          is_defined:
            itm.mobilisationAppicable !== 'MOBApplicable' ? false : true,
          Claim_period: itm.periodValue,
          Claim_period_unit: itm.period,
          Clauses: itm.clauses.map((cls, clsIndx) => ({
            clause: cls,
            sequence: clsIndx + 1
          }))
        });
      });
    return result;
  };

  const getLdOptionsPayload = () => {
    const result = [];
    ld.filter((itm) => !itm.deleted).forEach((itm, idx) => {
      result.push({
        sequence: idx + 1,
        is_defined: itm.delayCause !== 'LDApplicable' ? false : true,
        Clauses: itm.clauses.map((cls, clsIndx) => ({
          clause: cls,
          sequence: clsIndx + 1
        }))
      });
    });
    return result;
  };

  const getMrdtOptionsPayload = () => {
    const result = [];
    mrdt
      .filter((itm) => !itm.deleted)
      .forEach((itm, idx) => {
        result.push({
          sequence: idx + 1,
          is_defined: itm.mrdTime !== 'MinApplicable' ? false : true,
          Clauses: itm.clauses.map((cls, clsIndx) => ({
            clause: cls,
            sequence: clsIndx + 1
          }))
        });
      });
    return result;
  };

  const getWarrantyOptionsPayload = () => {
    const result = [];
    warranty
      .filter((itm) => !itm.deleted)
      .forEach((itm, idx) => {
        result.push({
          sequence: idx + 1,
          is_defined:
            itm.warrantyApplicable !== 'WarrantyApplicable' ? false : true,
          Clauses: itm.clauses.map((cls, clsIndx) => ({
            clause: cls,
            sequence: clsIndx + 1
          }))
        });
      });
    return result;
  };

  const getDlpOptionsPayload = () => {
    const result = [];
    dlp
      .filter((itm) => !itm.deleted)
      .forEach((itm, idx) => {
        result.push({
          sequence: idx + 1,
          is_defined: itm.dlpApplicable !== 'DLPApplicable' ? false : true,
          Clauses: itm.clauses.map((cls, clsIndx) => ({
            clause: cls,
            sequence: clsIndx + 1
          }))
        });
      });
    return result;
  };

  const getContractOptionsPayload = () => {
    const result = [];
    contractPerformance
      .filter((itm) => !itm.deleted)
      .forEach((itm, idx) => {
        result.push({
          sequence: idx + 1,
          is_defined: itm.pbgApplicable !== 'PBGApplicable' ? false : true,
          Clauses: itm.clauses.map((cls, clsIndx) => ({
            field1: cls.field1,
            field2: cls.field2,
            sequence: clsIndx + 1
          }))
        });
      });
    return result;
  };

  const getFreightOptionsPayload = () => {
    const result = [];
    freight
      .filter((itm) => !itm.deleted)
      .forEach((itm, idx) => {
        result.push({
          sequence: idx + 1,
          is_defined: itm.freightVendorScope !== 'yes' ? false : true,
          Clauses: itm.clauses.map((cls, clsIndx) => ({
            clause: cls,
            sequence: clsIndx + 1
          }))
        });
      });
    return result;
  };

  const getBoqOptionsPayload = () => {
    const result = [];
    boq
      .filter((itm) => !itm.deleted)
      .forEach((itm) => {
        if (itm?.boqVendorScope === 'manual') {
          itm?.clauses?.map((cls, clsIndx) =>
            result.push({
              index: itm.boqVendorScope === 'manual' ? clsIndx + 1 : null,
              description: cls.field2,
              unit: cls.field1,
              quantity: cls.field3,
              rate: cls.field4,
              amount: cls.field5
            })
          );
        } else {
          result.push();
        }
      });
    return result;
  };

  const getMessage0Payload = () => {
    const { message0 } = formValues;
    const result = [];
    result.push({
      sequence: 1,
      Clauses: [
        {
          clause: message0,
          sequence: 1
        }
      ]
    });
    return result;
  };

  const getMessage1Payload = () => {
    const result = [];
    message1.forEach((itm, idx) => {
      result.push({
        sequence: idx + 1,
        Clauses: itm.clauses.map((cls, clsIndx) => ({
          clause: cls,
          sequence: clsIndx + 1
        }))
      });
    });
    return result;
  };
  const getMessage2Payload = () => {
    const result = [];
    message2.forEach((itm, idx) => {
      result.push({
        sequence: idx + 1,
        Clauses: itm.clauses.map((cls, clsIndx) => ({
          clause: cls,
          sequence: clsIndx + 1
        }))
      });
    });
    return result;
  };

  const getMessage3Payload = () => {
    const { message3 } = formValues;
    const result = [];
    result.push({
      sequence: 1,
      Clauses: [
        {
          clause: message3,
          sequence: 1
        }
      ]
    });
    return result;
  };

  //console.log('PROJECTID:', projectId);

  const handleFormSubmit = (values) => {
    //console.log(supportingDoc[0]);
    console.log('VALUUES', values);
    setInitiate(true);
    setInitiateDraft(true);

    //console.log('form values', formValues);
    //console.log('form cancellation', check);
    //console.log('form no contra', value);
    //console.log('form acceptance', acceptance);
    const emptyApp = [];
    const paymentOptions = getPaymentOptionsPayload();
    const packingOptions = getPackingOptionsPayload();
    const mobilisationOptions = getMobilisationOptionsPayload();
    const ldOptions = getLdOptionsPayload();
    const deliveryOptions = getDeliveryPayload();
    const mrdtOptions = getMrdtOptionsPayload();
    const warrantyOptions = getWarrantyOptionsPayload();
    const dlpOptions = getDlpOptionsPayload();
    const contractOptions = getContractOptionsPayload();
    const freightOptions = getFreightOptionsPayload();
    const message0Options = getMessage0Payload();
    const message1Options = getMessage1Payload();
    const message2Options = getMessage2Payload();
    const message3Options = getMessage3Payload();
    const boqOptions = getBoqOptionsPayload();
    console.log(boqOptions);

    //console.log('message 0 options', getMessage0Payload());

    let formData = new FormData();

    /*  ; */

    formData.append(
      `LOI_OR_LOA`,
      values === 'draft' ? (loi === '' ? '' : loi) : formValues.loi
    );
    formData.append(
      `PO_OR_WO`,
      values === 'draft' ? (po === '' ? '' : po) : formValues.po
    );
    formData.append(
      `Date_of_Issuance`,
      moment(formValues.doi).format('YYYY-MM-DD')
    );

    formData.append(
      `Vendor_Name`,
      values === 'draft'
        ? vendorName === undefined
          ? ''
          : vendorName
        : formValues.v_name
    );
    /* formData.append(`Creators_EMail_ID`, formValues.creator_email); */
    formData.append(
      `BUJV_Name`,
      values == 'draft' ? getValues?.bu : formValues.bu
    );
    formData.append(
      `Project_Name`,
      values === 'draft' ? getValues?.project_name : formValues.project_name
    );
    formData.append(
      `Vendor_Quotation_Reference_Details`,
      values === 'draft'
        ? vqrd === ''
          ? ''
          : vqrd
        : formValues.vendor_quotation
    );
    formData.append(
      `Broad_Scope`,
      values === 'draft'
        ? broadScope === ''
          ? ''
          : broadScope
        : formValues.broad_scope
    );
    formData.append(
      `Basic_Contract_Price`,
      values === 'draft' ? getValues.loi_Value : formValues.contract_price
    );
    formData.append(
      `Currency`,
      values === 'draft'
        ? currency === ''
          ? ''
          : currency
        : formValues.currency
    );
    let selectedPriceBasis = priceBasis;

    if (priceBasisText && priceBasis === 'Others') {
      selectedPriceBasis = priceBasisText;
    }

    formData.append(
      'Price_Basis',
      values === 'draft'
        ? selectedPriceBasis || 'Others'
        : formValues.price_basis === 'Others'
        ? formValues?.price_basis_text
        : formValues.price_basis
    );
    formData.append(
      `Validity_of_Contract_in_months`,
      values === 'draft'
        ? contractValidity === ''
          ? ''
          : contractValidity
        : formValues.validitycontract === null ||
          formValues.validitycontract === undefined
        ? ''
        : formValues.validitycontract
    );
    formData.append(`taxAndDuties`, formValues.tax_duties);
    formData.append(
      `allOtherTermsAndConditions`,
      values === 'draft' ? (allTpl === '' ? '' : allTpl) : formValues.all_tpl
    );
    formData.append(
      `Price_Justification_and_Selection_of_Vendors`,
      values === 'draft'
        ? priceJustification === ''
          ? ''
          : priceJustification
        : formValues.price_justification
    );
    formData.append(
      `I_hereby_declare_there_are_no_contradictions_in_attachments_and_content_of_the_letter`,
      value ? 'Yes' : null
    );
    formData.append(
      `Approvers`,
      checkLimit?.isApproved === false &&
        isLimitExceeded === false &&
        checkLimit?.isCbNull === false
        ? selectedApprovers[0] !== undefined
          ? JSON.stringify([selectedApprovers[0]])
          : JSON.stringify(emptyApp)
        : JSON.stringify(selectedApprovers)
    );

    console.log('CHECKAPPR', selectedApprovers[0]);
    formData.append(
      `Email_Message`,
      values === 'draft'
        ? emailMessage === ''
          ? ''
          : emailMessage
        : formValues.email_message
    );

    formData.append(
      'Min_Resource_Deployment_Time',
      JSON.stringify(mrdtOptions)
    );
    formData.append('Delivery_Schedule', JSON.stringify(deliveryOptions));
    formData.append('DLP', JSON.stringify(dlpOptions));
    formData.append('Contract_Performance', JSON.stringify(contractOptions));
    formData.append('Mobilisation_Time', JSON.stringify(mobilisationOptions));
    formData.append('Warranty', JSON.stringify(warrantyOptions));
    formData.append('Freight_and_Insurance', JSON.stringify(freightOptions));
    formData.append('LD', JSON.stringify(ldOptions));
    formData.append('Packaging_and_Forwarding', JSON.stringify(packingOptions));
    formData.append('Payment_Options', JSON.stringify(paymentOptions));
    for (let i = 0; i < supportingDoc.length; i++) {
      formData.append('Documents', supportingDoc[i]);
    }
    formData.append(
      `Vendors_Contact_Person`,
      values === 'draft'
        ? vendorContact === ''
          ? ''
          : vendorContact
        : formValues.vendor_contact
    );
    formData.append(
      `Vendor_Id`,
      JSON.stringify(vendorId.id) === undefined
        ? ''
        : JSON.stringify(vendorId?.id)
    );
    formData.append(`BUJV_Id`, modalFormValues?.buId);
    formData.append(`Project_Id`, modalFormValues?.projectId);
    formData.append(`projectCode`, modalFormValues?.projectCode);
    formData.append(
      `Special_Terms_and_Conditions`,
      values === 'draft'
        ? specialTerms === ''
          ? ''
          : specialTerms
        : formValues.spl_terms
    );
    formData.append(
      `Is_The_Loi_Auto_Cancellation_After_30_Days_Accepted`,
      values === 'draft'
        ? cancellationMessage === ''
          ? ''
          : cancellationMessage
        : check
        ? formValues.cancellation_notice
          ? formValues.cancellation_notice
          : cancellationMessage
        : ''
    );
    formData.append(
      `Is_Anti_Bribery_Covenant_Accepted`,
      acceptance ? 'Yes' : null
    );

    formData.append(
      `Vendors_Contact_EMail_ID`,
      values === 'draft'
        ? contactEmail === ''
          ? ''
          : contactEmail
        : formValues.v_contact_id
    );

    formData.append(`Tax_and_Duties`, formValues.tax_duties);

    formData.append(
      `Vendor_Site_Address`,
      values === 'draft'
        ? vendorAddress === ''
          ? ''
          : vendorAddress
        : formValues.v_site_address
    );
    formData.append(
      `Vendor_Site_Id`,
      JSON.stringify(vendorId.id) === undefined
        ? ''
        : JSON.stringify(vendorId?.id)
    );
    formData.append(
      `Vendors_Contact_Number`,
      values === 'draft'
        ? vendorContactNumber === ''
          ? ''
          : vendorContactNumber
        : formValues.v_contact_no
    );

    formData.append(
      `currencyWords`,
      values === 'draft'
        ? toWords.convert(getValues.loi_Value)
        : toWords.convert(formValues.contract_price)
    );

    formData.append(
      `Quantity_Variation`,
      values === 'draft'
        ? quantityVariation === ''
          ? ''
          : quantityVariation
        : formValues.quantity_variation
    );
    formData.append(
      `Agreement_Location`,
      values === 'draft'
        ? agreementLocation === ''
          ? ''
          : agreementLocation
        : formValues.agreement_Location
    );
    formData.append(
      `All_Other_TandC_Shall_Be_As_Per_TLPs`,
      values === 'draft' ? (allTpl === '' ? '' : allTpl) : formValues.all_tpl
    );
    formData.append(
      `Vendor_Site`,
      values === 'draft'
        ? vendorSite === ''
          ? ''
          : vendorSite
        : formValues.v_site
    );
    formData.append(
      `loiMessage`,
      values === 'draft'
        ? loiMessage === undefined
          ? defaultClauses?.loiMessage?.defaultClause
          : loiMessage
        : formValues.loiMessage
        ? formValues.loiMessage
        : formValues.loaMessage
    );
    formData.append(
      `loaMessage`,
      values === 'draft'
        ? loaMessage === undefined
          ? defaultClauses?.loaMessage?.defaultClause
          : loaMessage
        : formValues.loaMessage
        ? formValues.loaMessage
        : formValues.loiMessage
    );
    formData.append(`Message_0`, JSON.stringify(message0Options));
    formData.append(`Message_1`, JSON.stringify(message1Options));
    formData.append(`Message_2`, JSON.stringify(message2Options));
    formData.append(`Message_3`, JSON.stringify(message3Options));
    formData.append(`BOQ`, JSON.stringify(boqOptions));
    formData.append(
      `List_of_Attachments`,
      values === 'draft'
        ? attachmentList === ''
          ? ''
          : attachmentList
        : formValues.doc_list
    );
    values === 'draft'
      ? formData.append(`is_submitting`, 'no')
      : formData.append(`is_submitting`, 'yes');
    dispatch(createLOI(formData)).then((response) => {
      if (response.payload.success) {
        setInitiateDraft(false);
        setInitiate(false);
        history.push('/dashboard');
      } else {
        setInitiateDraft(false);
        setInitiate(false);
      }
    });
  };

  return (
    <>
      <Layout className={`${styles.layout}`}>
        <>
          {current == 0 ? (
            <div className="steps-content">
              <InitiateLOI1
                po={po}
                currency={currency}
                isLimitExceeded={isLimitExceeded}
                setIsLimitExceeded={setIsLimitExceeded}
                setPriceBasisText={setPriceBasisText}
                cancellationMessage={cancellationMessage}
                setCancellationMessage={setCancellationMessage}
                setLOAMessage={setLOAMessage}
                setLOIMessage={setLOIMessage}
                taxDuties={taxDuties}
                setTaxDuties={setTaxDuties}
                specialTerms={specialTerms}
                attachmentList={attachmentList}
                broadScope={broadScope}
                initiateDraft={initiateDraft}
                setContactEmail={setContactEmail}
                setAttachmentList={setAttachmentList}
                setVendorContact={setVendorContact}
                setVendorAddress={setVendorAddress}
                setVendorSite={setVendorSite}
                setVendorContactNumber={setVendorContactNumber}
                setQuantityVariation={setQuantityVariation}
                setPriceJustification={setPriceJustification}
                setAgreementLocation={setAgreementLocation}
                //
                setAllTpl={setAllTpl}
                setSpecialTerms={setSpecialTerms}
                priceBasis={priceBasis}
                setContractValidity={setContractValidity}
                setPriceBasis={setPriceBasis}
                setCurrency={setCurrency}
                setBroadScope={setBroadScope}
                emailMessage={emailMessage}
                setEmailMessage={setEmailMessage}
                setVqrd={setVqrd}
                setPo={setPo}
                handleFormSubmit={handleFormSubmit}
                isDraft={isDraft}
                setIsDraft={setIsDraft}
                boq={boq}
                setBoq={setBoq}
                projectId={projectId}
                allSbgs={allSbgs}
                setSbgName={setSbgName}
                sbgName={sbgName}
                loading={loading}
                approverName={approverName}
                setApproverName={setApproverName}
                setProjectId={setProjectId}
                delivery={delivery}
                setDelivery={setDelivery}
                emailreg={emailreg}
                setEmailreg={setEmailreg}
                allVendorLocations={allVendorLocations}
                setVendorLocation={setVendorLocation}
                buJv={buJv}
                setBuJv={setBuJv}
                setProjectName={setProjectName}
                setBuName={setBuName}
                vendorName={vendorName}
                setVendorName={setVendorName}
                vendorId={vendorId}
                setVendorId={setVendorId}
                buId={buId}
                setBuId={setBuId}
                formSubmitted={formSubmitted}
                setFormSubmitted={setFormSubmitted}
                loi={loi}
                setLOI={setLOI}
                setMessage1={setMessage1}
                setMessage2={setMessage2}
                mobilisation={mobilisation}
                setMobilisation={setMobilisation}
                warranty={warranty}
                setWarranty={setWarranty}
                mrdt={mrdt}
                setMrdt={setMrdt}
                dlp={dlp}
                setDlp={setDlp}
                contractPerformance={contractPerformance}
                setContractPerformance={setContractPerformance}
                ld={ld}
                setLD={setLD}
                freight={freight}
                setFreight={setFreight}
                freightMessage={freightMessage}
                setFreightMessage={setFreightMessage}
                warrantyMessage={warrantyMessage}
                setWarrantyMessage={setWarrantyMessage}
                mrdtMessage={mrdtMessage}
                setMrdtMessage={setMrdtMessage}
                mobilisationMessage={mobilisationMessage}
                setMobilisationMessage={setMobilisationMessage}
                packing={packing}
                setPacking={setPacking}
                retentionMessage={retentionMessage}
                setRetentionMessage={setRetentionMessage}
                retention={retention}
                setRetention={setRetention}
                vendorSites={vendorSites}
                setVendorSites={setVendorSites}
                dummyData={dummyData}
                defaultClauses={defaultClauses}
                selectedApprovers={selectedApprovers}
                setSelectedApprovers={setSelectedApprovers}
                projects={projects}
                setProjects={setProjects}
                projectCodes={projectCodes}
                setProjectCodes={setProjectCodes}
                formValues={formValues}
                setformValues={setformValues}
                persons={persons}
                setPersons={setPersons}
                allBu={allBu}
                allCFO={allCFO}
                allApprovers={allApprovers}
                allVendors={allVendors}
                allProjects={allProjects}
                check={check}
                setCheck={setCheck}
                acceptance={acceptance}
                setAcceptance={setAcceptance}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                minValue={minValue}
                setMinValue={setMinValue}
                contractMessage1={contractMessage1}
                contractMessage2={contractMessage2}
                setContractMessage1={setContractMessage1}
                setContractMessage2={setContractMessage2}
                defectLiabilityMessage1={defectLiabilityMessage1}
                setDefectLiabilityMessage1={setDefectLiabilityMessage1}
                clauseMessage={clauseMessage}
                setClauseMessage={setClauseMessage}
                deliveryMessage={deliveryMessage}
                setDeliveryMessage={setDeliveryMessage}
                deliveryMessage1={deliveryMessage1}
                setDeliveryMessage1={setDeliveryMessage1}
                taxMessage={taxMessage}
                setTaxMessage={setTaxMessage}
                lcMessage1={lcMessage1}
                lcMessage2={lcMessage2}
                setLCMessage1={setLCMessage1}
                setLCMessage2={setLCMessage2}
                progressPercent1={progressPercent1}
                progressPercent2={progressPercent2}
                progressMessage={progressMessage}
                advanceMessage={advanceMessage}
                setProgressPercent1={setProgressPercent1}
                setProgressPercent2={setProgressPercent2}
                setProgressMessage={setProgressMessage}
                setAdvanceMessage={setAdvanceMessage}
                validateMessages={validateMessages}
                value={value}
                setValue={setValue}
                payments={payments}
                setPayments={setPayments}
                newDocs={newDocs}
                setNewDocs={setNewDocs}
                supportingDoc={supportingDoc}
                setSupportingDoc={setSupportingDoc}
                contacts={contacts}
                setContacts={setContacts}
                select={select}
                setSelect={setSelect}
                current={current}
                setCurrent={setCurrent}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                selectedSchedule={selectedSchedule}
                setSelectedSchedule={setSelectedSchedule}
                clauseCount={clauseCount}
                setClauseCount={setClauseCount}
              />
            </div>
          ) : (
            <div className="steps-content">
              <InitiateLOI2
                boq={boq}
                initiate={initiate}
                supportingDoc={supportingDoc}
                ld={ld}
                warranty={warranty}
                freight={freight}
                dlp={dlp}
                contractPerformance={contractPerformance}
                mrdt={mrdt}
                mobilisation={mobilisation}
                delivery={delivery}
                packing={packing}
                payments={payments}
                handleFormSubmit={handleFormSubmit}
                formValues={formValues}
                current={current}
                setCurrent={setCurrent}
                selectedApprovers={selectedApprovers}
                setInitiate={setInitiate}
              />
            </div>
          )}
        </>
      </Layout>
    </>
  );
};

export default InitiateLOI;
