import apiClient from 'utilities/apiClient';

export const allProjects = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_project}`, payload, true);
};

export const createProject = (payload) => {
  return apiClient.post(`${apiClient.Urls.get_project}`, payload, true);
};

export const project_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_project}/${payload}`,
    payload,
    true
  );
};

export const blockUnblock = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.get_project}/block-unblock/${url}`,
    payload,
    true
  );
};

export const activateDeactivate = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.get_project}/activate-deactivate/${url}`,
    payload,
    true
  );
};

export const editProjects = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.get_project}/edit/${url}`,
    payload,
    true
  );
};

export const updateCb = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.get_project}/update-cb-status/${url}`,
    payload,
    true
  );
};
